<template>
  <div id="tagTree">
    <div class="tree-header">
      <div class="tag-name">
        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange" v-if="false"></el-checkbox>
        <span style="margin-left: 10px">{{ $t('knowledgeManagement.knowledgeTag.tagName') }}</span>
      </div>
      <div class="header-right">
        <div class="tree-header-identification">{{ $t('knowledgeManagement.knowledgeTag.tagTree.isConfigurationRecognition') }}</div>
        <div class="tree-header-association">{{ $t('knowledgeManagement.knowledgeTag.tagTree.relatedKnowledge') }}</div>
        <div class="tree-header-operation"></div>
      </div>
    </div>
    <div class="tree-container"
         :class="hasNextTag ? 'has-next-btn' : ''"
    >
      <el-tree
          :data="tagList"
          ref="tree"
          class="file-tree"
          node-key="_id"
          :expand-on-click-node="false"
          :default-expanded-keys="defaultExpandedCids"
          :load="loadNode"
          lazy
          :props="{id:'_id', children: 'children',label: 'name',isLeaf:'isLeaf'}"
          @check-change="checkChange"
      >
        <template  slot-scope="{ node,data }">
          <div class="custom-tree-node tree-item">
            <div class="left-name">
              <i class="iconfont guoran-AI" v-if="data.aiCreate"></i>
              <div class="tree-item-name">
                <el-tooltip v-if="data.showTooltip" class="item" effect="dark" :content="data.name" placement="top-start">
                  <div class="folder-name one-column-ellipsis">{{ data.name }}</div>
                </el-tooltip>
                <div v-else class="folder-name">{{ data.name }}</div>
              </div>
            </div>
            <div class="right-box">
              <div class="identification">
                <div class="identification-btn" :class="data.ruleList && data.ruleList.length > 0 ? 'identification' : 'no-identification'">
                  {{data.ruleList && data.ruleList.length > 0 ?
                    $t('knowledgeManagement.knowledgeTag.tagTree.configured') :
                    $t('knowledgeManagement.knowledgeTag.tagTree.notConfigured')}}
                </div>
              </div>
              <div class="association">
                {{data.associatedCount > 999 ? ('999+') : data.associatedCount}}
              </div>
              <div class="placeholder">
                <div class="operation-btn">
                  <div class="ai-tag" @click="expandTags(data)">
                    {{ $t('knowledgeManagement.knowledgeTag.tagTree.AIExpansion') }}
                  </div>
                  <div class="add-tag" @click="addTag(node,data._id,data.name)">
                    <i class="el-icon-plus"></i>
                  </div>
                  <div class="edit-tag" @click="updateTag(node,data)">
                    <i class="iconfont guoran-a-18_huaban1fuben3"></i>
                  </div>
                  <div class="del-tag" @click="deleteTag(node,data)">
                    <i class="iconfont guoran-a-18-13"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </el-tree>
      <div class="loadMore"
           v-if="hasNextTag"
           @click="loadMore"
      >
        <span>{{$t('knowledgeManagement.knowledgeTag.tagTree.loadMore')}}</span>
      </div>
    </div>

    <popup-com
        v-if="deleteTagDialog"
        class="aiTagPopup"
        @closeEvent="deleteTagDialog = false">
      <div slot="popup-name">{{$t('knowledgeManagement.knowledgeTag.tagTree.deletedTag')}}</div>
      <div slot="popup-tip">{{deleteTagText}}</div>
      <div slot="popup-con" v-if="isMove">
        <el-form label-position="top" label-width="80px">
          <el-form-item label="">
            <div class="label-bg">
              <el-radio v-model="radio" label="remove">{{$t('knowledgeManagement.knowledgeTag.tagTree.relatedOther')}}</el-radio>
              <el-radio v-model="radio" label="del">{{$t('knowledgeManagement.knowledgeTag.tagTree.directlyDelete')}}</el-radio>
            </div>
          </el-form-item>
          <el-form-item :label="$t('knowledgeManagement.knowledgeTag.tagTree.associateWith')" v-if="radio === 'remove'">
            <el-select-tree ref="selectTree" :filterId="currentData._id"></el-select-tree>
          </el-form-item>
        </el-form>

      </div>
      <div slot="dialog-footer">
        <el-button  plain size="small" @click="deleteTagDialog = false" class="cancel-btn">{{$t('knowledgeManagement.knowledgeTag.cancel')}}</el-button>
        <el-button  type="danger" size="small" @click="confirmDeleteTag"  class="delete-btn">{{ $t('knowledgeManagement.knowledgeTag.deleteText') }}</el-button>
      </div>
    </popup-com>
    <el-drawer
        :visible.sync="aiDrawer"
        size="740px"
        :withHeader="false"
        :wrapperClosable="false"
        :destroy-on-close="true">
        <div class="aiExpandDrawer">
          <div class="drawerHeader">
            <span class="tagSet">{{ $t('knowledgeManagement.knowledgeTag.tagSet') }}</span>
            <i class="iconfont guoran-shanchu" @click="aiDrawer = false;aiDrawerFooter = false"></i>
          </div>
          <div class="ai-drawer-container">
            <div class="ai-process" :class="process === 'loading' ? 'ai-loading' : (process === 'finish' ? 'ai-success' : 'ai-error')">
              <i :class="['iconfont',process === 'loading' ? 'guoran-Loading' : (process === 'finish' ? 'guoran-tongyichicun-_duihao-mian' : 'guoran-tongyichicun-guanbi2')]"></i>
              {{processText}}
              <span style="color: #366AFF;margin-left: 2px" v-if="process === 'error'" @click="restart">{{ $t('knowledgeManagement.knowledgeTag.tagTree.retry') }}</span>
            </div>
            <div class="tag-tree">
              <el-tree
                  :data="aiTagTree"
                  node-key="id"
                  :default-expand-all="true"
                  :expand-on-click-node="false"
                  show-checkbox
                  :props="{
                    label:'name',
                    children:'children',
                    isLeaf:'isLeaf',
                    disabled:  function(data, node) { return data.isTop }
                  }"
                  ref="tagTree"
                  class="aiTagTree"
                  @check-change="checkAiChange"
              >
                <template slot-scope="{ node,data }">
                  <div class="custom-tree-node tree-item">
                    <div class="tree-left">
                      {{data.name}}
                    </div>
                    <div class="tree-right" v-if="!data.isTop">
                      <template v-if="!data.isSave">
                        <div class="tree-btn save-btn" @click="treeBtn('save',node,data)">{{ $t('knowledgeManagement.knowledgeTag.saveTag') }}</div>
                        <div class="tree-btn ignore" @click="treeBtn('ignore',node,data)">{{ $t('knowledgeManagement.knowledgeTag.tagTree.ignore') }}</div>
                      </template>
                     <span v-else class="save-tag-success-tips">{{ $t('knowledgeManagement.knowledgeTag.tagTree.tagSaved') }}</span>
                    </div>
                  </div>
                </template>
              </el-tree>
            </div>
          </div>
          <div class="drawer-footer" v-if="aiDrawerFooter">
            <div class="footer-left">
              <el-checkbox v-model="checkedAll" :indeterminate="aiIsIndeterminate" @change="handleAItAagCheckAllChange">{{ $t('knowledgeManagement.knowledgeTag.tagTree.allSelected') }}</el-checkbox>
              <span class="check-number">{{ $t('knowledgeManagement.knowledgeTag.tagTree.selected') }}<span style="color: #366aff;margin: 0 2px">{{checkedNumber}}</span>个</span>
            </div>
            <div class="footer-right">
              <div class="confirm-btn btn-item" @click="batchSaveTagDialog = true">{{ $t('knowledgeManagement.knowledgeTag.saveTag') }}</div>
              <div class="close-btn btn-item" @click="ignoreTag">{{ $t('knowledgeManagement.knowledgeTag.tagTree.ignore') }}</div>
            </div>
          </div>
        </div>
    </el-drawer>
    <popup-com @closeEvent="batchSaveTagDialog = false" v-if="batchSaveTagDialog" class="aiTagPopup">
      <div slot="popup-name">{{ $t('knowledgeManagement.knowledgeTag.tagTree.batchSave') }}</div>
      <div slot="popup-tip">{{ $t('knowledgeManagement.knowledgeTag.tagTree.batchSaveTips') }}</div>
      <div slot="dialog-footer">
        <el-button  plain size="small" @click="batchSaveTagDialog = false" class="cancel-btn">{{ $t('knowledgeManagement.knowledgeTag.cancel') }}</el-button>
        <el-button  type="primary" size="small" @click="saveTags"  class="confirm-btn">{{ $t('knowledgeManagement.knowledgeTag.saveTag') }}</el-button>
      </div>
    </popup-com>
  </div>
</template>

<script>
import { requestUrl } from "@/http/requestUrl";
// import DeleteDialog from "@/components/deleteDialog";
import PopupCom from "@/components/popup";
import ElSelectTree from "@/views/labelManagement/components/elSelectTree";
export default {
  name: "tagTree",
  components: { ElSelectTree, PopupCom },
  data(){
    return{
      treeData:null,
      resolve:null,
      deleteTagDialog:false,
      deleteTagText:"",
      radio:"remove",
      currentData:null,
      isMove:false,
      tagList:[],
      rootTreeData:null,
      rootResolve:null,
      checkAll:false,
      isIndeterminate:false,
      aiDrawer:false,
      processText:"",
      process:"loading",
      expandTagData:null,
      aiTagTree:[],
      checkedAll:false,
      aiIsIndeterminate:false,
      aiDrawerFooter:false,
      checkedNumber:0,
      parentNode:null,
      parentData:null,
      hasNextTag:false,
      saveTagData:null,
      batchSaveTagDialog:false,
      defaultExpandedCids:[]
    }
  },
  props:[],
  methods:{
    loadNode(node, resolve) {
      let treeData =  node.data || {};
      this.hasNextTag = false;
      this.treeData = node;
      this.resolve = resolve;
      if (node.level === 0) {
        this.tagList = [];
        this.defaultExpandedCids = [];
        this.rootTreeData = node;
        this.rootResolve = resolve;
        let url = requestUrl.tagManage.tagTree;
        let corpId = sessionStorage.getItem('_corpId');
        let mainId = sessionStorage.getItem('_mainId');
        let uid = sessionStorage.getItem("_uid");
        url += '?mainId=' + mainId + '&corpId=' + corpId + '&uid=' + uid + '&parentId=' + "-1";
        this.$http.get(url).then(res => {
          if (res.data.code == 0){
            let data = res.data.data;
            data.forEach(item => {
              if (!item.hasChildren){
                item.isLeaf = true;
              }
            })
            // this.tagList = data;
            this.$parent.tagList = data;
            if (data.length <= 5){
              data.forEach(item => {
                if (item.hasChildren && this.defaultExpandedCids.indexOf(item._id) === -1){
                  this.defaultExpandedCids.push(item._id)
                }
              })
            }
            resolve(data)
          }
        })
      }
      if (node.level >= 1) {
        let url = requestUrl.tagManage.tagTree
        let corpId = sessionStorage.getItem('_corpId');
        let mainId = sessionStorage.getItem('_mainId');
        let uid = sessionStorage.getItem("_uid");
        url += '?mainId=' + mainId + '&corpId=' + corpId + '&uid=' + uid + '&parentId=' + (treeData._id ? treeData._id : "-1");
        this.$http.get(url).then(res => {
          if (res.data.code == 0 && res.data.data){
            let data = res.data.data;
            data.forEach(item => {
              if (!item.hasChildren){
                item.isLeaf = true;
              }
            })
            // node.children = data;
            resolve(data);
          }
        })
      }
    },
    addTag(node,id,name){
      this.$emit('addTag',id,name)
    },
    updateTag(node,data){
      this.$emit('updateTag',node,data)
    },
    deleteTag(node,data){
      console.log(node,data)
      this.isMove = false;
      this.currentData = data;
      this.getTagCount(data,(res) => {
        this.getKnowledgeCount(data,res)
      });

    },
    getKnowledgeCount(data,tagChildrenCount){
      let url = requestUrl.tagManage.getKnowledgeCount + data._id;
      this.$http.get(url).then(res => {
        if (res.data.code == 0){
          if (res.data.data > 0 && tagChildrenCount > 0){
            let num = res.data.data;
            this.deleteTagText = this.$t('knowledgeManagement.knowledgeTag.tagTree.checkLowerLevel') + num + this.$t('knowledgeManagement.knowledgeTag.tagTree.numKnowledge');
            this.isMove = true;
          } else if (res.data.data > 0){
            this.deleteTagText = this.$t('knowledgeManagement.knowledgeTag.tagTree.checkCurrent') + data.associatedCount + this.$t('knowledgeManagement.knowledgeTag.tagTree.numKnowledge');
            this.isMove = true;
          } else if (tagChildrenCount > 0){
            this.deleteTagText = this.$t('knowledgeManagement.knowledgeTag.tagTree.hasChildren')
          } else {
            this.deleteTagText = this.$t('knowledgeManagement.knowledgeTag.tagTree.noChildren')
          }
          this.deleteTagDialog = true;
        }
      })
    },
    getTagCount(data,callBack){
      let url = requestUrl.tagManage.getChildrenCount + data._id
      this.$http.get(url).then(res => {
        if (res.data.code == 0){
          if (callBack){
            callBack(res.data.data)
          }
        }
      })
    },
    confirmDeleteTag(){
      let moveId = "";
      if (this.$refs.selectTree){
        moveId = this.$refs.selectTree.selectValue;
      }
      let url = requestUrl.tagManage.deleteTag + this.currentData._id;
      console.log(moveId,url,this.$refs.selectTree)
      const params = {
        moveId:moveId
      }
      this.$http.delete(url,{
        data:params
      }).then(res => {
        if (res.data.code == 0){
          this.$message({
            message:this.$t('knowledgeManagement.knowledgeTag.tagTree.deleteSuccess'),
            type:'success'
          })
          this.deleteTagDialog = false;
          this.loadNode(this.rootTreeData,this.rootResolve);
          this.$parent.tagDrawer = false;
          this.$parent.tagDrawerC = false;
        }
      })
    },
    handleCheckAllChange(val){
      if (val){
        let ids = this.tagList.map(item => {
          return item._id
        })
        this.$refs.tree.setCheckedKeys(ids);
      } else {
        this.$refs.tree.setCheckedKeys([]);
      }
      this.isIndeterminate = false;
    },
    handleAItAagCheckAllChange(val){
      if (val){
        let ids = this.aiTagTree.map(item => {
          return item.id
        })
        this.$refs.tagTree.setCheckedKeys(ids);
      } else {
        this.$refs.tagTree.setCheckedKeys([]);
      }
      this.aiIsIndeterminate = false;
    },
    checkChange(){
      this.checkAll = this.$refs.tree.getCheckedKeys().length === this.tagList.length;
      this.isIndeterminate = this.$refs.tree.getCheckedKeys().length > 0 && this.$refs.tree.getCheckedKeys().length < this.tagList.length;
    },
    checkAiChange(){
      this.checkedNumber = this.checkedAll ? this.$refs.tagTree.getCheckedKeys().length - 1 : this.$refs.tagTree.getCheckedKeys().length;
      this.aiIsIndeterminate = this.$refs.tagTree.getHalfCheckedKeys().length >= 1;
      this.checkedAll = this.$refs.tagTree.getCheckedKeys().length > 0 && this.$refs.tagTree.getHalfCheckedKeys().length === 0;
    },
    expandTags(data){
      this.expandTagData = data;
      this.processText = this.$t('knowledgeManagement.knowledgeTag.tagTree.expandingLabel');
      this.process = "loading";
      const result = /^\d+$/
      if (result.test(data.name)){
        this.$message(({
          message:this.$t('knowledgeManagement.knowledgeTag.tagTree.unrecognized'),
          type:"warning"
        }))
        return
      }
      this.getAiLabel(data.name);
      this.aiDrawer = true;
      this.checkedAll = false;
      this.checkedNumber = 0;
      this.aiIsIndeterminate = false;
      this.$nextTick(() => {
        // 打开抽屉时会抖动，添加此变量防止抖动
        this.aiDrawerFooter = true;
        this.aiTagTree = [
          {
            name:data.name,
            children:[],
            isTop:true,
            id:data._id,
            isLeaf:false
          }
        ];
      })
    },
    getAiLabel(label){
      let url = requestUrl.tagManage.aiLabel;
      this.$http.post(url,{label:label}).then(res => {
        if (res.data.code == 0 && res.data.data && res.data.data.length > 0){
          this.processText = this.$t('knowledgeManagement.knowledgeTag.tagTree.expandingSuccess');
          this.process = "finish";
          if (res.data.data[0] && res.data.data[0].name == label){
            this.aiTagTree[0].children = res.data.data[0].children
          } else {
            this.aiTagTree[0].children = res.data.data
          }
          console.log(this.aiTagTree)
        } else {
          this.process = "error";
          this.processText = this.$t('knowledgeManagement.knowledgeTag.tagTree.expandingFail');
        }
      })
    },
    //重新进行AI扩写
    restart(){
      this.processText = this.$t('knowledgeManagement.knowledgeTag.tagTree.expandingLabel');
      this.process = "loading";
      this.aiTagTree = [
        {
          name:this.expandTagData.name,
          children:[],
          isTop:true,
          id:this.expandTagData._id,
          isLeaf:false
        }
      ];
      this.getAiLabel(this.expandTagData.name);
    },
    async treeBtn(type,node,data){
      console.log(type,node,data)
      if (type === 'save'){
        this.saveTagData = data;
        let obj = {
          name: data.name,
          parentId: this.expandTagData._id,
          aiCreate:true,
          id:data.id,
          description:"",
          intelligentRecognition:false,
          ruleList:[],
          modelInstructions:""
        }
        this.$emit('saveTag',obj,this.expandTagData.name);
      } else {
        this.saveTagData = null;
        const checkedKeys = this.$refs.tagTree.getCheckedKeys();
        const newCheckedKeys = checkedKeys.filter(key => key !== data.id); // 移除当前节点的选中状态
        this.$refs.tagTree.setCheckedKeys(newCheckedKeys); // 更新选中状态

        if (node.parent && node.parent.parent) {
          node.parent.data.children = node.parent.data.children.filter(item => item.id !== data.id);
          this.checkedAll = false;
          if (data.children && data.children.length > 0) {
            this.checkedNumber = this.checkedNumber - 1 - this.countDescendants(data, data.id);
          } else {
            this.checkedNumber = this.checkedNumber - 1;
          }
          if (this.checkedNumber !== 0) {
            this.aiIsIndeterminate = true;
          } else {
            this.aiIsIndeterminate = false;
          }
        } else {
          this.aiTagTree = [];
          this.checkedAll = false;
          this.checkedNumber = 0;
          this.aiIsIndeterminate = false;
        }
      }
    },
    countDescendants(data, targetId) {
        // 递归函数用于计算后代节点数量
        function recursiveCount(node) {
            let count = 0;
            // 检查当前节点是否有子节点
            if (node.children!== null) {
                // 遍历子节点
                for (let child of node.children) {
                    count++;
                    // 递归调用计算子节点的后代节点数量，并累加到当前计数中
                    count += recursiveCount(child);
                }
            }
            return count;
        }
        // 递归查找目标节点
        function findTarget(node) {
            if (node.id === targetId) {
                return recursiveCount(node);
            } else if (node.children!== null) {
                for (let child of node.children) {
                    let result = findTarget(child);
                    if (result!== undefined) {
                        return result;
                    }
                }
            }
        }
        return findTarget(data);
    },
    async saveTagSuccess(){
      await this.findTagById(this.aiTagTree,this.saveTagData.id)
    },
    findTagById(list,id){
      list.forEach(item => {
        if (item.id === id){
          this.$set(item,'isSave',true)
        }
        if (item.children){
          this.findTagById(item.children,id)
        }
      })
    },
    async findTag(name,id){
      let flag = false;
      let url = requestUrl.tagManage.duplicateTags;
      let corpId = sessionStorage.getItem('_corpId');
      let mainId = sessionStorage.getItem('_mainId');
      url += '?mainId=' + mainId + '&corpId=' + corpId + '&keyword=' + name;
      await this.$http.get(url).then(res => {
        let data = res.data.data;
        if (id){
          data = res.data.data.filter(item => item._id !== id);
        }
        if (data.length > 0){
          flag = true
        }
      })
      return flag
    },
    async saveTags(){
      let checkNodes = this.$refs.tagTree.getCheckedNodes(false);
      let ids = [];
      for (let i = 0; i < checkNodes.length; i++){
        checkNodes[i].aiCreate = true;
        if (checkNodes[i].children){
          await this.getChildrenId(checkNodes[i].id,checkNodes[i].children,ids);
        }
      }
      let newNodes = checkNodes.filter(item => ids.indexOf(item.id) === -1);
      let saveData = newNodes;
      if (newNodes[0] && newNodes[0].isTop){
        saveData = newNodes[0].children
      }
      for (let i =0;i<saveData.length;i++){
        saveData[i].parentId = this.expandTagData._id
      }
      console.log('newNodes',ids,newNodes,saveData)
      let url = requestUrl.tagManage.batchSave;
      let corpId = sessionStorage.getItem('_corpId');
      let mainId = sessionStorage.getItem('_mainId');
      let uid = sessionStorage.getItem("_uid");
      url += '?mainId=' + mainId + '&corpId=' + corpId + '&uid=' + uid;
      if (saveData.length == 0){
        this.$message({
          message:this.$t('knowledgeManagement.knowledgeTag.tagTree.selectTag'),
          type:"warning"
        })
        return
      }
      this.$http.post(url,saveData).then(res => {
        console.log(339,res)
        if (res.data.code == 0){
          this.$message({
            message:this.$t('knowledgeManagement.knowledgeTag.tagTree.saveSuccess'),
            type:"success"
          })
          this.batchSaveTagDialog = false;
          this.loadNode(this.rootTreeData,this.rootResolve);
          this.aiDrawer = false;
          this.aiDrawerFooter = false;
        }
      })
    },
    getChildrenId(parentId,list,ids){
      list.forEach((item) => {
        item.aiCreate = true;
        item.parentId = parentId;
        if (item.id && ids.indexOf(item.id) === -1){
          ids.push(item.id)
        }
        if (item.children){
         this.getChildrenId(item.id,item.children,ids)
        }
      })
    },
    ignoreTag(){
      this.aiDrawer = false;
      this.aiDrawerFooter = false;
      this.aiTagTree = [];
      this.checkedAll = false;
      this.checkedNumber = 0;
      this.aiIsIndeterminate = false;
    },
    loadMore(){
      this.$emit('loadMore');
    }
  },

};
</script>

<style scoped lang="less">
#tagTree{
  width: 100%;
  height: 100%;
  .tree-header{
    display: flex;
    align-items: center;
    height: 50px;
    background: #F6F8FD;
    border-bottom: none;
    border-radius: 5px 5px 0 0;
    padding: 0 20px;
    border-bottom: 1px solid #E0E6F7;
    .tag-name{
     flex: 1;
      display: flex;
      align-items: center;
    }
    .header-right{
      flex: none;
      //width: 600px;
      display: flex;
      align-items: center;
      .tree-header-identification{
        flex: none;
        width: 200px;
        text-align: center;
      }
      .tree-header-association{
        flex: none;
        width: 190px;
        text-align: center;
      }
      .tree-header-operation{
        flex:none;
        width: 200px;
      }
    }
  }
  .tree-container{
    height: calc(100% - 50px);
    background-color: #ffffff;
    overflow: scroll;
  }
  /deep/.file-tree{
    padding: 0 14px;
    //padding-left: 40px;
    .el-tree-node__content {
      height: 60px;
      .el-checkbox{
        margin-left:10px;
      }
    }
    .el-tree-node {
      position: relative;
      .el-tree-node__children {
        padding-left: 30px; // 缩进量
      }
      // 竖线
      &::before {
        content: "";
        height: 100%;
        width: 1px;
        position: absolute;
        left: -3px;
        top: -30px;
        border-width: 1px;
        border-left: 1px dashed #4A7FEB;
      }
      // 当前层最后一个节点的竖线高度固定
      &:last-child::before {
        height: 60px; // 可以自己调节到合适数值
      }
      // 横线
      &::after {
        content: "";
        width:36px;
        height: 20px;
        position: absolute;
        left: -3px;
        top: 30px;
        border-width: 1px;
        border-top: 1px dashed #4A7FEB;
        z-index: 0;
      }
      .el-tree-node__content{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        color: #000;
        padding-left: 10px !important;
        border-bottom: 1px solid #E0E6F7;
        .el-tree-node__expand-icon{
          flex: none;
          margin-left: 6px;
          width: 25px;
          height: 25px;
          background: #D8E2FF;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          color: #366aff;
          padding: 0;
          z-index: 10;
          &.is-leaf{
            background-color: transparent;
            color: transparent;
          }
          .el-icon-caret-right:before{
            //width: 16px;
            //height: 16px;
            //font-size: 16px;
          }

        }
        .custom-tree-node{
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          height: 60px;
          color: #000;
          padding-left: 6px;
          .left-name{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex: 1;
            .guoran-AI{
              color: #366aff;
              margin-right: 6px;
            }
          }
          .right-box{
            flex: none;
            display: flex;
            align-items: center;
            .identification{
              display: flex;
              align-items: center;
              justify-content: center;
              flex: none;
              width: 190px;
              font-size: 12px;
              .identification-btn{
                flex: none;
                height: 24px;
                width: 104px;
                border-radius: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
              .identification{
                background-color: #D6F3EA;
                color: #04BB82;
              }
              .no-identification{
                background-color: #FFE8EA;
                color: #FE5965;
              }
            }

            .association{
              flex: none;
              width: 210px;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #366aff;
            }
            .placeholder{
              flex: none;
              width: 200px;
            }
            .operation-btn{
              display: none;
              //width: 160px;
              i{
                color: #366aff;
                cursor: pointer;
              }
              .el-icon-plus{
                font-size: 16px;
              }
              .ai-tag{
                font-size: 13px;
                flex: none;
                width: 108px;
                height: 26px;
                background: #FFFFFF;
                color: #366AFF;
                border: 1px solid #A1B9FF;
                border-radius: 13px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
              }
              .add-tag{
                margin: 0 8px;
              }
              .del-tag{
                margin-left: 8px;
              }
            }
          }
          .tree-item-name{
            display: flex;
            align-items: center;

            .icon-box{
              flex-shrink: 0;
              display: inline-block;
              width: 22px;
              height: 22px;
              background: #6F94FF;
              border-radius: 50%;
              text-align: center;
              line-height: 21px;
              margin-left: 6px;
              i{
                color: #ffff;
                font-size: 12px;
              }
            }

            .folder-name{
              margin-left: 4px;
              // overflow: hidden;
              // text-overflow: ellipsis;
              // white-space: nowrap;
              color: #000000;
              max-width: 540px;
              text-overflow: ellipsis;
              // display: -webkit-box; //使用了flex，需要加
              overflow: hidden;     //超出隐藏
              word-break: break-all; //纯英文、数字、中文
              text-overflow: ellipsis;  //省略号
              -webkit-box-orient: vertical; //垂直
              -webkit-line-clamp: 1;  //显示一行
              white-space:nowrap;   //合并空白
            }
          }
          .tree-item-type{
            display: flex;
            align-items: center;
            img{
              margin-right: 6px;
            }
          }
          .custom-tree-node-left{
            max-width: 130px;
            flex: none;
            margin: 10px 10px 10px 0;
            overflow: hidden;
            text-overflow:ellipsis;
            .custom-tree-node-label{
              overflow: hidden;
              text-overflow:ellipsis;
              white-space: nowrap;
              max-width: 130px;
            }
          }
          &:hover{
            .operation-btn{
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
      // &.is-expanded{
      //   .custom-tree-node{
      //     padding: 0;
      //   }
      // }

    }

    // 去掉最顶层的虚线，放最下面样式才不会被上面的覆盖了
    & > .el-tree-node::after {
      border-top: none;
    }

    & > .el-tree-node::before {
      border-left: none;
    }
    .el-checkbox{
      position: fixed;
      left: 90px;
    }

  }
  .loadMore{
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #366aff;
  }
  .aiTagPopup{
    z-index: 3000!important;
    .label-bg{
      background-color: #FAFBFC;
      height: 38px;
      padding: 0px 14px;
    }
  }
  .drawerHeader{
    width: 100%;
    flex: none;
    height: 48px;
    //height: 45px;
    background: #F6F8FD;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    box-sizing: border-box;
    .tagSet{
      font-size: 16px;
      font-weight: 400;
      color: #000000;
      padding: 12px 0;
      border-bottom: 2px solid #366aff;
    }
    .guoran-shanchu{
      color: #616161;
      font-size: 18px;
    }
  }
  .aiExpandDrawer{
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .ai-drawer-container{
    flex: auto;
    overflow-y: auto;
    // height: calc(100% - 130px);
    .ai-process{
      height: 49px;
      width: calc(100% - 40px);
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 20px;
      i{
        margin-right: 10px;
      }
    }
    .ai-loading{
      background: #F7F9FF;
      i{
        color: #366aff;
        animation: fadeam 10s infinite;
      }
    }
    @keyframes fadeam{
      100%{
        transform: rotate(360deg);
      }
    }
    .ai-success{
      background: #EBFAF2;
      i{
        color: #04B051;
      }
    }
    .ai-error{
      background: #FFF6F7;
      color: #666666;
      cursor: pointer;
      i{
        color: #FE5965;
      }
    }
    .tag-tree{
      height: calc(100% - 90px);
      overflow-y: scroll;
      padding: 0 20px;
      /deep/.aiTagTree{
        padding-left: 10px;
        .el-tree-node{
          position: relative;
          .el-tree-node__children {
            padding-left: 30px; // 缩进量
          }
          // 竖线
          &::before {
            content: "";
            height: 100%;
            width: 1px;
            position: absolute;
            left: -3px;
            top: -40px;
            border-width: 1px;
            border-left: 1px dashed #4A7FEB;
          }
          // 当前层最后一个节点的竖线高度固定
          &:last-child::before {
            height: 60px; // 可以自己调节到合适数值
          }
          // 横线
          &::after {
            content: "";
            width:36px;
            height: 20px;
            position: absolute;
            left: -3px;
            top: 20px;
            border-width: 1px;
            border-top: 1px dashed #4A7FEB;
            z-index: 0;
          }
          .el-tree-node__content {
            height: 40px;
            padding-left: 10px!important;
            .el-tree-node__expand-icon{
              flex: none;
              margin-left: 6px;
              width: 25px;
              height: 25px;
              background: #D8E2FF;
              border-radius: 4px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 16px;
              color: #366aff;
              padding: 0;
              z-index: 10;
              &.is-leaf{
                background-color: transparent;
                color: transparent;
              }
              .el-icon-caret-right:before{
                //width: 16px;
                //height: 16px;
                //font-size: 16px;
              }

            }
          }
        }
        // 去掉最顶层的虚线，放最下面样式才不会被上面的覆盖了
        & > .el-tree-node::after {
          border-top: none;
        }

        & > .el-tree-node::before {
          border-left: none;
        }
        .custom-tree-node{
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          .tree-right{
            flex: none;
            width: 130px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .tree-btn{
              width: 60px;
              height: 24px;
              border-radius: 13px;
              font-size: 13px;
              flex: none;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
            }
            .save-btn{
              background: #366AFF;
              color: #ffffff;
            }
            .ignore{
              background-color:#ffffff;
              border: 1px solid #E0E6F7;
              color: #000000;
            }
            .save-tag-success-tips{
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100%;
              color: #366aff;
            }
          }
        }
        .is-disabled{
          display: none;
        }
        .el-checkbox{
          // position: fixed;
          // right: 700px;
          margin-left: 6px;
        }
      }
    }
  }
  .drawer-footer{
    flex: none;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    .footer-left{
      display: flex;
      align-items: center;
      .check-number{
        margin-left: 10px;
        display:flex;
        align-items: center;
      }
    }
    .footer-right{
      display: flex;
      align-items: center;
      flex: 1;
      justify-content: flex-end;
      .btn-item{
        flex: none;
        width: 86px;
        height: 32px;
        border-radius: 19px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      .close-btn{
        background-color: #ffffff;
        color: #000000;
        border: 2px solid #E0E6F7;
      }
      .confirm-btn{
        color: #ffffff;
        background: #366AFF;
        margin-left: 8px;
        border: 2px solid #366AFF;
        margin-right: 12px;
      }
    }
  }

}
</style>