// /***
//  * 自定义弹框事件 (处理多次弹出事件)
//  *  */

function messageFn(type, message, duration = 3000) {
    this.$message.closeAll();
    this.$message({
        type: type,
        message: message,
        duration: duration
    });
}

function newInitWaterMark(elId, textValue) {
    //默认设置
    console.log('elId',elId,textValue)
    var defaultSettings = {

        watermark_txt: "",

        visitorWatermark_txt: "",

        watermark_x: 0, //水印起始位置x轴坐标

        watermark_y: 0, //水印起始位置Y轴坐标

        watermark_rows: 0, //水印行数

        watermark_cols: 0, //水印列数

        watermark_x_space: 40, //水印x轴间隔

        watermark_y_space: 40, //水印y轴间隔

        watermark_color: "black", //水印字体颜色

        watermark_alpha: 0.3, //水印透明度

        watermark_fontsize: "12px", //水印字体大小

        watermark_font: "微软雅黑", //水印字体

        watermark_width: 100, //水印宽度

        watermark_height: 60, //水印长度

        watermark_angle: 20 //水印倾斜度数

    };
    let textWatermarkValue = textValue;
    if (textWatermarkValue) {
        defaultSettings.watermark_txt = textWatermarkValue;
    }
    if (!textWatermarkValue) {
        return;
    }

    //采用配置项替换默认值，作用类似jquery.extend

    if (arguments.length === 1 && typeof arguments[0] === "object") {

        // 获取参数配置

        var src = arguments[0];

        for (let key in src) {

            if (src[key] && defaultSettings[key] && src[key] === defaultSettings[key])

                continue;

            else if (src[key])

                defaultSettings[key] = src[key];

        }

    }

    var oTemp = document.createDocumentFragment();

    //获取页面最大宽度

    var page_width = Math.max(document.getElementById(elId).scrollWidth, document.getElementById(elId).clientWidth);

    var cutWidth = page_width * 0.0150;

    page_width = page_width - cutWidth;

    //获取页面最大高度
    var page_height = document.getElementById(elId).scrollHeight;

    // var page_height = Math.max(document.body.scrollHeight, document.body.clientHeight);

    // var page_height = document.body.scrollHeight+document.body.scrollTop;

    //如果将水印列数设置为0，或水印列数设置过大，超过页面最大宽度，则重新计算水印列数和水印x轴间隔

    if (defaultSettings.watermark_cols == 0 || (parseInt(defaultSettings.watermark_x + defaultSettings

        .watermark_width * defaultSettings.watermark_cols + defaultSettings.watermark_x_space * (

        defaultSettings.watermark_cols - 1)) > page_width)) {

        defaultSettings.watermark_cols = parseInt((page_width - defaultSettings.watermark_x + defaultSettings

            .watermark_x_space) / (defaultSettings.watermark_width + defaultSettings

            .watermark_x_space));

        defaultSettings.watermark_x_space = parseInt((page_width - defaultSettings.watermark_x -

            defaultSettings

                .watermark_width * defaultSettings.watermark_cols) / (defaultSettings.watermark_cols - 1));

    }

    //如果将水印行数设置为0，或水印行数设置过大，超过页面最大长度，则重新计算水印行数和水印y轴间隔

    if (defaultSettings.watermark_rows == 0 || (parseInt(defaultSettings.watermark_y + defaultSettings

        .watermark_height * defaultSettings.watermark_rows + defaultSettings.watermark_y_space * (

        defaultSettings.watermark_rows - 1)) > page_height)) {

        defaultSettings.watermark_rows = parseInt((defaultSettings.watermark_y_space + page_height -

            defaultSettings

                .watermark_y) / (defaultSettings.watermark_height + defaultSettings.watermark_y_space));

        defaultSettings.watermark_y_space = parseInt(((page_height - defaultSettings.watermark_y) -

            defaultSettings

                .watermark_height * defaultSettings.watermark_rows) / (defaultSettings.watermark_rows - 1));

    }

    var x;
    var y;
    for (var i = 0; i < defaultSettings.watermark_rows; i++) {
        y = defaultSettings.watermark_y + (defaultSettings.watermark_y_space + defaultSettings.watermark_height) * i;
        for (var j = 0; j < defaultSettings.watermark_cols; j++) {
            x = defaultSettings.watermark_x + (defaultSettings.watermark_width + defaultSettings.watermark_x_space) * j;
            var mask_div = document.createElement("div");

            mask_div.id = "mask_div" + i + j;
            mask_div.className = "mask_div";
            mask_div.style.webkitTransform = "rotate(-" + defaultSettings.watermark_angle + "deg)";
            mask_div.style.MozTransform = "rotate(-" + defaultSettings.watermark_angle + "deg)";
            mask_div.style.msTransform = "rotate(-" + defaultSettings.watermark_angle + "deg)";
            mask_div.style.OTransform = "rotate(-" + defaultSettings.watermark_angle + "deg)";
            mask_div.style.transform = "rotate(-" + defaultSettings.watermark_angle + "deg)";
            mask_div.style.visibility = "";
            mask_div.style.position = "absolute";
            //奇偶行错开，这样水印就不对齐，显的不呆板
            mask_div.style.left = x + 20 + "px";
            if(defaultSettings.watermark_txt.indexOf('ww-open-data') != -1) {
                mask_div.innerHTML = defaultSettings.watermark_txt;
            } else {
                mask_div.appendChild(document.createTextNode(defaultSettings.watermark_txt));
            }
            mask_div.style.top = y + "px";

            mask_div.style.overflow = "hidden";

            mask_div.style.pointerEvents = "none"; //让水印不遮挡页面的点击事件

            mask_div.style.opacity = defaultSettings.watermark_alpha;

            mask_div.style.fontSize = defaultSettings.watermark_fontsize;

            mask_div.style.fontFamily = defaultSettings.watermark_font;

            mask_div.style.color = defaultSettings.watermark_color;

            mask_div.style.textAlign = "center";

            mask_div.style.width = defaultSettings.watermark_width + "px";

            mask_div.style.height = defaultSettings.watermark_width + "px";

            mask_div.style.display = "block";

            mask_div.style.wordBreak = 'break-all'

            oTemp.appendChild(mask_div);
        }
    }
    document.getElementById(elId).appendChild(oTemp);
}

function hasManagement(managePermission){
    let bool = false;
    let roleId = sessionStorage.getItem('roleId')
    if (roleId == 1){
        return true
    }
    if (managePermission){
        let groupIds = sessionStorage.getItem('_groupIds') ? JSON.parse(sessionStorage.getItem('_groupIds')) : [];
        let userId = sessionStorage.getItem('_uid') ? Number(sessionStorage.getItem('_uid')) : ""
        if (groupIds){
            groupIds = groupIds.map(res => {
                return Number(res)
            })
        }
        if (managePermission.includes(userId)){
            bool = true;
        } else if (groupIds){
            for (let i=0;i<groupIds.length;i++){
                if (managePermission.includes(groupIds[i])){
                    bool = true;
                    break;
                }
            }
        }
    }
    return bool;
}
export default {
    messageFn,
    newInitWaterMark,
    hasManagement
};
