<template>
  <div id="folderDetails">
    <div class="operationButton">
      <!-- v-if="!currentNodeCopy.yuQueId && !currentNodeCopy.feishuToken" -->
      <template v-if="false">
        <div class="buttonItem" @click="editFolder">
          <i class="iconfont guoran-tongyichicun-16-15-lianjibianji"></i>
          <span>编辑</span>
        </div>
        <div class="buttonItem" @click="moveTo" >
          <i class="iconfont guoran-tongyichicun-yidong"></i>
          <span>移动到</span>
        </div>
        <template v-if="isVersionManage">
          <div class="buttonItem set-authority" @click="handleCommand('authority')">
            <i class="iconfont guoran-tongyichicun-shezhiquanxian"></i>
            <span>权限设置</span>
          </div>
          <div class="buttonItem" @click="handleCommand('del')">
            <i class="iconfont guoran-tongyichicun-18-13-shanchu"></i>
            <span>删除</span>
          </div>
        </template>
        <el-dropdown @command="handleCommand" trigger="click" v-else>
          <div class="buttonItem">
          <span class="el-dropdown-more">
            <i class="iconfont guoran-qita1"></i>
            <span>更多</span>
          </span>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="synchronization"  v-if="currentNodeCopy.associatedSyncStatus && !isAskLight()">
              <i class="iconfont guoran-tongyichicun-16-12-shuaxin"></i>
              同步
            </el-dropdown-item>
            <el-dropdown-item command="authority">
              <i class="iconfont guoran-tongyichicun-shezhiquanxian"></i>
              权限设置
            </el-dropdown-item>
            <el-dropdown-item command="del">
              <i class="iconfont guoran-piliangshanchu"></i>
              删除
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
      <span>{{ currentNodeCopy.type === 1 ? $t('knowledgeManagement.folderDetails.knowledgeTitle') : $t('knowledgeManagement.folderDetails.knowledgeTitle') }}</span>
      <div class="buttonItem" @click="editFolder" v-if="currentNodeCopy.parentId !==-1 && !isAskLight() && (hasManagement(currentNodeCopy) || roleId == 1)">
        <i class="iconfont guoran-tongyichicun-16-15-lianjibianji"></i>
        <span>{{ $t('knowledgeManagement.folderDetails.edit') }}</span>
      </div>
    </div>
    <div class="folderContainer">
      <div class="detailInfo">
        <div class="folderTitle">
          {{ currentNodeCopy.name }}
        </div>
        <div class="folderInfo description">
          <span class="title">{{ $t('knowledgeManagement.folderDetails.description') }}</span>
          <span v-if="currentNodeCopy.description">{{ currentNodeCopy.description }}</span>
          <span class="description" v-else>{{ $t('knowledgeManagement.folderDetails.emptyDescription') }}</span>
        </div>
        <el-divider></el-divider>
        <template v-if="currentNodeCopy.type == 1">
          <div class="folderInfo">
            <span class="title">{{$t('status.status')}}</span>
            <div :class="['newsStatus',!currentNodeCopy.currentVersion || !currentNodeCopy.currentVersion.state || currentNodeCopy.currentVersion.state === 'UNPUBLISHED' ?
                'unPublishStatus' : (currentNodeCopy.currentVersion.state === 'PUBLISH' || currentNodeCopy.currentVersion.state === 'PUBLISHING' ? 'publishStatus' : 'archiveStatus')]">
                {{ currentNodeCopy.currentVersion | statusFilter }} 
            </div>
          </div>
          <el-divider></el-divider>
        </template>
        <div class="folderInfo">
          <span class="title">{{ $t('knowledgeManagement.folderDetails.type') }}</span>
          <span class="folderType">
            <template v-if="currentNodeCopy.type == 1">
               <img src="../../assets/images/video.png"
                    v-if="setSrc(currentNodeCopy.fileSuffix) == 'video'" />
              <img src="../../assets/images/image.png"
                   v-else-if="setSrc(currentNodeCopy.fileSuffix) == 'image'" />
              <img src="../../assets/images/txt.png"
                   v-else-if="setSrc(currentNodeCopy.fileSuffix) == 'txt'" />
              <img src="../../assets/images/word.png"
                   v-else-if="setSrc(currentNodeCopy.fileSuffix) == 'word'" />
              <img src="../../assets/images/pdf.png"
                   v-else-if="setSrc(currentNodeCopy.fileSuffix) == 'pdf'" />
              <img src="../../assets/images/ppt.png"
                   v-else-if="setSrc(currentNodeCopy.fileSuffix) == 'ppt'" />
              <img src="../../assets/images/excel.png"
                   v-else-if="setSrc(currentNodeCopy.fileSuffix) == 'excel'" />
              <span>
                 {{currentNodeCopy.fileSuffix ? currentNodeCopy.fileSuffix.replace('.',"") : '--'}}
              </span>
            </template>
            <template v-else>
              <img  src="../../assets/images/file.png">
              {{ $t('knowledgeManagement.folderDetails.emptyDescription') }}
            </template>
        </span>
        </div>
        <el-divider></el-divider>
        <div class="folderInfo">
          <span class="title">{{ $t('knowledgeManagement.folderDetails.size') }}</span>
          <span v-if="currentNodeCopy.volume">{{currentNodeCopy.volume | volume}}</span>
          <span v-else>--</span>
        </div>
        <el-divider></el-divider>
          <div class="folderInfo" v-if="currentNodeCopy.type == 1">
            <span class="title">{{ $t('knowledgeManagement.folderDetails.version') }}</span>
            <span>v{{currentNodeCopy.version}}</span>
          </div>
          <el-divider v-if="currentNodeCopy.type == 1"></el-divider>
          <div class="folderInfo">
            <span class="title">{{ $t('knowledgeManagement.folderDetails.owner') }}</span>
            <span>{{ $t('knowledgeManagement.folderDetails.enterprise') }}</span>
          </div>
          <el-divider></el-divider>
          <template v-if="currentNodeCopy.type == 1">
            <div class="validTime">
              <span class="title">{{$t('knowledgeManagement.folderManagement.form.effectTime')}}</span>
              <template v-if="currentNodeCopy.validTo && currentNodeCopy.validFrom">
                <span>{{ new Date(currentNodeCopy.validFrom).format('yyyy-MM-dd hh:mm') }} ~ {{ new Date(currentNodeCopy.validTo).format('yyyy-MM-dd hh:mm') }}</span>
              </template>
              <template v-else> -- </template>
            </div>
            <el-divider></el-divider>
          </template>
      </div>
      <div class="folderRight">
        <template v-if="currentNodeCopy.parentId !==-1">
          <div class="title">
            <span>{{ $t('knowledgeManagement.folderDetails.permissionsAndSecurity') }}</span>
            <span @click="permissionSetting" v-if="currentNodeCopy.id !==-1 && !isAskLight() && (hasManagement(currentNodeCopy) || roleId == 1)"><i class="iconfont guoran-shezhi1"></i></span>
          </div>
          <div class="authority management">
            <span class="head">{{ $t('knowledgeManagement.folderDetails.managePermission') }}</span>
            <div class="authorityList">
              <template v-if="!currentNodeCopy.managePermission">
                --
              </template>
              <template v-else>
                <div v-for="(item,index) in JSON.parse(currentNodeCopy.managePermission)"
                     :key="index"
                     class="authorityInfo"
                >
                  <el-tooltip placement="top">
                    <div slot="content">
                        <span class="user-name" style="margin-right:4px;">
                          <span>{{item.name}}</span>
                        </span>
                    </div>
                    <div class="avatar" v-if="item.name">{{(item.name).substr(-2)}}</div>
                  </el-tooltip>
                </div>
              </template>
            </div>
          </div>
          <div class="authority management" v-if="!_isLiBang()">
            <span class="head">{{ $t('knowledgeManagement.folderDetails.editPermission') }}</span>
            <div class="authorityList">
              <template v-if="!currentNodeCopy.editPermission">
                --
              </template>
              <template v-else>
                <div v-for="(item,index) in JSON.parse(currentNodeCopy.editPermission)"
                     :key="index"
                     class="authorityInfo"
                >
                  <el-tooltip placement="top">
                    <div slot="content">
                        <span class="user-name" style="margin-right:4px;">
                          <span>{{item.name}}</span>
                        </span>
                    </div>
                    <div class="avatar" v-if="item.name">{{(item.name).substr(-2)}}</div>
                  </el-tooltip>
                </div>
              </template>
            </div>
          </div>
          <div class="authority">
              <span class="head">{{ $t('knowledgeManagement.folderDetails.visitPermission') }}</span>
              <div class="authorityList">
                <template v-if="!currentNodeCopy.visitPermission">
                  --
                </template>
                <template v-else>
                  <div v-for="(item,index) in JSON.parse(currentNodeCopy.visitPermission)"
                      :key="index"
                      class="authorityInfo"
                  >
                    <template v-if="item.type === 1">
                      <div class="departAvatar">
                        <i class="iconfont guoran-chanpin"></i>
                      </div>
                      <div class="departName">
                        <open-data :openid="item.name" :type="'departmentName'" v-if="isUseOpenData()"></open-data>
                        <template v-else>
                          {{item.name}}
                        </template>
                      </div>
                    </template>
                    <template v-else>
                      <el-tooltip placement="top">
                        <div slot="content">
                                <span class="user-name" style="margin-right:4px;">
                                  <open-data :openid="item.name" :type="'userName'" v-if="isUseOpenData()"></open-data>
                                  <span v-else>{{item.name}}</span>
                                </span>
                                <span class="dept-name" style="color:#d2d0d0;" v-for="(deptFullNameItem,deptFullNameIndex) in item.deptFullName" :key="deptFullNameIndex">
                                  <span v-if="deptFullNameIndex === 0">(</span>
                                  <open-data :openid="deptFullNameItem" :type="'userName'" v-if="isUseOpenData()"></open-data>
                                  <span>{{deptFullNameItem}}</span>
                                  <span v-if="deptFullNameIndex +1 < item.deptFullName.length">/</span>
                                  <span v-if="deptFullNameIndex+1 === item.deptFullName.length">)</span>
                                </span>
                            
                             
                            <!-- </div> -->
                        </div>
                        <open-data :openid="item.name" :type="'userName'" v-if="isUseOpenData()"></open-data>
                        <div class="avatar" v-if="item.name && !isUseOpenData()">{{(item.name).substr(-2)}}</div>
                      </el-tooltip>
                    </template>
                  </div>
                </template>
              </div>
              <div class="safeHead">
                <span>{{ $t('knowledgeManagement.folderDetails.security') }}</span>
              </div>
              <div class="watermark">
                <span>{{ $t('knowledgeManagement.folderDetails.watermark') }}</span>
                <div :class="currentNodeCopy.textWatermark ? 'openWatermark' : 'closeWatermark'">
                  <i :class="currentNodeCopy.textWatermark ? 'el-icon-check' : 'el-icon-close'"></i>
                  <span>{{currentNodeCopy.textWatermark ? $t('knowledgeManagement.folderDetails.opened') : $t('knowledgeManagement.folderDetails.closed')}}</span>
                </div>
              </div>
              <el-divider></el-divider>
              <div class="watermark">
                <span>{{ $t('knowledgeManagement.folderDetails.download') }}</span>
                <div :class="currentNodeCopy.isDownload ? 'openWatermark' : 'closeWatermark'">
                  <i :class="currentNodeCopy.isDownload ? 'el-icon-check' : 'el-icon-close'"></i>
                  <span>{{currentNodeCopy.isDownload ? $t('knowledgeManagement.folderDetails.opened') : $t('knowledgeManagement.folderDetails.closed')}}</span>
                </div>
              </div>
            <el-divider></el-divider>
          </div>
        </template>
        <template v-if="currentNodeCopy.parentId !==-1 ">
          <div class="folderSource">
            <span class="head">{{ $t('knowledgeManagement.folderDetails.source') }}</span>
            <span>{{ folderSource() }}</span>
          </div>
          <el-divider></el-divider>
        </template>
        <template v-if="currentNodeCopy.parentId !==-1 && currentNodeCopy.source !== 1">
          <div class="folderSeat">
            <span class="head">{{ $t('knowledgeManagement.folderDetails.location') }}</span>
            <span>{{currentNodeCopy.sourceLocation ? currentNodeCopy.sourceLocation : '--'}}</span>
          </div>
          <el-divider></el-divider>
        </template>
        <!-- <template v-if="(currentNodeCopy.yuQueId || currentNodeCopy.feishuToken) && !isAskLight()">
          <div class="synchronization" @click="synchronization">
            <span class="synchronizationButton">同步</span>
          </div>
        </template> -->
        <template v-if="currentNodeCopy.parentId !==-1 && currentNodeCopy.type == 1">
          <div class="title">
            <span>{{ $t('knowledgeManagement.folderDetails.knowledgeTags') }}</span>
            <span class="label-set" @click="labelSet" v-if="!isAskLight() && (hasManagement(currentNodeCopy) || roleId == 1)">
              <i class="iconfont guoran-shezhi1"></i>
            </span>
          </div>
          <div class="tag-list">
            <div class="associated-tags">
              <div class="tag-item"
                   v-for="(item,index) in associatedTags"
                   :key="index"
                   @click="openAssociationKnowledge(item)"
              >
                <el-tooltip
                    class="item"
                    effect="dark"
                    :content="$t('knowledgeManagement.folderDetails.AIExtraction')"
                    placement="top-start"
                >
                  <span style="display: flex;align-items: center;" v-if="item.aiCreate || (currentNodeCopy.autoAssociatedTags && currentNodeCopy.autoAssociatedTags.indexOf(item._id) !==-1)">
                    <i class="iconfont guoran-AI"></i>
                    <span> {{item.name}}</span>
                  </span>
                </el-tooltip>
                <template v-if="!item.aiCreate && !(currentNodeCopy.autoAssociatedTags && currentNodeCopy.autoAssociatedTags.indexOf(item._id) !==-1)">
                  <span> {{item.name}}</span>
                </template>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <employee-structure
        ref="employeeStructure"
        :permissionList="permissionList"
        @confirmPermission="confirmPermission"
        @cancelPermission="cancelPermission"
        v-if="false"
        @onNodeClick="onNodeClick"
    ></employee-structure>
    <knowledge-permissions
        ref="knowledgePermissions"
        v-if="permissionDialog"
        @confirmKnowledgePermissions="confirmPermission"
    ></knowledge-permissions>
    <delete-dialog @closeEvent="deleteFileDialog = false" v-if="deleteFileDialog">
      <div slot="popup-container" class="popup-container">
        <div class="delete-title">{{ $t('knowledgeManagement.knowledgeTable.popupContainerTitle') }}</div>
        <div class="prompt-text">
          {{ $t('knowledgeManagement.knowledgeTable.popupContainerText') }}
        </div>
      </div>
      <div slot="dialog-footer">
        <el-button @click="deleteFileDialog = false" round size="small" plain class="cancel-btn">{{ $t('knowledgeManagement.knowledgeTable.segPopupCancel') }}</el-button>
        <el-button @click="deleteFile" style="background-color: #FE5965 ;color: white;border:none;" size="small"  class="confirm-btn">{{ $t('knowledgeManagement.knowledgeTable.del') }}
        </el-button>
      </div>
    </delete-dialog>
    <popup-com @closeEvent="labelDialog = false" v-if="labelDialog">
      <div slot="popup-name">{{ $t('knowledgeManagement.folderDetails.labelManagement') }}</div>
      <div slot="popup-tip">{{ $t('knowledgeManagement.folderDetails.taggingKnowledge') }}</div>
      <div slot="popup-con">
        <el-form label-position="top" label-width="80px">
          <el-form-item :label="$t('knowledgeManagement.folderDetails.tagging')">
            <el-select-tree ref="selectTree" :no-data-text="$t('common.emptyData')" :multiple="true"></el-select-tree>
          </el-form-item>
        </el-form>
      </div>
      <div slot="dialog-footer">
        <el-button  plain size="small" @click="labelDialog = false" class="cancel-btn">{{ $t('knowledgeManagement.folderDetails.cancel') }}</el-button>
        <el-button  type="primary" size="small" @click="confirmLabelSet"  class="confirm-btn">{{ $t('knowledgeManagement.folderDetails.confirm') }}</el-button>
      </div>
    </popup-com>
    <el-drawer
        :visible.sync="associationKnowledgeDrawer"
        :with-header="false"
        :append-to-body="true"
        :destroy-on-close="true"
        :wrapperClosable="false"
        size="600px"
    >
      <div class="knowledge-drawer-header">
        <div class="header-left">
          <span class="header-title">{{ $t('knowledgeManagement.folderDetails.associationKnowledge') }}</span>
          <div class="tag-label" v-if="checkTag">{{checkTag.name}}</div>
        </div>
        <div class="header-right" @click="associationKnowledgeDrawer = false">
          <i class="iconfont guoran-shanchu"></i>
        </div>
      </div>
      <div class="association-knowledge-table">
        <association-knowledge
            ref="associationKnowledge"
            v-if="associationKnowledgeDrawer"
            @knowledgeUnbind="knowledgeUnbind"
        ></association-knowledge>
      </div>
    </el-drawer>
    <el-drawer
        :visible.sync="editFolderDrawer"
        :with-header="false"
        :append-to-body="true"
        :destroy-on-close="true"
        :wrapperClosable="false"
        size="600px"
    >
      <div class="folder-drawer-header">
        <div class="header-left">
          <span class="header-title">{{ $t('knowledgeManagement.folderDetails.editKnowledge') }}</span>
        </div>
        <div class="header-right" @click="editFolderDrawer = false;editFolderDrawerC = false">
          <i class="iconfont guoran-shanchu"></i>
        </div>
      </div>
      <div class="folder-container" v-if="editFolderDrawerC">
        <folder-form :formFolder="formFolder" ref="folderForm" :currentNode="currentNodeCopy"></folder-form>
      </div>
      <div class="folder-footer">
        <div class="close-btn btn-item" @click="closeDrawer">{{ $t('knowledgeManagement.folderDetails.cancel') }}</div>
        <div class="confirm-btn btn-item" @click="saveFolder">{{ $t('knowledgeManagement.folderDetails.save') }}</div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import EmployeeStructure from "@/components/employeeStructure";
import { requestUrl } from "@/http/requestUrl";
import OpenData from "@/components/openData";
import DeleteDialog from "@/components/deleteDialog";
import KnowledgePermissions from "@/components/knowledgePermissions";
import PopupCom from "@/components/popup";
import ElSelectTree from "@/views/labelManagement/components/elSelectTree";
import AssociationKnowledge from "@/views/knowledgeManagement/components/associationKnowledge";
import FolderForm from "@/views/knowledgeManagement/components/folderForm";
import { mapGetters, mapActions } from 'vuex';
export default {
  name: "folderDetails",
  components: { FolderForm, AssociationKnowledge, ElSelectTree, PopupCom, DeleteDialog, EmployeeStructure,OpenData,KnowledgePermissions },
  data(){
    return{
      deleteFolderDialog:false,
      permissionList:[],
      permissionDialog:false,
      deleteFileDialog:false,
      currentNodeCopy:{},
      currentUrl:"https://portal.test.askbot.cn/open-data.html",
      labelDialog:false,
      associatedTags:[],
      associationKnowledgeDrawer:false,
      checkTag:null,
      roleId:0,
      editFolderDrawer:false,
      directoryTree:[],
      formFolder:{
        name:"",
        description:"",
        parentId:"",
        id:"",
        textWatermark:false,
        isDownload:false,
        textWatermarkValue:"",
        downloadType:2,
        takingEffectTimeType:"noLimit",
        takingEffectTime:[]
      },
      editFolderDrawerC:false,
      saveLoading:false
    }
  },
  props:["currentNode",'isVersionManage',"folderTree"],
  filters:{
    volume(val){
      if (val / 1024 / 1024 / 1024 >= 1){
        return (Math.round(val) / 1024 / 1024 / 1024).toFixed(1) + 'GB'
      }
      else if (val / 1024 / 1024 >= 1){
        return (Math.round(val) / 1024 / 1024 ).toFixed(1) + 'MB'
      }
      else if (val / 1024 >= 1){
        return (Math.round(val ) / 1024).toFixed(1) + ' KB'
      } else {
        return val + 'B'
      }
    },
    statusFilter(val){
      if (!val || !val.state || val.state === 'UNPUBLISHED'){
        return "未发布"
      } else if (val.state === 'PUBLISH' || val.state === 'PUBLISHING'){
        return  "已发布"
      } else {
        return "归档"
      }
    }
  },
  computed:{
    ...mapGetters('knowledge', {
      knowledgeInfo: 'getKnowledge'
    }),
  },
  methods:{
    // 'iframe' + id
    ...mapActions('knowledge', [
      'updateKnowledge'
    ]),
    statusFilter(val){
      if (!val || !val.state || val.state === 'UNPUBLISHED'){
        return this.$t('status.unPublish');
      } else if (val.state === 'PUBLISH'){
        return this.$t('status.publish')
      } else {
        return this.$t('status.documentation')
      }
    },
    folderSource(){
      let str = this.$t('knowledgeManagement.folderDetails.folderSource.systemAddition');
      if (this.currentNodeCopy.source == 1){
         str = this.$t('knowledgeManagement.folderDetails.folderSource.localUpload')
      } else if (this.currentNodeCopy.source == 2){
        str = this.$t('knowledgeManagement.folderDetails.folderSource.qiweiSync')
      } else if (this.currentNodeCopy.source == 3){
        str = this.$t('knowledgeManagement.folderDetails.folderSource.dingpanSync')
      } else if (this.currentNodeCopy.source == 4){
        str = this.$t('knowledgeManagement.folderDetails.folderSource.systemAddition')
      } else if (this.currentNodeCopy.source == 5){
        str = this.$t('knowledgeManagement.folderDetails.folderSource.yuQueSync')
      } else if (this.currentNodeCopy.source == 6){
        str = this.$t('knowledgeManagement.folderDetails.folderSource.feiShuSync')
      }
      return str
    },
    setSrc(type){
      if (type === '.mp4' || type === '.MP4' || type === '.mov' || type === '.MOV'){
        return 'video'
      } else if (type === '.jpg' || type === '.png' || type === '.JPG' || type === '.PNG' || type === '.jpeg' || type === '.JPEG' || type === '.gif'){
        return 'image'
      } else if (type === '.doc' || type === '.docx'){
        return 'word'
      } else if (type === '.pdf'){
        return 'pdf'
      } else if (type === '.ppt' || type === '.pptx'){
        return 'ppt'
      } else if (type === '.xlsx' || type === '.xls'){
        return 'excel'
      } else if (type === '.txt'){
        return 'txt'
      } else {
        return ''
      }
    },
    editFolder(){
      this.editFolderDrawer = true;
      this.$nextTick(() => {
        this.editFolderDrawerC = true;
        this.formFolder.name = this.currentNodeCopy.name;
        this.formFolder.description = this.currentNodeCopy.description;
        this.formFolder.parentId = this.currentNodeCopy.parentId;
        this.formFolder.id = this.currentNodeCopy.id;
        this.formFolder.textWatermark = this.currentNodeCopy.textWatermark;
        this.formFolder.textWatermarkValue = this.currentNodeCopy.textWatermarkValue;
        this.formFolder.isDownload = this.currentNodeCopy.isDownload;
        this.formFolder.downloadType = this.currentNodeCopy.downloadType ? this.currentNodeCopy.downloadType : 2;
        setTimeout(() => {
          if (this.currentNodeCopy.visitPermission){
            let list = JSON.parse(this.currentNodeCopy.visitPermission)
            this.$refs.folderForm.permissionList = list;
            this.$refs.folderForm.permissionsListCopy = this.currentNodeCopy.visitPermission;
          }
          let management = []
          if (this.currentNodeCopy.managePermission){
            management = JSON.parse(this.currentNodeCopy.managePermission)
          }
          this.$refs.folderForm.$refs.accountManagement.managePermission = management;
          this.$refs.folderForm.$refs.accountManagement.managePermissionIds = management.map(res => {
            return res.id
          })

          let editManagement = []
          if (this.currentNodeCopy.editPermission){
            editManagement = JSON.parse(this.currentNodeCopy.editPermission);
          }
          this.$refs.folderForm.$refs.editAccountManagement.managePermission = editManagement;
          this.$refs.folderForm.$refs.editAccountManagement.managePermissionIds = editManagement.map(res => {
            return res.id
          })
          let takingEffectTime = [];
          let takingEffectTimeType = 'noLimit'
          if (this.currentNodeCopy.validFrom && this.currentNodeCopy.validTo){
            takingEffectTimeType = 'custom';
            takingEffectTime = [this.currentNodeCopy.validFrom,this.currentNodeCopy.validTo]
          }
          this.formFolder.takingEffectTimeType = takingEffectTimeType;
          this.formFolder.takingEffectTime = takingEffectTime;
          this.$refs.folderForm.isFolder = this.currentNodeCopy.type === 0 ? true : false;
        },100)
        let url = requestUrl.knowledgeBase.getKnowledgeBaseById + '?id=' + this.currentNodeCopy.parentId;
        this.$http.get(url).then(res => {
          if (res.data.code == 0 && res.data.data){
            /* eslint-disable */
            let data = res.data.data;
            this.$refs.folderForm.parentDirectoryName = data.name;
            /* eslint-enable*/
          }
        })
      })
      // this.$emit('editFolder','edit',this.currentNodeCopy)
    },
    moveTo(){
      this.$emit('moveTo','shift',this.currentNodeCopy)
    },
    handleCommand(command){
      if (command === 'authority'){
        this.permissionSetting();
        this.$emit('handleCommand',command)
      } else if (command === 'del'){
        if (this.currentNode.type == 0){
          this.$emit('handleCommand',command)
        } else {
          this.deleteFileDialog = true;
        }
      } else if (command === 'synchronization'){
        this.synchronization();
      }
    },
    deleteFolder(){

    },
    permissionSetting(){
      this.permissionDialog = true;
      this.permissionList = this.currentNodeCopy.visitPermission ? JSON.parse(this.currentNodeCopy.visitPermission) : []
      this.$nextTick(() => {
        // this.$refs.employeeStructure.permissionDialog = true;
        this.$refs.knowledgePermissions.permissionsObj.permissionsList = this.currentNodeCopy.visitPermission ? JSON.parse(this.currentNodeCopy.visitPermission) : [];
        this.$refs.knowledgePermissions.permissionsListCopy = this.currentNodeCopy.visitPermission ? this.currentNodeCopy.visitPermission : "[]";
        this.$refs.knowledgePermissions.knowledgePermissions = true;
        let management = []
        if (this.currentNodeCopy.managePermission){
          management = JSON.parse(this.currentNodeCopy.managePermission)
        }
        this.$refs.knowledgePermissions.$refs.accountManagement.managePermission = management;
        this.$refs.knowledgePermissions.$refs.accountManagement.managePermissionIds = management.map(res => {
          return res.id
        })

        let editManagement = []
        if (this.currentNodeCopy.editPermission){
          editManagement = JSON.parse(this.currentNodeCopy.editPermission);
        }
        this.$refs.knowledgePermissions.$refs.editAccountManagement.managePermission = editManagement;
        this.$refs.knowledgePermissions.$refs.editAccountManagement.managePermissionIds = editManagement.map(res => {
          return res.id
        })
        this.$refs.knowledgePermissions.permissionsObj.textWatermark = this.currentNodeCopy.textWatermark;
        this.$refs.knowledgePermissions.permissionsObj.textWatermarkValue = this.currentNodeCopy.textWatermarkValue;
        this.$refs.knowledgePermissions.permissionsObj.isDownload = this.currentNodeCopy.isDownload;
        this.$refs.knowledgePermissions.permissionsObj.downloadType = this.currentNodeCopy.downloadType;
        console.log('management',management,this.$refs.knowledgePermissions.$refs.accountManagement.managePermissionIds)
      })
    },
    onNodeClick(data){
      if (data.checked){
        this.permissionList.push(data)
      } else {
        this.permissionList = this.permissionList.filter(item => {
          return item.id !== data.id
        });
      }
    },
    confirmPermission(obj){
      console.debug('obj',obj)
      if (this.saveLoading){
        this.$message({
          message:this.$t('knowledgeManagement.folderManagement.saving'),
          type:"warning"
        })
        return;
      }
      let permissionList = obj.permissionsList.map(res => {
        let obj = {
          id:res.id,
          type:res.type !== undefined ? res.type : (res.source !== undefined ? 0 : 1),
          name:res.name ? res.name : res.label
        }
        return obj
      });
      if (permissionList.length == 0){
        this.$message({
          message:this.$t('knowledgeManagement.folderManagement.script.selecteVisitePermissions'),
          type:"warning"
        })
        return;
      }
      let managePermission = obj.accountManagement;
      let editPermission = obj.editPermissions
      let corpId = sessionStorage.getItem('_corpId');
      let url = requestUrl.knowledgeBase.permissionSetV2 + '?id=' + this.currentNode.id + '&corpId=' + corpId;
      let param = {
        visitPermission:permissionList,
        managePermission,
        editPermission,
        textWatermark:obj.textWatermark,
        textWatermarkValue:obj.textWatermarkValue,
        isDownload:obj.isDownload,
        downloadType:obj.downloadType
      }
      this.saveLoading = true;
      this.$http.post(url,param).then(res => {
        if (res.data.code == '0'){
          this.$message({
            message:this.$t('knowledgeManagement.folderManagement.permissionSaveSuccess'),
            type:'success',
            duration:2000
          })
          // this.$refs.employeeStructure.permissionDialog = false;
          this.$refs.knowledgePermissions.knowledgePermissions = false;
          this.permissionDialog = false;
          // 调用此接口会返回组织架构外的人员信息
          // this.getPermissionName(permissionList);
          this.currentNodeCopy.visitPermission = JSON.stringify(permissionList);
          this.currentNodeCopy.managePermission = JSON.stringify(managePermission);
          this.currentNodeCopy.editPermission = JSON.stringify(editPermission);
          this.$set(this.currentNode,'visitPermission',JSON.stringify(permissionList))
          this.$set(this.currentNode,'managePermission',JSON.stringify(managePermission))
          this.$set(this.currentNode,'editPermission',JSON.stringify(editPermission))
          this.$emit('getKnowledgeData',this.currentNodeCopy)
          if (obj.textWatermark !== this.currentNodeCopy.textWatermark ||
              obj.textWatermarkValue !== this.currentNodeCopy.textWatermarkValue ||
              obj.isDownload !== this.currentNodeCopy.isDownload ||
              obj.downloadType !== this.currentNodeCopy.downloadType){
            this.$emit('changeSafe')
          }
          this.currentNodeCopy.textWatermark = obj.textWatermark;
          this.currentNodeCopy.textWatermarkValue = obj.textWatermarkValue;
          this.currentNodeCopy.isDownload = obj.isDownload;
          this.currentNodeCopy.downloadType = obj.downloadType;
        }
        this.saveLoading = false;
        if (editPermission){
          this.knowledgeInfo.editPermission = editPermission.map(res => {
            return Number(res.id)
          });
        } else {
          this.knowledgeInfo.editPermission = null
        }
        this.updateKnowledge(this.knowledgeInfo);
      })
    },
    cancelPermission(){
      this.$refs.employeeStructure.permissionDialog = false;
      this.permissionDialog = false;
    },
    getPermissionName(list){
      let url = requestUrl.knowledgeBase.getPermissionName;
      this.$http.post(url,list).then(res => {
        if (res.data.code == '0' && res.data.data){
          this.currentNodeCopy.visitPermission = JSON.stringify(res.data.data);
          this.$set(this.currentNode,'visitPermission',JSON.stringify(res.data.data))
        }
      })
    },
    /**
     * 一键同步文件或文件夹
     * */
    synchronization(){
      let mainId = sessionStorage.getItem('_mainId');
      let url = ''
      //  // 5是语雀，6是飞书
      if(this.currentNode.source === 5){
          url = requestUrl.channel.autoSyncYuQue + '?mainId=' + mainId;
        } else {
          url = requestUrl.feishu.syncFeishu + '?mainId=' + mainId;
        }
      // let url = requestUrl.knowledgeBase.fileSync + '?id=' + this.currentNode.id;
      this.$http.get(url).then(res => {
        if (res.data.code == '0'){
          this.$emit('synchronization',this.currentNode)
          // this.$message({
          //   message:"同步成功",
          //   duration:2000,
          //   type:"success"
          // })
        }
      })
    },
    deleteFile(){
      console.log(376);
      let url = requestUrl.knowledgeBase.moveDeleteFolder
      let obj = {
        deleteNodeIds:[this.currentNode.id],
        newParentId:null
      }
      this.$http.delete(url,{
        data:obj
      }).then(res => {
        if (res.data.code == '0'){
          this.$message({
            message:this.$t('knowledgeManagement.folderManagement.script.deleteSuccess'),
            duration:2000,
            type:"success"
          })
          this.deleteFileDialog = false
          this.$emit('deleteDetail')
        }
      })
    },
    getNodeDetail(node){
      console.debug('node',node)
      let url = requestUrl.knowledgeBase.getKnowledgeBaseById + '?id=' + node.id;
      this.$http.get(url).then(res => {
        if (res.data.code == 0 && res.data.data){
          /* eslint-disable */
          let data = res.data.data;
          this.currentNodeCopy = data;          
          /* eslint-enable*/
        }
      })
    },
    iframeLoad(){
      // this.$nextTick(() => {
      //   let iframe = document.querySelector('.iframe' + item.id);
      //   iframe.width = iframe.contentDocument.body.clientWidth;
      //   console.log(iframe,'iframe');
      // })
    },
    //打标签
    labelSet(){
      this.labelDialog = true;
      this.$nextTick(() => {
        this.$refs.selectTree.checkTagList = JSON.parse(JSON.stringify(this.associatedTags)) ;
        this.$refs.selectTree.tagIds = this.associatedTags.map(item => {
          return item._id
        });
        this.$refs.selectTree.selectValue = this.associatedTags.map(item => {
          return item.name
        });
      })
    },
    confirmLabelSet(){
      let url = requestUrl.tagManage.bindTag + this.currentNode.knowledgeId;
      let tagIds = {
        tagIds:this.$refs.selectTree.tagIds
      }
      this.$http.put(url,tagIds).then(res => {
        if (res.data.code == 0){
          this.$message({
            message:this.$t('knowledgeManagement.folderDetails.tagSuccess'),
            type:"success"
          })
          this.currentNodeCopy.associatedTags = tagIds.tagIds;
          this.$set(this.currentNode,'associatedTags',tagIds.tagIds)
          this.currentNodeCopy.autoAssociatedTags = [];
          //后端处理接口需要时间，立马去调用会存在还未更改完数据
          setTimeout(() => {
            this.getTagList(tagIds.tagIds);
          },500)

          this.labelDialog = false;
        }
      })
    },
    getTagList(tagIds){
      this.associatedTags = [];
      let ids = tagIds ? tagIds : this.currentNode.associatedTags;
      if (ids){
        let url = requestUrl.tagManage.getTagsById;
        this.$http.post(url,{
          tagIds:ids
        }).then(res => {
          if (res.data.code == 0){
            this.associatedTags = res.data.data;
          }
        })
      }
    },
    openAssociationKnowledge(item){
      this.checkTag = item;
      this.associationKnowledgeDrawer = true;
      this.$nextTick(() => {
        this.$refs.associationKnowledge.getKnowledge(item._id,this.folderTree);
      })
    },
    //解除绑定时判断当前知识是否要更新标签列表
    knowledgeUnbind(id){
      if (id === this.currentNodeCopy.knowledgeId){
        this.currentNodeCopy.associatedTags = this.currentNodeCopy.associatedTags.filter(item => item !== this.checkTag._id);
        this.associatedTags = this.associatedTags.filter(item => item._id !== this.checkTag._id);
      }
    },
    closeDrawer(){
      this.editFolderDrawer = false;
      this.editFolderDrawerC = false;
    },
    saveFolder(){
      console.log(
        865, this.$refs.folderForm.parentChildrenNum
      );
      
      if (this.saveLoading){
        this.$message({
          message:this.$t('knowledgeManagement.folderManagement.saving'),
          type:"warning"
        })
        return;
      }
      let url = requestUrl.knowledgeBase.updateKnowledge
      let visitPermission = this.$refs.folderForm.permissionList;
      let managePermissionList = this.$refs.folderForm.$refs.accountManagement.managePermission;
      let editPermissionList = this.$refs.folderForm.$refs.editAccountManagement.managePermission;
      let validFrom = null;
      let validTo = null;
      if (this.formFolder.takingEffectTime && this.formFolder.takingEffectTime.length > 0){
        validFrom = this.formFolder.takingEffectTime[0];
        validTo = this.formFolder.takingEffectTime[1]
      }
      let obj = {
        corpId:sessionStorage.getItem('_corpId'),
        parentId:this.formFolder.parentId,
        name:this.formFolder.name,
        description:this.formFolder.description,
        orderNum:this.$refs.folderForm.parentChildrenNum,
        type:0,
        visitPermissionList:visitPermission.map(res => {
          let obj = {
            id:res.id,
            type:res.type !== undefined ? res.type : (res.source !== undefined ? 0 : 1),
            name:res.name ? res.name : res.label
          }
          return obj
        }),
        managePermissionList,
        editPermissionList,
        textWatermark:this.$refs.folderForm.formFolders.textWatermark,
        textWatermarkValue:this.$refs.folderForm.formFolders.textWatermarkValue,
        isDownload:this.$refs.folderForm.formFolders.isDownload,
        downloadType:this.$refs.folderForm.formFolders.downloadType,
        validFrom,
        validTo
      };
      if (!obj.name){
        this.$message({
          message:this.$t('knowledgeManagement.folderManagement.script.FolderRequeridMessage'),
          duration:2000,
          type:'warning'
        })
        return
      }
      if (obj.visitPermissionList.length == 0){
        this.$message({
          message:this.$t('knowledgeManagement.folderManagement.script.selecteVisitePermissions'),
          duration:2000,
          type:'warning'
        })
        return
      }
      obj.type = this.currentNodeCopy.type;
      obj.id = this.currentNodeCopy.id;
      obj.mainId = this.currentNodeCopy.mainId;
      this.saveLoading = true;
      this.$http.put(url,obj).then(res => {
        if (res.data.code === '0'){
          this.editFolderDrawer = false;
          this.editFolderDrawerC = false;
          this.$message({
            message:this.$t('knowledgeManagement.folderDetails.editKnowledgeSuccess'),
            type:"success"
          })
          this.getNodeDetail(obj)
          this.$emit('saveFolderSuccess',obj)
          if (obj.textWatermark !== this.currentNodeCopy.textWatermark ||
              obj.textWatermarkValue !== this.currentNodeCopy.textWatermarkValue ||
              obj.isDownload !== this.currentNodeCopy.isDownload ||
              obj.downloadType !== this.currentNodeCopy.downloadType){
            this.$emit('changeSafe')
          }
        }
        this.saveLoading = false;
      })
    },
    hasManagement(data){
      let bool = false;
      let roleId = sessionStorage.getItem('roleId')
      if (roleId == 1){
        return true
      }
      if (data.managePermission && JSON.parse(data.managePermission)){
        let grouIds = sessionStorage.getItem('_groupIds') ? JSON.parse(sessionStorage.getItem('_groupIds')) : [];
        let userId = sessionStorage.getItem('_uid') ? Number(sessionStorage.getItem('_uid')) : ""
        if (grouIds){
          grouIds = grouIds.map(res => {
            return Number(res)
          })
        }
        let managePermission = JSON.parse(data.managePermission).map(res => {
          return res.id
        });
        if (managePermission.includes(userId)){
          bool = true;
          return bool
        } else {
          for (let i=0;i<grouIds.length;i++){
            if (managePermission.includes(grouIds[i])){
              bool = true
              return bool;
            }
          }
        }
      }
      return bool
    }
  },
  mounted() {
    
    this.$nextTick(() => {
      this.currentNodeCopy = this.currentNode ? this.currentNode : {};
    })
    this.roleId = sessionStorage.getItem('roleId')
    // window.addEventListener("message", (e) => {
    //   console.log(e,'opneDataLoad---');
    //   if(e.data.emitName === "opneDataLoad"){
    //     this.$refs['iframe' + Number(e.data.datas.id)][0].width = e.data.datas.width;
    //     console.log(this.$refs['iframe' + Number(e.data.datas.id)],'opneDataLoad--元素');
    //     console.log('opneDataLoad');
    //   }
    // })
  },
  watch:{
    currentNode:{
      handler(val){
        let obj = JSON.parse(JSON.stringify(val))
        // let corpId = sessionStorage.getItem('_corpId')
        let list = obj.visitPermission ? JSON.parse(obj.visitPermission) : []
        // list.forEach(item => {
        //   this.$nextTick(() => {
        //     let iframe = document.querySelector('.iframe' + item.id);
        //     iframe.width = iframe.contentDocument.body.clientWidth;
        //     console.log(iframe,'iframe');
        //   })
        //   // item.width = document.querySelector('.departName') ? document.querySelector('.departName').clientWidth : 0;
        //   // item.iframeUrl = `${this.currentUrl}?corpId=${corpId}&openid=${item.name}&type=${item.type == 1 ? 'departmentName' : 'userName'}&style=3&datas=${item.id + ',' + item.type + ',' + item.name}`
        // })
        obj.visitPermission = JSON.stringify(list)
        this.currentNodeCopy = obj;
        console.log(1009, this.currentNodeCopy);
        
        this.getTagList();
      },
      immediate:true
    },
  }
};
</script>

<style scoped lang="less">
#folderDetails{
  height: 100%;
  .operationButton{
    display:flex;
    align-items:center;
    justify-content: space-between;
    padding: 0 15px 0 15px;
    border-bottom: 1px solid #E0E6F7;
    height: 50px;
    .buttonItem{
      flex:none;
      width: 72px;
      height: 28px;
      background: #FFFFFF;
      border: 1px solid #A1B9FF;
      border-radius: 17px;
      display:flex;
      align-items:center;
      justify-content: center;
      font-size:13px;
      color: #366aff;
      cursor: pointer;
      i{
        font-size:14px;
        margin-right: 4px;
      }
      .el-dropdown-more{
        color: #366aff;
      }
    }
    .set-authority{
      width: 96px;
    }
  }
  .folderContainer{
    height: calc(100% - 55px);
    overflow-y: auto;
    padding: 15px 15px 0 15px;
  }
  .detailInfo{
    //margin-top: 20px;
    color: #000000;
    .folderTitle{
      margin-bottom: 16px;
    }
    .folderInfo{
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 13px;
      .title{
        color: #A9B3C6;
        flex: none;
        margin-right: 10px;
      }
      .folderType{
        display: flex;
        align-items: center;
        img{
          margin-right: 4px;
        }
      }
      .newsStatus{
        flex: none;
        width: 74px;
        height: 19px;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px;
        margin-left: 10px;
      }
      .unPublishStatus{
        border: 1px solid #FFE0CF;
        color: #FF8C4C;
        background: #FFF1E9;
      }
      .publishStatus{
        color: #366aff;
        background: #ECF1FF;
        border: 1px solid #ECF1FF;
      }
      .archiveStatus{
        color: #9DA5BF;
        background: #F3F3F3;
        border: 1px solid #DDE0E9;
      }
    }
    .validTime{
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 13px;
      .title{
        color: #A9B3C6;
      }
    }
    .description{
      color: #A9B3C6;
    }
  }
  .folderRight{
    .title{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;
      i{
        color: #366aff;
        cursor: pointer;
      }
    }
    .head{
      color: #A9B3C6;
      width: 60px;
      flex: none;
    }
    .authority{
      font-size: 13px;
      .authorityList{
        display: flex;
        align-items: center;
        margin-top: 12px;
        flex-wrap: wrap;
        .authorityInfo{
          display: flex;
          align-items: center;
          margin-right: 8px;
          position: relative;
          margin-bottom: 6px;
          .avatar{
            flex: none;
            width: 31px;
            height: 31px;
            background: #366AFF;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #FFFFFF;
            font-size: 12px;
            &.max{
              width: auto;
              padding: 0 12px;
              border-radius: 16px;
              height: 24px;
            }
          }
          .departAvatar{
            flex: none;
            width: 31px;
            height: 31px;
            background: #918EFF;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1;
            i{
              color: #FFFFFF;
            }
          }
          .departName{
            flex: none;
            padding: 0 12px 0 20px;
            height: 24px;
            line-height: 24px;
            background: #E6E5FF;
            border-radius: 11px;
            //display: flex;
            //align-items: center;
            //justify-content: center;
            margin-left: -14px;
            z-index: 0;
            max-width: 140px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            iframe,.iframe{
              // width: 100%;
              height: 24px;
              line-height: 24px;
            }
            &.user-name{
              background: #366AFF;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-left: 0;
              width: 60px;
              padding: 0;
              height: 24px;
              border-radius: 14px;
              i{
                color: #fff;
              }
              iframe{
                height: 24px;
                line-height: 24px;
                background: #366AFF;
                color: #fff;
                // margin-left: 6px;
                width: 100%;
                text-align: center;
                body{
                  text-align: center;
                }
              }
            }
          }
        }
      }
      .safeHead{
        color: #000000;
        margin: 12px 0 12px 0;
        font-size:14px;
      }
      .watermark{
        display: flex;
        align-items: center;
        justify-content: space-between;
        //padding: 8px 0;
        color: #A9B3C6;
        font-size: 12px;
        .openWatermark{
          flex: none;
          color: #04BB82;
          padding: 0 8px;
          height: 24px;
          background: #D6F3EA;
          border-radius: 12px;
          display: flex;
          align-items: center;
          justify-content: space-around;
          i{
            font-size: 16px;
            margin-right: 2px;
            margin-top: 2px;
            cursor: default;
          }
        }
        .closeWatermark{
          flex: none;
          color: #FE5965;
          padding: 0 8px;
          height: 24px;
          background: #FFE8EA;
          border-radius: 12px;
          display: flex;
          align-items: center;
          justify-content: space-around;
          i{
            font-size: 16px;
            margin-right: 2px;
            margin-top: 2px;
            cursor: default;
          }
        }
      }
    }
    .management{
      margin-bottom: 10px;
    }
    .folderSource,.folderSeat{
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 13px;
    }
    .synchronization{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .synchronizationButton{
        width: 90px;
        height: 32px;
        background: #FFFFFF;
        border: 1px solid #E0E6F7;
        border-radius: 19px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
    .label-set{
      font-size: 18px;
    }
    .tag-list{
      //margin-top: 20px;
      .associated-tags{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .tag-item{
          flex: none;
          padding: 0 6px;
          height: 20px;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #04BB82;
          line-height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 8px;
          background-color: #D6F3EA;
          border-radius: 5px;
          cursor: pointer;
          margin-bottom: 8px;
          .guoran-AI{
            color: #04BB82;
            font-size: 12px;
            margin-right: 4px;
          }
        }
      }
    }
  }
  /deep/.el-divider--horizontal{
    margin: 18px 0;
  }
  /deep/.el-divider{
    background-color: #E0E6F7;
  }
}
.knowledge-drawer-header{
  background-color: #F6F8FD;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .header-left{
    display: flex;
    align-items: center;
    .header-title{
     padding: 12px 0;
      border-bottom: 2px solid #366aff;
      font-size: 16px;
      color: #000000;
    }
    .tag-label{
      flex: none;
      margin-left: 12px;
      height: 27px;
      background: #FFFFFF;
      border: 1px solid #A1B9FF;
      border-radius: 14px;
      padding: 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .header-right{
    flex: none;
    width: 20px;
  }
}
.association-knowledge-table{
  margin-top: 20px;
  height: calc(100% - 67px);
}
.folder-drawer-header{
  background-color: #F6F8FD;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
}
.folder-container{
  padding: 0 20px;
  height: calc(100% - 100px);
  overflow-y: scroll;
}
.folder-footer{
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 0 20px;
  width: 100%;
  height: 50px;
  .btn-item{
    flex: none;
    width: 86px;
    height: 32px;
    border-radius: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .close-btn{
    background-color: #ffffff;
    color: #000000;
    border: 2px solid #E0E6F7;
  }
  .confirm-btn{
    color: #ffffff;
    background: #366AFF;
    margin-left: 8px;
    border: 2px solid #366AFF;
  }
}
/deep/.el-drawer__body{
  height: 100%;
}
</style>