<template>
	<div v-if="botConfigure && false">
		<router-view :corpId="mainVal" @reload="reload" ref="routerView"></router-view>
	</div>
	<div v-else class="mainView">
		<div class="navLeft">
			<side-bar></side-bar>
		</div>
		<div class="mainRight" :class="isDetail ? 'right_detail' : ''">
			<div class="topHeader" v-show="!isDetail">
				<top-header @getMainVal="getMainVal" ref="topHeader" @changeCompany="changeCompany" @newsFun="newsFun"
					@addPlugin="addPlugin" @editPlugin="editPlugin" @editBot="editBot" @editInfo="editInfo"
            @summaryFun="summaryFun" @showEditFun="showEditFun" @changeReadStatus="changeReadStatus"
					@downLoadUrl="downLoadUrl" @addAutomation="addAutomation" @adjustPriority="adjustPriority" @goBack="goBack"></top-header>
			</div>
			<div class="container" :class="isDetail ? 'detail_container' : ''">
        <keep-alive
            :include="['botLearningIndex']"
        >
          <router-view :corpId="mainVal" @reload="reload" ref="routerView" @closeSummary="closeSummary"
					@automationDetail="automationDetail" @getApprovalLoading="getApprovalLoading" @sureApprovalStatus="sureApprovalStatus"></router-view>
        </keep-alive>
				<!-- 搜索 start 每个路由都可能出现 -->
				<testWindow v-if="isShowTestWindow" @showTestWindiow="showTestWindiow" class="ask-lw-bot" />
				<!-- 搜索 end 每个路由都可能出现 -->
			</div>
			<div class="test-btn-news" @click="showTestWindiow(true)" v-if="!isShowTestWindow">
				<span class="test-btn-icon-outers">
					<i class="iconfont guoran-tongyichicun-ceshi"></i>
				</span>
				<span class="test-btn-text-outers">{{$t("common.test")}}</span>
			</div>
		</div>
		<systemUpdateNotice></systemUpdateNotice>
	</div>
</template>
<script>
/* eslint-disable */
import SideBar from "@/components/sideBar";
import TopHeader from "@/components/top-header";
import agentConfig from "@/assets/js/agentConfig";
import testWindow from "../testWindow/testWindow.vue";
import { mapActions, mapGetters } from 'vuex';
import { log } from 'exceljs/dist/exceljs';
import systemUpdateNotice from '@/components/systemUpdateNotice.vue';
export default {
	name: "MainView",
	props: [],
	data () {
		return {
			mainVal: "",
			testWindowVisible: false,
			botConfigure: false,
			isDetail: false
		};
	},
	components: {
		TopHeader,
		SideBar,
		testWindow,
		systemUpdateNotice
	},
	computed: {
		...mapGetters('apphandle', {
			isShowTestWindow: 'getIsShowTestWindow'
		}),
	},
	created () {
		// 在组件创建时，监听路由变化
		this.checkRouteForBotConfigure();
	},
	watch: {
		// 监听路由的变化
		$route (to, from) {
			// 路由变化时检查当前路由是否包含 botConfigure
			this.checkRouteForBotConfigure();
			this.automationDetail(false)
		}
	},
	methods: {
		getApprovalLoading(boolean) {
			this.$refs.topHeader.getApprovalLoading(boolean)
		},
		sureApprovalStatus(boolean) {
			this.$refs.topHeader.sureApprovalStatus(boolean)
		},
		checkRouteForBotConfigure () {
			// 获取当前路由路径
			const currentPath = this.$route.path;
			// 判断当前路由路径是否包含 botConfigure
			this.botConfigure = currentPath.includes('botConfigure');
		},
		/*获取企业id*/
		getMainVal (val) {
			this.mainVal = val;
		},
		getWechatCorp () {
			let mainId = sessionStorage.getItem('_mainId');
			let uid = sessionStorage.getItem('_uid');
			let url = '/portal-api/account/third-wechat-corp-info?mainId=' + mainId + '&uid=' + uid
			this.$http.get(url).then(res => {
				if (res.data.code === '0') {
					if (res.data.data.length !== 0) {
						this.showData(res.data.data[0].corpId)
					}
				}
			})
		},
		showData (id) {
			let corpId = id
			let currentUrl = encodeURIComponent(window.location.origin + window.location.pathname);
			let url = ' /portal-api/account/askbot-wechat-agent-config?corpId=' + corpId + '&url=' + currentUrl
			this.$http.get(url).then(res => {
				if (res.data.code === '0') {
					agentConfig(corpId, res.data.data.data, (res) => {
						console.info('agent auth config success', res);
						/* eslint-disable */
						WWOpenData.bind(this.$el);
						/* eslint-enable*/
					})
				}
			})
		},
		reload () {
			this.$refs.topHeader.getUserInfo();
		},
		changeCompany () {
			if (this.$route.path === '/knowledgeManagement') {
				if (this.$refs.routerView.$refs.folderManagement) {
					this.$refs.routerView.$refs.folderManagement.getKnowledgeBaseList('', '', 'mounted');
					this.$refs.routerView.selectCompanyUseFlow();
				}
			}
		},
		...mapActions('apphandle', [
			'updateIsShowTestWindows'
		]),
		/**
         * 修改展示测试窗显示隐藏状态
         * @method showTestWindiow
         * @return
         */
        showTestWindiow(status) {
			if(this.isShowTestWindow && status) return false;
            this.testWindowVisible = status;
            this.updateIsShowTestWindows(status);
        },

    newsFun(type, from, callback){
		console.log('150 newsFun: ', type, from, callback);
      if (type === 'save' || type === 'publish' || type === 'PUBLISHED'){
        let newType = (type == "save" ? 'save' : "PUBLISHED")
        this.$refs.routerView.saveOrPublish(newType, from);
			} else if(type === 'PENDING_APPROVAL') {
				this.$refs.routerView.cancelApproval();
			} else if (type === 'UNARCHIVE' || type === 'ARCHIVE') {
				this.$refs.routerView.verifyIfAutomationIsTriggered(type);
			} else if (type === 'UNPUBLISHED') {
				this.$refs.routerView.verifyIfAutomationIsTriggered(type);
			} else if (type === 'delete') {
				console.log('160 delete: ', callback);
				this.$refs.routerView.deleteNews(callback)
			}
		},
		addPlugin () {
			this.$refs.routerView.addPlugin()
		},
		editPlugin () {
			this.$refs.routerView.editPlugin()
		},
		editBot () {
			this.$refs.routerView.updateBotOpen()
    },
    editInfo(){
      this.$refs.routerView.updateInfo()
		},
		summaryFun (bool) {
			this.$refs.routerView.editSummary(bool);
		},
		showEditFun (type){
			this.$refs.routerView.openWebOffice(type);
		},
    changeReadStatus(){
      this.$refs.routerView.changeReadStatus();
    },
		closeSummary () {
			this.$refs.topHeader.showSummary = false;
		},
		downLoadUrl () {
			this.$refs.routerView.downLoadUrl();
		},
		addAutomation () {
			this.$refs.routerView.addAutomation();
		},
		adjustPriority(automationSort) {
			this.$refs.routerView.adjustPriority(automationSort);
		},
		automationDetail (flag) {
			console.log(flag, 'flagflagflag');
			this.isDetail = flag
		},
    goBack(){
      this.$refs.routerView.checkSubsection();
    }
	},
	mounted () {
		this.getWechatCorp();
	}
};
</script>
<style lang="less" scoped>
.mainView {
	// width: 100vw;
	//min-width: 1200px;
	height: 100vh;
	overflow: hidden;
	display: flex;
	align-items: center;
	text-align: left;

	.mainRight {
		flex: 1;
		height: 100%;
		padding: 12px;
		box-sizing: border-box;
		overflow: hidden;
		background-color: #f2f6fc;
		position: relative;

		.container {
			height: calc(100% - 74px);
			display: flex;
		}
	}

	.right_detail {
		padding: 0 !important;

		.detail_container {
			height: 100% !important;
		}
	}

	.test-btn-news {
		display: inline-block;
		font-size: 12px;
		font-weight: 800;
		line-height: 14px;
		position: absolute;
		top: 47vh;
		right: -2px;
		width: 12px;
		padding: 14px 10px;
		color: white;
		border-radius: 10px 0 0 10px;
		z-index: 1111;
		cursor: pointer;
		background: linear-gradient(179deg, #5f9bfc, #46b4d6);

		.test-btn-icon-outers {
			display: inline-block;
			margin-bottom: 8px;

			i {
				font-size: 16px;
			}
		}

		.test-btn-text-outers {
			display: inline-block;
			overflow: hidden;
			color: #fff;
			word-break: break-all;
			text-align: center;
		}
	}

	.ask-lw-bot {
		flex: none;
		width: 387px;
		background: #F6F8FB;
		box-shadow: 0px 0px 10px 0px rgba(79, 82, 92, 0.17);
		border-radius: 5px;
		margin-left: 16px;
	}
}
</style>
