<!--知识管理页面-->
<template>
  <div id="knowledgeManagement" class="askLightKnowledgeManage">
    <div class="knowledgeSidebar">
      <folder-management
          @getFolderData="getFolderData"
          @initSearchType="initSearchType"
          @delSuccess="delSuccess"
          :corpId="corpId"
          :currentNode="currentNode"
          ref="folderManagement"
          sourceType="knowledgeManagement"
          @getKnowledge="getKnowledge"
          @getFolderDetail="getFolderDetail"
      ></folder-management>
       <div class="capacity-use-detail">
         <div class="text">{{handlerUnit(combinationInfo.useCapacity)}} / {{handlerUnit(combinationInfo.totalCapacity)}}</div>
        <div class="alc-item-value-pre">
          <div :style="{width: combinationInfo.percentage + '%'}" class="alc-item-value-pre-innner"></div>
        </div>
      </div>
    </div>
    <div class="knowledgeContainer">
      <div class="containerTop">
        <div class="topLeft">
          <!-- <span :class="['enterprise',breadcrumbCopy.length === 0 ? 'enterprise-disabled' : '']" @click="parentFolder">{{breadcrumb[0] && breadcrumb[0].name}}</span> -->
          <span v-for="(item,index) in breadcrumb"
              :key="index"
              :class="[index == breadcrumb.length -1 ? 'lastBreadcrumbItem' :'','breadcrumbItem']"
              @click="gobackFolder(item,index)">
              {{item.name}}
              <span class="symbol" v-if="index + 1 < breadcrumb.length"> > </span>
            </span>
          <span class="dataTotal">
            {{dataTotal}}
          </span>
        </div>
        <div class="topRight" :class="isAskLight() || !hasManagement() || breadcrumb.length === 0 ? 'onlySee' : ''">
            <el-dropdown  @command="handleCommand" v-if="!isAskLight() && hasManagement() && breadcrumb.length > 0" trigger="click">
              <div class="addBtn" >
                <span class="el-dropdown-link">
                  <i class="iconfont guoran-a-18-35"></i>
                </span>
              </div>
              <el-dropdown-menu slot="dropdown" class="dropdown-upload">
                <el-dropdown-item  disabled>{{ $t('knowledgeManagement.management.local') }}</el-dropdown-item>
                <el-dropdown-item command="uploadFile">
                  <div style="width:100%" @click="customUploadFile('fold')">
                    <i class="iconfont guoran-zhiding"></i>
                    {{ $t('knowledgeManagement.management.uploadFiles') }}
                  </div>
                </el-dropdown-item>
                <el-dropdown-item command="uploadFolder">
                  <div style="width:100%" @click="customUploadFile('folder')">
                      <i class="iconfont guoran-shangchuanwenjianjia-"></i>
                    {{ $t('knowledgeManagement.management.uploadFolder') }}
                    </div>
                </el-dropdown-item>
                <el-dropdown-item v-if="!main_isLishi" divided></el-dropdown-item>
                <el-dropdown-item v-if="!main_isLishi" disabled>{{ $t('knowledgeManagement.management.thirdParty') }}</el-dropdown-item>
                <template v-if="false">
                  <el-dropdown-item :command="1">
                    <img src="https://static.guoranbot.com/cdn-arsenal/dev/img/qiyechat.b7289a96.png" height="14px">
                    {{ $t('knowledgeManagement.management.weChatSynchronization') }}
                  </el-dropdown-item>
                  <el-dropdown-item :command="2">
                    <i class="iconfont guoran-tongyichicun-tongzhifasong"></i>
                    {{ $t('knowledgeManagement.management.tencentSynchronization') }}
                  </el-dropdown-item>
                  <el-dropdown-item :command="3">
                    <img src="https://static.guoranbot.com/cdn-arsenal/dev/img/dingding.874450b3.jpeg" height="14px">
                    {{ $t('knowledgeManagement.management.dingSynchronization') }}
                  </el-dropdown-item>
                </template>
                <el-dropdown-item v-if="!main_isLishi" :command="8">
                  <img src="../../assets/images/yuque.png" height="16px" style="margin-right: 10px">
                  {{ $t('knowledgeManagement.management.yuQueSynchronization') }}
                </el-dropdown-item>
                <el-dropdown-item v-if="!main_isLishi" :command="5">
                  <img src="../../assets/images/feishu.png" height="16px" style="margin-right: 10px">
                  {{ $t('knowledgeManagement.management.feishuSynchronization') }}
                </el-dropdown-item>
                <el-dropdown-item divided></el-dropdown-item>
                <el-dropdown-item disabled>{{ $t('knowledgeManagement.management.addText') }}</el-dropdown-item>
                <el-dropdown-item command="newFolder">
                  <i class="iconfont guoran-a-18-35"></i>
                  {{ $t('knowledgeManagement.management.addFolder') }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
         
          <div class="hideDetails" :class="isShowKnowledgeDetail ? 'expand' : 'hide'" @click="isShowKnowledgeDetail = !isShowKnowledgeDetail">
            <i class="iconfont guoran-dianjichakantuli"></i>
          </div>
        </div>
      </div>
      <div class="container">
        <div :class="[isShowKnowledgeDetail?'setTableWidth':'notSetTableWidth','containerTable']">
          <knowledge-table
              :currentNode.sync="currentNode"
              :breadcrumb="breadcrumb"
              ref="knowledgeTable"
              :channel="channel"
              @rowClick="rowClick"
              @lookDetail="lookDetail"
              @editFolder="knowledgeEdit"
              @moveTo="knowledgeMove"
              @delFolder="knowledgeDel"
              @folderPermission="folderPermission"
              @batchMoveFolder="moveFolder"
              @batchDelete="batchDelete"
              @syncThird="handleCommand"
              @openDirectoryDialog="openDirectoryDialog"
              @deleteSuccess="deleteSuccess"
              @getDataTotal="getDataTotal"
              @synchronization="synchronization"
          ></knowledge-table>
        </div>
        <div class="knowledgeDetail" v-if="isShowKnowledgeDetail">
          <folder-details
              :currentNode="currentNode"
              :folderTree="folderTree"
              @editFolder="editFolder"
              @moveTo="editFolder"
              @handleCommand="folderDetailHandle"
              @deleteDetail="deleteDetail"
              @synchronization="synchronization"
              @getKnowledgeData="getKnowledgeData"
              @saveFolderSuccess="saveFolderSuccess"
              ref="folderDetails"
          ></folder-details>
        </div>
      </div>
    </div>
    <el-drawer
        :withHeader="false"
        :visible.sync="synchronizationDrawer"
        @close="closeDrawer"
        size="70%"
    >
      <SynchronizeFiles
          @closeDrawer="closeDrawer"
          v-if="filesCom"
          :channel="channel"
          :synchronizationDrawer="synchronizationDrawer"
      ></SynchronizeFiles>
      <!-- <div class="closeSynchronize">
        <span class="closeButton" @click="closeDrawer">
          关闭
        </span>
      </div> -->
    </el-drawer>
    <Popup @closeEvent="moveToNew = false" v-if="moveToNew">
      <div slot="popup-name">{{ $t('knowledgeManagement.folderManagement.script.editFolderTitleMove') }}</div>
      <div slot="popup-tip">{{ $t('knowledgeManagement.folderManagement.script.editFolderTipsMover') }}</div>
      <div slot="popup-con-c">
        <el-form label-position="top" label-width="80px">
          <el-form-item label="父目录">
            <span slot="label">
              <span>{{ $t('knowledgeManagement.folderManagement.form.parent') }}</span>
              <span style="color: red">*</span>
            </span>
            <el-dropdown
                trigger="click"
                id="el-dropdown-dir"
                ref="messageDrop"
                @visible-change="elDropdownvisibleChange($event)"
                :hide-on-click="true">
              <span
                  :class="['el-dropdown-link',
                  'el-dropdown-link-dir',
                  parentDirectorySelect
                  ? 'active-el-dropdown-link': '',]">
                <span
                    v-if="parentDirectoryName"
                    class="el-dropdown-dir-content"
                >
                  {{ parentDirectoryName }}
                </span>
                <span
                    v-else
                    class="el-dropdown-placeholder"
                >请选择</span>
                <span class="el-icon">
                  <em
                      v-show="!parentDirectorySelect"
                      class="el-icon-arrow-down"
                  ></em>
                  <em
                      v-show="parentDirectorySelect"
                      class="el-icon-arrow-up"
                  ></em>
                </span>
              </span>
              <el-dropdown-menu
                  id="dir-dropdown"
                  slot="dropdown"
              >
                <el-dropdown-item
                    class="dir-dropdown-item"
                >
                  <div class="dir-tree">
                    <el-tree
                        :data="folderPullTreeCopy"
                        node-key="id"
                        :expand-on-click-node="false"
                        check-strictly
                        :props="typeTreeDefaultProps"
                        @node-click="nodeClick($event)"
                        lazy
                        :load="loadDireNode"
                    >
                      <span class="custom-tree-node" slot-scope="{ data }">
                        <div class="custom-tree-node-left">
                          <span class="custom-tree-node-label">
                            <span class="folder-name">{{ data.name }}</span>
                          </span>
                        </div>
                        <span v-if="data.checked"><i class="el-icon-check"></i></span>
                      </span>
                    </el-tree>
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-form-item>
        </el-form>
      </div>
      <div slot="dialog-footer">
        <el-button  plain size="small" @click="moveToNew = false" class="cancel-btn">{{ $t('knowledgeManagement.folderManagement.cancel') }}</el-button>
        <el-button  type="primary" size="small" @click="moveToNewFolder" class="confirm-btn">{{ $t('knowledgeManagement.folderManagement.confirm') }}</el-button>
      </div>
    </Popup>
    <delete-dialog @closeEvent="deleteFolderNoJudgeDialog = false" v-if="deleteFolderNoJudgeDialog">
      <div slot="popup-container" class="popup-container">
        <div class="delete-title">{{ $t('knowledgeManagement.management.batchDeletion') }}</div>
        <div class="prompt-text">{{ $t('knowledgeManagement.management.batchDeletionTips') }}</div>
      </div>
      <div slot="dialog-footer">
        <el-button @click="deleteFolderNoJudgeDialog = false" size="small" plain class="cancel-btn">{{ $t('knowledgeManagement.folderManagement.cancel') }}</el-button>
        <el-button @click="deleteFolder" style="background-color: #FE5965 ;color: white;border:none;" size="small"  class="confirm-btn">{{ $t('knowledgeManagement.folderManagement.deleteText') }}
        </el-button>
      </div>
    </delete-dialog>
    <Popup @closeEvent="deleteFolderDialog = false" v-if="deleteFolderDialog">
      <div slot="popup-name" class="popup-name">{{ $t('knowledgeManagement.management.batchDeletionVerification') }}</div>
      <div slot="popup-tip">{{ $t('knowledgeManagement.management.batchDeletionVerificationTips') }}</div>
      <div slot="popup-con" class="ruleForms" v-if="delFolderNameList.length > 0">
        <div style="color:#606266;">
          <span v-for="(item,index) in delFolderNameList" :key="index">{{item.name}} {{index+1 < delFolderNameList.length ? '、' : ''}}</span>
          等{{delFolderNameList.length}}{{ $t('knowledgeManagement.management.deletedFolderTips') }}
        </div>
        <el-form  ref="ruleForms" label-position="top" label-width="80px" class="demo-ruleForm">
          <el-form-item :label="$t('knowledgeManagement.management.handleFolder')" >
            <el-radio v-model="radio" label="remove">{{ $t('knowledgeManagement.management.moveKnowledgeTips') }}</el-radio>
            <el-radio v-model="radio" label="del">{{ $t('knowledgeManagement.management.directlyDelete') }}</el-radio>
          </el-form-item>
          <el-form-item label="" v-if="radio === 'remove'">
            <el-tree
                key="chooseDept"
                ref="chooseDept"
                :data="folderPullTreeCopy"
                :props="typeTreeDefaultProps"
                @node-click="onNodeClick"
                :expand-on-click-node="false"
                lazy
                :load="loadDireNode"
            >
              <span
                  class="custom-tree-node-choose-dept"
                  slot-scope="{data}">
                <span class="custom-tree-node-left">
                  <span class="data-label" >{{data.name}}</span>
                </span>
                <span v-show="data.checked">
                  <i class="el-icon-check"></i>
                </span>
              </span>
            </el-tree>
          </el-form-item>
          <div class="del-tips" v-else>
            {{ $t('knowledgeManagement.management.prudentDeletion') }}
          </div>
        </el-form>
      </div>
      <div slot="dialog-footer">
        <el-button @click="deleteFolderDialog = false" plain round class="cancel-btn">{{ $t('knowledgeManagement.folderManagement.cancel') }}</el-button>
        <el-button type="primary" @click="deleteFolder" round  class="confirm-btn" style="background-color: #FE5965 ;color: white;border:none;" >{{ $t('knowledgeManagement.folderManagement.deleteText') }}</el-button>
      </div>
    </Popup>
    <div class="synchronization-loading" v-if="isShowSynchronizationloading">{{ $t('knowledgeManagement.management.syncing') }}</div>
    <div v-if="uploadInfoVisible" class="ask-upload-notify">
      <div class="ask-upload-notify-header">
          <div class="aunh-left">
              <span v-show="uploadInfoStatus === 'pending'" class="aunh-left-loading">
                  <img height="18px" src="https://static.guoranbot.com/cdn-office-website/askbot_doc/loading.gif" alt="" srcset="">
                  <span class="aunh-left-text">{{ $t('knowledgeManagement.management.uploading') }}</span>
              </span>
              <span v-show="uploadInfoStatus === 'success'" class="aunh-left-suc">
                  <i class="iconfont guoran-a-tongyichicun-duihaoyuan"></i>
                  <span class="aunh-left-text">{{ $t('knowledgeManagement.management.uploadSuccess') }}</span>
              </span>
               <span v-show="uploadInfoStatus === 'error'" class="aunh-left-fail">
                  <i class="iconfont guoran-tongyichicun-cuowu"></i>
                  <span class="aunh-left-text">{{ $t('knowledgeManagement.management.uploadFail') }}</span>
              </span>
              <span class="aunh-left-count">{{ uploadedSuccessCount }}/{{ activeFileList.length }}</span>
          </div>
          <div class="aunh-right">
              <span @click="hiddenActiveFileList = !hiddenActiveFileList" v-show="!hiddenActiveFileList"
                  class="aunh-right-up">
                  <i class="arsenal_icon arsenalangle-up-solid"></i>
              </span>
              <span @click="hiddenActiveFileList = !hiddenActiveFileList" v-show="hiddenActiveFileList"
                  class="aunh-right-down">
                  <i class="arsenal_icon arsenalangle-down-solid"></i>
              </span>
              <span @click="closeUploadInfoVisible" class="aunh-right-close">
                  <i class="iconfont guoran-cuohao2"></i>
              </span>
          </div>
      </div>
      <div v-show="hiddenActiveFileList" class="ask-upload-notify-body">
          <div class="aunb-info" v-show="uploadInfoStatus === 'error'">
          <div class="left">
            <span class="aunb-info-suc&err">{{ $t('knowledgeManagement.management.success') }} : {{uploadedSuccessCount}} {{ $t('knowledgeManagement.management.fail') }} : {{uploadedFailCount}}</span>
          </div>
          <!-- <div class="right" @click="reUpload">重新上传所有失败任务</div> -->
          </div>
          <div v-for="item in activeFileList" :key="item.uid" class="aunb-cell">
              <div :style="{ width: (item.process * 350) + 'px' }" class="aunb-cell-process"></div>
              <div class="aunb-cell-info">
                  <div class="aunb-cell-info-left">
                      <span v-show="item.process === 1" class="acil-suc">
                          <i class="iconfont guoran-a-tongyichicun-duihaoyuan"></i>
                      </span>
                      <!-- <span v-show="item.process === 0" class="acil-err">
                          <i class="iconfont guoran-tongyichicun-cuowu"></i>
                      </span> -->
                      <span class="acil-icon">
                          <img width="16px" style="margin-top: 2px;" :src="item.icon" alt="" srcset="">
                      </span>
                      <div class="acil-name-box">
                          <div class="acil-name">{{ item.name }}</div>
                          <span v-show="item.isBeyond" class="beyond">
                              <span> {{item.beyondType === 'total' ? '超出可用容量' : '文件大小超出25M'}} </span>
                          </span>
                      </div>
                  </div>
                  <div class="aunb-cell-info-right" v-if="item.process !== 0">
                      <span class="acir-current">{{ formatSize(item.size * item.process) }}</span>
                      <span class="acir-total">/{{ formatSize(item.size) }}</span>
                      <!-- <span v-show="item.process !== 1" class="acir-close">
                          <i class="iconfont guoran-tongyichicun-cuowu"></i>
                      </span> -->
                      <span v-show="false" class="acir-reload">
                          <i class="iconfont guoran-tongyichicun-huifuchuli"></i>
                      </span>
                  </div>
              </div>
          </div>
      </div>
    </div>
    <popup @closeEvent='sameFileNameVisible = false' v-if="sameFileNameVisible" class="same-name-file-popup">
      <div slot="popup-name">{{ $t('knowledgeManagement.management.sameFolder') }}</div>
      <div slot="popup-tip">{{ $t('knowledgeManagement.management.sameFolderTips') }}</div>
      <div slot="popup-con-c" class="popup-con-c">
        <div class="same-name-file-popup-content">
          <div v-for="(item,index) in sameFileNameList" :key="index" class="same-name-file-popup-item">
              <img :src="item.icon" >
              <span class="one-column-ellipsis">{{item.name }}</span>
          </div>
        </div>
      </div>
      <div slot="dialog-footer">
        <!--  saveType 0：新建，1：替换，2：保存为副本 -->
        <el-button  type="primary" size="small" @click="replaceFile"  class="confirm-btn">{{ $t('knowledgeManagement.management.replace') }}</el-button>
        <el-button  size="small" @click="saveCopyFile"  class="cancel-btn">{{ $t('knowledgeManagement.management.copy') }}</el-button>
        <el-button  plain size="small" @click="sameFileNameVisible = false" class="cancel-btn">{{ $t('knowledgeManagement.folderManagement.cancel') }}</el-button>
      </div>
    </popup>
  </div>
</template>

<script>
import FolderManagement from "@/views/knowledgeManagement/folderManagement";
import KnowledgeTable from "@/views/knowledgeManagement/knowledgeTable";
import FolderDetails from "@/views/knowledgeManagement/folderDetails";
import SynchronizeFiles from "@/views/knowledgeManagement/synchronizeFiles";
import Popup from "@/components/popup";
import { ossConfig,upload, multipartUploadChangeFilName } from "@/assets/js/AliyunIssUtil";
import { requestUrl } from "@/http/requestUrl";
import { crumbs, GetQueryString } from "@/assets/js/filterTree";
import { getCurrentNode, parentNode } from "@/assets/js/getParentNode";
import debounce from "@/assets/js/debounce";
import formatDoc from "../../utils/formatDocList";
import DeleteDialog from "@/components/deleteDialog";
import { v4 as uuidv4 } from "uuid";
export default {
  name: "knowledgeManagement",
  components: { Popup, SynchronizeFiles, FolderDetails, KnowledgeTable, FolderManagement,DeleteDialog },
  data(){
    return{
      currentNode:{},
      parentNode:{},
      isShowKnowledgeDetail:true,
      synchronizationDrawer:false,
      filesCom:false, //解决抽屉打开会抖动一下
      formFolder:{
        name:"",
        description:"",
        parentId:""
      },
      editFolderTitle:this.$t('knowledgeManagement.folderManagement.addFolder'),
      parentDirectorySelect:"",
      typeTreeDefaultProps: {
        label: "name",
        isLeaf:'leaf'
      },
      directoryTree:[],
      parentDirectoryName:"",
      uploadFileList:[],
      fileTotal:0,
      successSize:0,
      breadcrumb:[],
      channel:0,
      moveToNew:false,
      moveToParentId:"",
      folderPullTreeCopy:[],
      folderPullTree:[],
      selectFolderList:[],
      deleteFolderDialog:false,
      radio:"remove",
      checkedDeptPopup:{},
      dataTotal:0,
      appEvt:null,
      synchronizationData:{},
      isShowSynchronizationloading:false,
      combinationInfo:{},
      folderTree:[],
      breadcrumbCopy:[],
      uploadInfoVisible:false,
      // uploadInfoStatus:1,// 0 部分任务上传失败 1 上传中 2 上传完成
      uploadInfoStatusText:"",
      fileList: [],
      activeFileList: [], // 当前上传文件列表信息 用于右上提示展示
      // uploadedSuccessCount: 0, // 上传完成文件数量
      // uploadedFailCount:0, // 上传失败的数量
      // uploadTotalCount: 1,  // 总上传数量
      hiddenActiveFileList: true,
      // needUploadList:[], // 真正需要上传的fileList
      sameFileNameVisible:false,
      sameFileNameList:[],
      sameFileList:[],
      breadcrumbOld:[],
      deleteFolderNoJudgeDialog:false,
      delFolderNameList:[],
      delData:{}, // 要删除的数据
    }
  },
  methods:{
    delSuccess(data,delId){
      this.delData = data;
      this.$refs.knowledgeTable.getFolderAndFile(data.id,'del');
      if (this.breadcrumb.length > 0){
        let index = this.breadcrumb.findIndex(item => item.id == delId);
        if (index > -1){
          this.breadcrumb.splice(index,this.breadcrumb.length);
        }
      }
    },
     // 无感删除路由参数
    replaceUrl(url,aParam) {
      aParam.forEach(item => {
      let fromindex = url.indexOf(`${item}=`) //必须加=号，避免参数值中包含item字符串
      if (fromindex !== -1) {
          // 通过url特殊符号，计算出=号后面的的字符数，用于生成replace正则
          let startIndex = url.indexOf('=', fromindex)
          let endIndex = url.indexOf('&', fromindex)
          let hashIndex = url.indexOf('#', fromindex)
          let reg;
          if (endIndex !== -1) { // 后面还有search参数的情况
              const num = endIndex - startIndex
              reg = new RegExp(`${item}=.{${num}}`)
              url = url.replace(reg, '')
          } else if (hashIndex !== -1) { // 有hash参数的情况
              const num = hashIndex - startIndex - 1
              reg = new RegExp(`&?${item}=.{${num}}`)
              url = url.replace(reg, '')
          } else { // search参数在最后或只有一个参数的情况
              reg = new RegExp(`&?${item}=.+`)
              url = url.replace(reg, '')
          }
      }
    });
    const noSearchParam = url.indexOf('=')
    if( noSearchParam === -1 ){
        url = url.replace(/\?/, '') // 如果已经没有参数，删除？号
    }
    return url
    },
    /**
     * 重新请求数据
     * */
    getKnowledge(type,node){
      this.folderTree = this.$refs.folderManagement.folderTree;
      // let parent = this.folderTree[0] ? this.folderTree [0] : {}
      if (type == 'del'){
        this.selectCompanyUseFlow()
        let findObj = null
        findObj = this.breadcrumb.find(item => item.id == node.id)
        if (!findObj && this.breadcrumb.length > 0){
          this.currentNode = this.breadcrumb[this.breadcrumb.length - 1]
        } else {
          if (this.breadcrumb.length > 0){
            this.breadcrumb.splice(this.breadcrumb.length -1,1)
            this.currentNode = this.breadcrumb[this.breadcrumb.length - 1]
          }
        }
        let treeBox = this.$refs.folderManagement.$refs.treeBox
        if (treeBox && this.breadcrumb.length > 0){
          // treeBox.setCurrentKey(this.breadcrumb[this.breadcrumb.length - 1].id);
        } else if (this.breadcrumb.length == 0){
          // treeBox.setCurrentKey(parent.id);
        }
      }
      let breadcrumb = [...this.breadcrumbCopy,...this.breadcrumb]
      // parent = parentNode(this.breadcrumb,this.folderTree[0])
      // if (Object.keys(this.currentNode).length === 0 || types === 'mounted'){
      //   sessionStorage.removeItem('breadcrumb')
        // if(breadcrumb && breadcrumb.length > 1){
          this.currentNode = breadcrumb[breadcrumb.length - 1];
      //     // parent = breadcrumb[breadcrumb.length - 1];
      //     this.currentNode = parent;
        // } else {
        //   this.currentNode = this.folderTree[0] ? this.folderTree[0] : {}
        // }
      // }
      if(!this.currentNode) {
        this.currentNode = this.folderTree[0]
        // console.log(this.$refs.folderManagement, 'this.$refs.folderManagement');
        // this.$nextTick(() => {
        //   let node = this.$refs.folderManagement.$refs.treeBox.getNode(this.currentNode.id)
        //   setTimeout(() => {
        //     this.$refs.folderManagement.nodeClick(this.currentNode, node)
        //   })
        // })

      }
      this.$refs.knowledgeTable.getFolderAndFile(this.currentNode.id);
      if(typeof(node) === 'string' && node === 'closeDrawer'){
        this.$refs.knowledgeTable.channel = null;
      }
      // this.$refs.knowledgeTable.getFolderAndFile(parent.id,type);
      this.$refs.folderDetails && this.$refs.folderDetails.getNodeDetail(this.currentNode)
    },
    initSearchType(){
      this.$refs.knowledgeTable.activeHeaderType = {
        text: this.$t('typeList.all'),
        value: 'all'
      }
    },
    /**
     * 当前选择的节点
     * */
    getFolderData(data,node,type){
      this.currentNode = data;
      this.parentNode = node.parent.data || data;
      this.formFolder.name = data.name;
      this.formFolder.description = data.description;
      this.formFolder.parentId = data.parentId;
      if (this.$refs.folderDetails){
        this.$refs.folderDetails.permissionList = [];
      }
      // if (data.visitPermission && typeof data.visitPermission == 'string'){
      //   let list = JSON.parse(data.visitPermission);
      //   this.$refs.folderDetails.getPermissionName(list);
      // }
      this.$refs.knowledgeTable.currentPage = 1;
      this.$refs.knowledgeTable.getFolderAndFile(data.id,type);
      this.breadcrumb = [];
      let arr = crumbs(node);
      // arr = arr.filter(item => item.parentId !== -1);
      this.breadcrumb = arr;
    },
    /**
     * 右侧详情块点击的编辑/移动
     * */
    editFolder(command,node){
      let parentNode = this.folderTree[0] ? this.folderTree[0] : {}
      if (this.currentNode.type == 0 && this.breadcrumb.length > 1){
        parentNode = this.breadcrumb[this.breadcrumb.length - 2]
      } else if (this.currentNode.type == 1 && this.breadcrumb.length > 0){
        parentNode = this.breadcrumb[this.breadcrumb.length - 1]
      }
      this.$refs.folderManagement.nodeParentData = parentNode;
      this.$refs.folderManagement.nodeData = node;
      if(command == 'shift') {
        this.$refs.folderManagement.handleCommand(command, null, null, 'detail-shift');
      } else {
        this.$refs.folderManagement.handleCommand(command, null, null, 'detail-edit');
      }
    },
    saveFolderSuccess(obj){
      this.$refs.folderManagement.confirmEditFolderAfterHandler(obj);
    },
    handleCommand(command){
      let folder = this.$refs.folderManagement.folderTree;
      if (command === 'newFolder'){
        let parentNode = folder[0]
        if (this.breadcrumb.length > 0){
          parentNode = this.breadcrumb[this.breadcrumb.length - 1]
        }
        this.$refs.folderManagement.nodeData = parentNode;
        this.$refs.folderManagement.handleCommand('addChildDept');
        // command === 8 || command === 5 || 
        // else if (command === 'tengXun' || command === 'feiShu' || command === 'weChat' || command === 'dingDing' || command === 'YuQue'){
      } else if (command === 1 || command === 2 || command === 3 || command === 4 || command === 5 || command === 6 || command === 7 || command === 8){
        let url = requestUrl.channel.checkAccess;
        url += '?corpId=' + this.corpId + '&channel=' + command;
        this.$http.get(url).then(res => {
          if (res.data.data){
            this.synchronizationDrawer = true;
            this.channel = command
            this.$nextTick(() => {
              this.filesCom = true;
            })
          } else {
            this.$message({
              message:command === 8 ? this.$t('knowledgeManagement.management.accessYuque') : this.$t('knowledgeManagement.management.accessFeishu'),
              duration:2000,
              type:'warning'
            })
            this.$router.push({
              path:'/channel_management',
              query:{
                type:'channelAccess'
              }
            })
          }
        })

      }
    },
    /**
     * 展示详情更多按钮中删除操作
     * */
    folderDetailHandle(command){
      if (command === 'del'){
        this.$refs.folderManagement.handleCommand('del',{},{},'detail-delete')
      }
    },
    /**
     * 上传文件
     * */
    handleAddFolder(){
      this.$refs.uploadFolder.$children[0].$refs.input.webkitdirectory = true;
    },
    beforeUpload(file){
      console.log('file',file)
     

    },
    beforeUploadFolder(file){
      console.log(file,'before')
      // if(this.combinationInfo.surplusCapacity - file.size < 0){
      //   this.messageFn('warning','容量已超出限制')
      //   return false;
      // }
    },
    onPreview(file){
      console.debug('file',file)
    },
    onPreviewFolder(file){
      console.debug('file',file)
    },
    aliyunOssRequest(data){
      let file = data.file;
      const fileName = file.name;
      const fileType = fileName.substring(fileName.lastIndexOf('.'));
      let bool = false
      if (fileType === '.mp4' || fileType === '.MP4'){
        let size = file.size / 1024 / 1024 <= 100
        if (!size) {
          this.$message({
            message: this.$t('knowledgeManagement.management.uploadVideoTips'),
            type: 'warning',
            duration: 2000
          })
          bool = true
        }
      }
      else if (fileType === '.jpg' || fileType === '.png' || fileType === '.JPG' || fileType === '.PNG'){
        let size = file.size / 1024 / 1024 <= 10
        if (!size) {
          this.$message({
            message: this.$t('knowledgeManagement.management.uploadImageTips'),
            type: 'warning',
            duration: 2000
          })
          bool = true
        }
      }
      if(this.combinationInfo.surplusCapacity - file.size < 0){
        this.messageFn('warning',this.$t('knowledgeManagement.management.capacityExceeded'))
        bool = true
      }
      if (bool){
        let uid = file.uid;
        let uploadFile = this.$refs.uploadFile;
        if (uploadFile){
          let idx = uploadFile.uploadFiles.findIndex(item => item.uid === uid);
          uploadFile.uploadFiles.splice(idx,1);
          return
        }
      }
      let res = upload(ossConfig, file);
      console.debug('oss upload res', data, res);
      return res;
    },
    /**
     * 文件夹的上传
     * */
    aliyunOssFolderRequest(data){
      console.log(data,'上传中');
      let file = data.file;
      if (file.name && file.name.substr(0,2) === '~$'){
        return;
      }
      const fileName = file.name;
      const fileType = fileName.substring(fileName.lastIndexOf('.'));
      let bool = false
      if (fileType === '.mp4' || fileType === '.MP4'){
        let size = file.size / 1024 / 1024 <= 100
        if (!size) {
          this.$message({
            message: '上传的视频不能超过100M',
            type: 'warning',
            duration: 2000
          })
          bool = true
        }
      }
      else if (fileType === '.jpg' || fileType === '.png' || fileType === '.JPG' || fileType === '.PNG'){
        let size = file.size / 1024 / 1024 <= 10
        if (!size) {
          this.$message({
            message: '上传的图片不能超过10M',
            type: 'warning',
            duration: 2000
          })
          bool = true
        }
      }
      if(this.combinationInfo.surplusCapacity - file.size < 0){
        this.messageFn('warning','容量已超出限制')
        bool = true
      }
      if (bool){
        let uid = file.uid;
        let uploadFile = this.$refs.uploadFile;
        if (uploadFile){
          let idx = uploadFile.uploadFiles.findIndex(item => item.uid === uid);
          uploadFile.uploadFiles.splice(idx,1);
          return
        }
      }
      let res = upload(ossConfig, file);
      console.debug('oss upload res', data, res);
      return res;
    },
    async uploadSuccess(file){
      console.debug(file,'uploadSuccess');
      let url = requestUrl.knowledgeBase.saveLocalUploadFile + '?saveType=' + file.file.saveType;
      let type = file.name.substring(file.name.lastIndexOf("."));
      let fileName = file.name.substring(0,file.name.lastIndexOf("."))
      let corpId = sessionStorage.getItem('_corpId')
      let parentData = getCurrentNode(this.breadcrumbOld,this.folderTree[0]);
      let managePermission = parentData.managePermission ? JSON.parse(parentData.managePermission) : [];
      if (parentData.parentId == -1 && managePermission.length == 0){
        managePermission = [
          {
            id:sessionStorage.getItem('_uid'),
            name:sessionStorage.getItem('realname'),
            type:0
          }
        ]
      }
      let obj = {
        "corpId":corpId,
        "parentId":parentData.id,
        "name":fileName,
        "volume":file.oldSize,  //文件大小
        "sourceLocation":file.file.webkitRelativePath, // 用户本地路径
        "fileSuffix":type,
        "ossPath":file.response.res.requestUrls[0],
        visitPermissionList:parentData.visitPermission ? JSON.parse(parentData.visitPermission):null,
        managePermissionList:managePermission,
        textWatermark:parentData.textWatermark,
        textWatermarkValue:parentData.textWatermarkValue,
        isDownload:parentData.isDownload,
        downloadType:parentData.downloadType

      }
      this.$http.post(url,obj).then(res => {
        if (res.data.code == '0'){
          // this.selectCompanyUseFlow();
          debounce(() => {
            let parentDataNew = getCurrentNode(this.breadcrumb,this.folderTree[0]);
            this.$refs.knowledgeTable.getFolderAndFile(parentDataNew.id,'isReload');
          },2000)
        }
      })
    },
    async uploadSuccessFolder(file){
      console.debug(file,'uploadSuccessFolder');
      this.successSize +=1
      let url = requestUrl.knowledgeBase.saveLocalUploadFile + '?saveType=' + file.file.saveType;
      let type = file.name.substring(file.name.lastIndexOf("."));
      let fileName = file.name.substring(0,file.name.lastIndexOf("."))
      let corpId = sessionStorage.getItem('_corpId');
      let parentData = getCurrentNode(this.breadcrumbOld,this.folderTree[0]);
      let managePermission = parentData.managePermission ? JSON.parse(parentData.managePermission) : [];
      if (parentData.parentId == -1 && managePermission.length == 0){
        managePermission = [
          {
            id:sessionStorage.getItem('_uid'),
            name:sessionStorage.getItem('realname'),
            type:0
          }
        ]
      }
      let obj = {
        "corpId":corpId,
        "parentId":parentData.id,
        "name":fileName,
        "volume":file.oldSize,  //文件大小
        "sourceLocation":file.file.webkitRelativePath, // 用户本地路径
        "fileSuffix":type,
        "ossPath":file.response.res.requestUrls[0],
        visitPermissionList:parentData.visitPermission ? JSON.parse(parentData.visitPermission):null,
        managePermissionList:managePermission,
        textWatermark:parentData.textWatermark,
        textWatermarkValue:parentData.textWatermarkValue,
        isDownload:parentData.isDownload,
        downloadType:parentData.downloadType
      }
      this.$http.post(url,obj).then(res => {
        if (res.data.code == '0'){
          // this.selectCompanyUseFlow();
          debounce(() => {
            let parentDataNew = getCurrentNode(this.breadcrumb,this.folderTree[0]);
            this.$refs.knowledgeTable.getFolderAndFile(parentDataNew.id);
            this.$refs.folderManagement.getKnowledgeBaseList(parentDataNew.id,parentData,'clear');
          },2000)
        }
      })
      // this.$refs.uploadFile.clearFiles();
    },
     /**
      *  自定义上传
      * @method handleSelect
      * @param {String} type 上传的类型   folder:上传文件夹 ;  '' : 上传文件
     * */
    async customUploadFile(type){
      let inputDom = document.createElement('input')
			inputDom.setAttribute('id', '_ef_file');
			inputDom.setAttribute('type', 'file');
      inputDom.setAttribute('multiple', 'true');
			inputDom.setAttribute("style", 'visibility:hidden');
      if(type === 'folder'){
        inputDom.setAttribute('webkitdirectory', 'true');
        inputDom.setAttribute('directory', 'true');
      }
			document.body.appendChild(inputDom);
			inputDom.click();
			inputDom.onchange = () => {
        let files = Array.from(inputDom.files);
        this.breadcrumbOld = JSON.parse(JSON.stringify(this.breadcrumb))
        this.handlerSameFileName(files,type);
        document.body.removeChild(inputDom)
      }
    },
    // 处理相同文件名 并 执行上传
    handlerSameFileName(fileList,type){
        this.sameFileNameList = [];
        this.sameFileList = []
        let sameFileNameList = [],canUploadFileNameList = [],canUploadList = [];
        let list = [];
        fileList.forEach(item => {
          list.push(item.name)
        })
        let corpId = sessionStorage.getItem('_corpId');
        let parentData = getCurrentNode(this.breadcrumb,this.folderTree[0])
        this.$http.post(requestUrl.docVersion.doubleNameCheck + '?parentId=' + parentData.id + '&corpId=' + corpId,list).then(res => {
          if (res.data.code == '0'){
            sameFileNameList = res.data.data;
            sameFileNameList.forEach(item => {
              this.sameFileNameList.push({
                name:item,
                icon:formatDoc.formatFileTypeSrc({name:item})
              })
            })
            if(sameFileNameList.length === 0){
              canUploadFileNameList = list;
            } else {
              list.forEach(item => {
                if(sameFileNameList.indexOf(item) === -1){
                  canUploadFileNameList.push(item);
                }
              })
            }
            this.sameFileNameVisible = sameFileNameList.length > 0 ? true :false;
            fileList.forEach(item => {
              if(canUploadFileNameList.indexOf(item.name) !== -1){
                // saveType 0：新建，1：替换，2：保存为副本
                item.saveType = 0;
                canUploadList.push(item);
              } else {
                this.sameFileList.push(item)
              }
            })
            if(canUploadList.length > 0){
              this.uploadInfoVisible = true;
              this.setTaskQueue(canUploadList,type);
            }
          }
        })
    },
    // 替换
    replaceFile(){
      this.breadcrumbOld = JSON.parse(JSON.stringify(this.breadcrumb))
      this.uploadInfoVisible = true;
      // saveType 0：新建，1：替换，2：保存为副本
      this.sameFileList.forEach(item => {
        item.saveType = 1;
      })
      this.setTaskQueue(this.sameFileList,'');
      this.sameFileNameVisible = false
    },
    // 保存为副本
    saveCopyFile(){
      this.breadcrumbOld = JSON.parse(JSON.stringify(this.breadcrumb))
      this.uploadInfoVisible = true;
      // saveType 0：新建，1：替换，2：保存为副本
      this.sameFileList.forEach(item => {
        item.saveType = 2;
      })
      this.setTaskQueue(this.sameFileList,'');
      this.sameFileNameVisible = false
    },
    setTaskQueue (list,type) {
      let needUploadList = []
      list.forEach((fileItem) => {
        fileItem.uid = uuidv4();
        this.activeFileList.unshift(this.formatUploadFileInfo(fileItem, false, '', 'pending'));
        needUploadList.unshift(this.formatUploadFileInfo(fileItem, false, '', 'pending'));
      })
      this.concurrencyRequest(needUploadList, 1,type)
    },
    concurrencyRequest (fileList, maxNum,type) {
      let that = this
      return new Promise((resolve) => {
          if (fileList.length === 0) {
              resolve([]);
              return;
          }
          const results = [];
          let index = 0; // 下一个请求的下标
          let count = 0; // 当前请求完成的数量

          // 发送请求
          async function request () {
              if (index === fileList.length) return;
              const i = index; // 保存序号，使result和fileList相对应
              const file = fileList[index];
              index++;
              try {
                  const resp = await that.upload(file, type);
                  // resp 加入到results
                  results[i] = resp;
              } catch (err) {
                  // err 加入到results
                  results[i] = err;
              } finally {
                  count++;
                  // 判断是否所有的请求都已完成
                  if (count === fileList.length) {
                      console.log('完成了');
                      setTimeout(function (){
                        that.selectCompanyUseFlow();
                      },2000)
                      resolve(results);
                  }
                  request();
              }
          }
          // maxNum和fileList.length取最小进行调用
          const times = Math.min(maxNum, fileList.length);
          for (let i = 0; i < times; i++) {
              request();
          }
      })
    },
    async upload (file,type) {
      let surplusCapacity = this.combinationInfo.surplusCapacity;
      console.log(surplusCapacity,type,'上传之前---剩余');
      let isBeyond = surplusCapacity - file.oldSize < 0 ? true : false;
      if(!isBeyond){
        await multipartUploadChangeFilName(ossConfig, file.file, (process) => {
          // this.needUploadList.forEach(item => {
            this.activeFileList.forEach(AllItem => {
                if (AllItem.uid == file.uid) {
                  this.$set(AllItem, 'process', process)
                  if(AllItem.process === 0) {
                    this.$set(AllItem, 'uploadStatus', 'pending');
                  } else if(AllItem.process === 1) {
                    this.$set(AllItem, 'uploadStatus', 'success');
                  }
                }
            })
          // })
          }).then((resp) => {
              file.response = resp;
              this.combinationInfo.surplusCapacity = surplusCapacity - file.oldSize;
              this.combinationInfo.useCapacity = this.combinationInfo.useCapacity + file.oldSize;
              this.combinationInfo.percentage = Number((this.combinationInfo.useCapacity / this.combinationInfo.totalCapacity * 100).toFixed(1));
              console.log(this.combinationInfo.surplusCapacity,'上传成功后的---剩余');
              if(type === 'folder'){
                this.uploadSuccessFolder(file);
              } else {
                this.uploadSuccess(file);
              }
          })
      } else {
        this.activeFileList.forEach(item => {
          if(item.uid === file.uid){
            this.$set(item, 'uploadStatus', 'error');
            item.isBeyond = true;
            item.beyondType = 'total';
          }
        })
      }
    },
    formatSize(size) {
      let sizeB = size * 1024 * 1024;
      let str = "";
      if (sizeB < 1024) {
          str = sizeB.toFixed(1) + "B";
      } else {
          if (size < 1) {
              str = (sizeB / 1024).toFixed(1) + "KB";
          } else {
              str = Number(size).toFixed(1) + "M";
          }
      }
      return str;
    },
    /**
     * 上传文件
     * */
    formatUploadFileInfo(fileInfo,isBeyond,beyondType,uploadStatus) {
      let tampFileInfo = {
          size: (fileInfo.size / 1024 / 1024).toFixed(3),
          icon: formatDoc.formatFileTypeSrc(fileInfo),
          name: fileInfo.name,
          uid: fileInfo.uid,
          process: 0,
          file:fileInfo,
          isBeyond:isBeyond,
          beyondType:beyondType,
          oldSize:fileInfo.size,
          uploadStatus:uploadStatus,
          
      };
      return tampFileInfo;
    },
    closeUploadInfoVisible() {
        this.uploadInfoVisible = false;
        this.activeFileList = [];
    },
    /**
     * table中数据点击生成对应的面包屑
     * */
    rowClick(data){
      this.$refs.folderManagement.nodeParentData = data;
      this.breadcrumb.push(data);
      this.currentNode = data;
      this.$refs.folderManagement.nodeData = data;
      // if (this.breadcrumb.length - 2 >= 0){
      //   this.$refs.folderManagement.nodeParentData = this.breadcrumb[this.breadcrumb.length - 2]
      // } else {
      //   this.$refs.folderManagement.nodeParentData = {
      //     id:-1,
      //     name:"企业知识库",
      //     children:[]
      //   }
      // }
      this.$refs.folderManagement.checkDefault = data.id;
      let treeBox = this.$refs.folderManagement.$refs.treeBox ? this.$refs.folderManagement.$refs.treeBox : (this.$refs.folderManagement.$refs.individualTreeBox ? this.$refs.folderManagement.$refs.individualTreeBox : '')
      if (treeBox){
        treeBox.setCurrentKey(data.id);
      }
    },
    /**
     * 点击查看文件详情
     * */
    lookDetail(data){
      this.currentNode = data;
      this.$refs.folderManagement.nodeData = data;
      // if (this.breadcrumb.length - 1 >= 0){
      //   this.$refs.folderManagement.nodeParentData = this.breadcrumb[this.breadcrumb.length - 1]
      // } else {
      //   this.$refs.folderManagement.nodeParentData = {
      //     id:-1,
      //     name:"企业知识库",
      //     children:[]
      //   }
      // }
      if (!this.isShowKnowledgeDetail){
        this.isShowKnowledgeDetail = true;
      }
    },
    //返回指定文件夹下
    gobackFolder(item,index){
      if(item.name ==='企业知识库'){
        this.parentFolder()
      } else {
        this.$refs.knowledgeTable.currentPage = 1;
        this.$refs.knowledgeTable.getFolderAndFile(item.id);
        this.currentNode = item;
        this.breadcrumb.splice(index+1,this.breadcrumb.length);
        this.$refs.folderManagement.nodeData = item;
      }
     

      // if (this.breadcrumb.length - 2 >= 0){
      //   this.$refs.folderManagement.nodeParentData = this.breadcrumb[this.breadcrumb.length -2]
      // } else {
      //   this.$refs.folderManagement.nodeParentData = {
      //     id:-1,
      //     name:"企业知识库",
      //     children:[]
      //   }
      // }
      this.$refs.folderManagement.checkDefault = item.id;
      let treeBox = this.$refs.folderManagement.$refs.treeBox ? this.$refs.folderManagement.$refs.treeBox : (this.$refs.folderManagement.$refs.individualTreeBox ? this.$refs.folderManagement.$refs.individualTreeBox : '')
      if (treeBox){
        treeBox.setCurrentKey(item.id);
      }
    },
    //返回顶级节点
    parentFolder(){
      let folder = this.$refs.folderManagement.folderTree
      if (this.breadcrumbCopy.length > 0){
        this.breadcrumb = this.breadcrumb.slice(0,1);
        this.currentNode = folder[0] ? folder[0] :{}
        this.$refs.knowledgeTable.currentPage = 1;
        this.$refs.knowledgeTable.getFolderAndFile(this.currentNode.id);
        this.$refs.folderManagement.checkDefault = this.currentNode.id;
        this.$refs.folderManagement.$refs.treeBox.setCurrentKey(this.currentNode.id);
      }
    },
    /**
     * 表格中选择文件编辑/移动/删除
    * */
    knowledgeEdit(row){
      this.$refs.folderManagement.nodeParentData = parentNode(this.breadcrumb,this.folderTree[0])
      this.$refs.folderManagement.nodeData = row;
      this.$refs.folderManagement.handleCommand('edit', null, null, 'detail-edit');
    },
    knowledgeMove(row){

      this.$refs.folderManagement.nodeParentData = parentNode(this.breadcrumb,this.folderTree[0])
      this.$refs.folderManagement.nodeData = row;
      this.$refs.folderManagement.handleCommand('shift', null, null, 'detail-shift');
    },
    knowledgeDel(row){

      this.$refs.folderManagement.nodeParentData = parentNode(this.breadcrumb,this.folderTree[0])
      this.$refs.folderManagement.nodeData = row;
      this.$refs.folderManagement.folderTreeCopy =  JSON.parse(JSON.stringify(this.$refs.folderManagement.folderTree))
      this.$refs.folderManagement.removeCurrentNode(this.$refs.folderManagement.folderTreeCopy)
      this.$refs.folderManagement.radio = 'remove';
      // this.$refs.folderManagement.deleteFolderDialog = true;
      this.$refs.folderManagement.delType = 'table';
      this.$refs.folderManagement.handlerDelFolder();
      
    },
    /*权限设置*/
    folderPermission(row){
      this.$refs.folderManagement.nodeParentData = parentNode(this.breadcrumb,this.folderTree[0])
      this.$refs.folderManagement.nodeData = row;
      this.$refs.folderManagement.handleCommand('permission')
    },
    elDropdownvisibleChange(value) {
      // if (value) {
      //   this.setChecked(this.moveToParentId,this.folderPullTreeCopy);
      // }
      this.parentDirectorySelect = value;
    },
    nodeClick(node) {
      this.$set(node, "checked", true);
      this.moveToParentId = node.id;
      this.parentDirectoryName = node.name;
      let messageDrop = this.$refs.messageDrop;
      if (messageDrop) {
        messageDrop.visible = false;
      }
    },
    //打开父目录时选中态回显
    setChecked(id,list) {
      list.forEach(item => {
        this.$set(item, "checked", false);
        if (item.children){
          this.setChecked(id,item.children)
        }
      });
      list.forEach(item => {
        if (item.id === id) {
          this.$set(item, "checked", true);
        }
        if(item.children){
          this.setChecked(id,item.children)
        }
      });
    },
    /**
     * 懒加载数据
     * */
    loadDireNode(node, resolve) {
      console.debug('nodelevel',node.level)
      let corpId = sessionStorage.getItem('_corpId');
      let  mainId = sessionStorage.getItem('_mainId');
      let url = '';
      let isAskLightning = GetQueryString('isAskLightning') == 1;
      if(isAskLightning){
        url = requestUrl.knowledgeBase.clientSelectKnowledgeBaseStructureTree;
      } else {
        url = requestUrl.knowledgeBase.knowledgeBaseTreeLazy;
      }
      // let url =requestUrl.knowledgeBase.knowledgeBaseTreeLazy;
      url += "?corpId=" + corpId + '&mainId=' + mainId;
      url += '&parentId=' + (node.data.id ? node.data.id : -1)
      url += '&keyWord='
      let axiosDatas = [];
      if (node.level === 0) {
        this.$http.get(url).then(res => {
          if (res.data.code == 0 && res.data.data){
            axiosDatas = res.data.data;
            axiosDatas.forEach(element => {
              element.leaf = false;
            });
            this.folderPullTreeCopy = axiosDatas;
            return resolve(this.folderPullTreeCopy);
          }
        })
      }
      if (node.level >= 1) {
        this.$http.get(url).then(res => {
          if (res.data.code == 0 && res.data.data){
            axiosDatas = res.data.data
            axiosDatas.forEach(element => {
              element.leaf = false;
              element.checked=false;
            });
            let ids = this.selectFolderList.map(res => {
              return res.id
            })
            let data = axiosDatas.filter(item => ids.indexOf(item.id) === -1)
            node.data.children = data;
            return resolve(data);
          }
        })
      }
    },
    /**
     * 批量移动文件/文件夹
     * */
    moveFolder(list){
      // this.getKnowledgeBaseTree();
      this.selectFolderList = list;
      this.moveToNew = true;
    }
    ,
    /**
     * 将选择的文件移动到指定文件夹下
     * */
    moveToNewFolder(){
      if (!this.moveToParentId){
        this.$message({
          message:"选择您将要移动到的文件夹",
          duration:2000,
          type:"success"
        })
        return
      }
      let url = requestUrl.knowledgeBase.moveToNewHome;
      let obj = {
        nodeIds:this.selectFolderList.map(res => {
          return res.id;
        }),
        newHomeId:this.moveToParentId,
        corpId:sessionStorage.getItem('_corpId')
      }
      this.$http.post(url,obj).then(res => {
        if (res.data.code == 0){
          this.$message({
            message:this.$t('knowledgeManagement.folderManagement.script.moveSuccess'),
            duration:2000,
            type:'success'
          })
          this.moveToNew = false;
          console.debug('currentNode---',this.currentNode)
          this.getKnowledge(this.currentNode)
        }
        this.$refs.knowledgeTable.nextExecuteFnName = ""
      })
    },
    /**
     * 批量删除
     * */
    batchDelete(list){
      this.selectFolderList = list;
      this.checkedDeptPopup = {};
      this.radio = 'remove';
      this.delFolderNameList = [];
      let corpId = sessionStorage.getItem('_corpId');
      let mainId = sessionStorage.getItem('_mainId');
      let folderIds = this.selectFolderList.map(res => {
          return res.id
        })
      this.$http.post(requestUrl.knowledgeBase.delCheckFolder + '?corpId=' + corpId + '&mainId=' + mainId,{
        folderIds:folderIds
      }).then(res => {
        if (res.data.code == '0'){
          if(res.data.data){
            for(let key in res.data.data){
              for (let index = 0; index < this.selectFolderList.length; index++) {
                const item = this.selectFolderList[index];
                if(res.data.data[key] && item.id == key){
                  this.delFolderNameList.push(item);
                } 
              }
            }
          }
        }
        if(this.delFolderNameList.length > 0){
          this.deleteFolderDialog = true;
        } else {
          this.deleteFolderNoJudgeDialog = true;
          this.$refs.knowledgeTable.nextExecuteFnName = ""
        }
        this.$refs.knowledgeTable.getFolderAndFile( this.currentNode.id,this.currentNode.name === '企业知识库' ? null : 'del');
      })
    },
    onNodeClick(data){
      this.getAllNodeId(this.folderPullTreeCopy,data);
    },
    deleteFolder(){
      let url = requestUrl.knowledgeBase.moveDeleteFolder
      let obj = {
        deleteNodeIds:this.selectFolderList.map(res => {
          return res.id
        }),
        newParentId:null
      }
      if (this.radio === 'remove'){
        this.judgeChildrens(this.folderPullTreeCopy);
        if(Object.keys(this.checkedDeptPopup).length === 0 && this.radio === 'remove' && this.delFolderNameList.length > 0){
          this.$message.error(this.$t('knowledgeManagement.folderManagement.script.deleteFolderMessage'))
          return false;
        }
        obj.newParentId = this.checkedDeptPopup.id;
      }
      this.$http.delete(url,{
        data:obj
      }).then(res => {
        if (res.data.code == '0'){
          this.deleteFolderDialog = false;
          this.deleteFolderNoJudgeDialog = false;
          this.$message({
            message:this.$t('knowledgeManagement.folderManagement.script.deleteSuccess'),
            duration:2000,
            type:"success"
          })
          this.currentNode = this.breadcrumb[this.breadcrumb.length - 1]
          // this.$refs.folderManagement.getKnowledgeBaseList('');
          this.delData = this.$refs.folderManagement.$refs.treeBox &&  this.$refs.folderManagement.$refs.treeBox.getCurrentNode(this.currentNode.id)
          this.$refs.knowledgeTable.getFolderAndFile( this.currentNode.id,this.currentNode.name === '企业知识库' ? null : 'del');
          this.$refs.knowledgeTable.selection = [];
          this.selectCompanyUseFlow()
          this.$refs.folderManagement.getKnowledgeBaseList(this.currentNode.id,this.delData,'clear')
        }
      })
    },
    deleteSuccess(row){
      this.currentNode = row;
      this.selectCompanyUseFlow()
    },
    deleteDetail(){
      let parentId = parentNode(this.breadcrumb,this.folderTree[0]).id;
      let data = this.$refs.knowledgeTable.tableData;
      if (data.length == 1){
        this.$refs.knowledgeTable.currentPage = 1;
      }
      this.$refs.knowledgeTable.getFolderAndFile(parentId,'del');
    },
    // 递归取出checked=true的数据
    judgeChildrens(data) {
      data.forEach(item => {
        if (item.checked) {
          this.checkedDeptPopup = item;
        }
        if (item.children && item.children.length > 0) {
          this.judgeChildrens(item.children || []);
        }
      });
      return
    },
    // 递归获取所有节点
    getAllNodeId (moduleDataList,data) {
      return new  Promise((resolve) => {
        if (moduleDataList){
          for (let i = 0; i < moduleDataList.length; i++) {
            if(data.id === moduleDataList[i].id){
              this.$set(moduleDataList[i],'checked',!moduleDataList[i].checked);
            } else {
              this.$set(moduleDataList[i],'checked',false);
            }
            if (moduleDataList[i].children && moduleDataList[i].children.length > 0) {
              this.getAllNodeId( moduleDataList[i].children,data)
            }
          }
        }
        resolve(true)
      })
    },
    /*全量加载请求左侧列表*/
    getKnowledgeBaseTree(){
      let corpId = sessionStorage.getItem('_corpId');
      let mainId = sessionStorage.getItem('_mainId');
      let url = requestUrl.knowledgeBase.knowledgeBaseTree;
      url += "?corpId=" + corpId + '&parentId=-1' + '&mainId=' + mainId + '&keyWord=';
      this.$http.get(url).then(res => {
        console.debug('resData',res.data)
        if (res.data.code == '0'){
          this.folderPullTreeCopy = res.data.data;
          this.folderPullTree = JSON.stringify(res.data.data)
        }
      })
    },
    closeDrawer(){
      this.synchronizationDrawer = false;
      this.filesCom = false;
      this.channel = -0
      // let node = parentNode(this.breadcrumb);
      // let page = this.$refs.knowledgeTable.currentPage;
      // if (page == 1){
      //   this.$refs.knowledgeTable.getFolderAndFile(node.id);
      // }
      this.$refs.folderManagement.getKnowledgeBaseList('',null,'closeDrawer')
    },
    /**
     * 获取文件夹下文件/文件夹的数量
     * */
    getDataTotal(data){
      this.dataTotal = data;
    },
    /**
     * 打开目录管理
     * */
    openDirectoryDialog(){
      this.$refs.folderManagement.handleDirectory();
    },
    synchronization(data){
      this.isShowSynchronizationloading = true;
      this.synchronizationData = JSON.parse(JSON.stringify(data));
    },
    getKnowledgeData(data){
      let parentData = getCurrentNode(this.breadcrumb,this.folderTree[0])
      console.log('data',data)
      // if (parentData.id === data.id){
      //   this.$refs.knowledgeTable.currentPage = 1;
      //
      // }
      this.$refs.knowledgeTable.getFolderAndFile(parentData.id);
      this.$nextTick(() => {
        this.$refs.folderManagement.nodeData.visitPermission = data.visitPermission;
        this.$refs.folderManagement.nodeData.managePermission = data.managePermission
        this.$refs.folderManagement.nodeData.textWatermark = data.textWatermark;
        this.$refs.folderManagement.nodeData.textWatermarkValue = data.textWatermarkValue;
        this.$refs.folderManagement.nodeData.isDownload = data.isDownload;
        this.$refs.folderManagement.nodeData.downloadType = data.downloadType;
      })
    },
    /**
     * 接收监听来自服务端传入的消息 应用初始化时启用
     * @method initAskAppEventSource
     * @param {} 
     * @return
     */
    initAskAppEventSource() {
        setTimeout(() => {
            console.log("initAskAppEventSource", this.appEvt);
            this.appEvt && this.appEvt.close();
            this.appEvt = new EventSource('/knowledge-api/global/sse-emitter?userId=' + sessionStorage.getItem("_uid"));
            this.appEvt.addEventListener("message", (e) => {
                let resObj = JSON.parse(e.data);
                if (resObj.type === 'KNOWLEDGE_SYNC_SUCCESS') {
                  this.isShowSynchronizationloading = false;
                  if(this.synchronizationData.id){
                    this.$http.get(requestUrl.knowledgeBase.getKnowledgeBaseById + '?id=' + this.synchronizationData.id).then(res => {
                      let list = JSON.parse(JSON.stringify(this.$refs.knowledgeTable.tableData))
                      list.forEach((item,index) => {
                        if(item.id === res.data.data.id){
                          list[index] = res.data.data
                        }
                      })
                      this.$refs.knowledgeTable.tableData = list;
                      this.synchronizationData = {};
                      this.$message({
                        message:this.$t('knowledgeManagement.management.syncSuccess'),
                        duration:2000,
                        type:"success"
                      })
                    })
                  }
                }
            })
            this.appEvt.onerror =  (e) => {
              console.log("[MainView] 166: ", e);
              this.appEvt.close();
              this.initAskAppEventSource();
            }
        }, 2000);

        // setTimeout(() => {
        //     this.$refs.appNotice.closeUploadInfoVisible(true);
        // }, 15000);
    },
     //  查询用户会员信息、流量包、空间容
    selectCompanyUseFlow(){
        this.$http.get(requestUrl.knowledge.getKnowledgeRestrict+ '?mainId=' + sessionStorage.getItem('_mainId')).then((res) => {
            if (res.data.data) {
              res.data.data.percentage = Number((res.data.data.useCapacity / res.data.data.totalCapacity * 100).toFixed(1));
              this.combinationInfo = res.data.data;
            }
        })
    },
    getSelectSyncList(){
      this.$http.get('/knowledge-api/feishu/selectSyncList' + '?corpId=' + sessionStorage.getItem('_corpId') + '&parentToken=-1&justFolder=true').then((res) => {
        if (res.data.code == '0'){
          // this.$router.push({ path: '/knowledgeManagement'})
        }
      })
    },
    handlerUnit(value){
        return value/1024/1024/1024>=1 ? (value/1024/1024/1024).toFixed(1) + 'GB' : (value/1024/1024).toFixed(1) + 'MB'
    },
    getClientUser(){
      let url = '/knowledge-api/sso/auth/client-user';
      this.$http.get(url).then(res => {
        if (res.data.code == 0 && res.data.data){
          this.clientUser = res.data.data
          sessionStorage.setItem('_clientUid',res.data.data.id)
        }
      })
    },
    hasManagement(){
      let bool = false;
      let roleId = sessionStorage.getItem('roleId')
      if (roleId == 1){
        return true
      }
      let data = { parentId:-1 };
      if (this.breadcrumb.length > 0){
        data = this.breadcrumb[this.breadcrumb.length - 1]
      }
      if (data.parentId == -1){
        return true
      }
      if (data.managePermission && JSON.parse(data.managePermission)){
        let grouIds = sessionStorage.getItem('_groupIds') ? JSON.parse(sessionStorage.getItem('_groupIds')) : [];
        let userId = sessionStorage.getItem('_uid') ? Number(sessionStorage.getItem('_uid')) : ""
        if (grouIds){
          grouIds = grouIds.map(res => {
            return Number(res)
          })
        }
        let managePermission = JSON.parse(data.managePermission).map(res => {
          return res.id
        });
        if (managePermission.includes(userId)){
          bool = true;
          return bool
        } else if (grouIds){
          for (let i=0;i<grouIds.length;i++){
            if (managePermission.includes(grouIds[i])){
              bool = true
              return bool;
            }
          }
        }
      }
      return bool
    },
    getFolderDetail(nodeData){
      this.$refs.folderDetails.getNodeDetail(nodeData);
    },
    handleClickOutside(event) {
      const target = event.target;
      console.log('target',target)
      window.parent.postMessage({
        data: "close_dropdown",
      },"*");
    }
  },
  props:["corpId"],
  watch:{
    breadcrumb:{
      handler(n){
        console.log(n,'breadcrumb=nn');
        this.breadcrumbCopy = [];
        if(n) {
          this.breadcrumbCopy = n.slice(1,n.length+1)
        }
      },
      deep:true
    }
  },
  computed:{
    // 当前列表的上传状态
    uploadInfoStatus () {
        if (this.activeFileList.some(item => { return item.uploadStatus == 'pending' })) {
            return 'pending'
        } else if (this.activeFileList.some(item => { return item.uploadStatus == 'error' })) {
            return 'error'
        } else {
            return 'success'
        }
    },
    uploadedSuccessCount() {
        let count = 0
        count = JSON.parse(JSON.stringify(this.activeFileList)).filter(item => {
          return item.uploadStatus == 'success'
        }).length
        return count
    },
    uploadedFailCount () {
        let count = 0
        count = JSON.parse(JSON.stringify(this.activeFileList)).filter(item => {
          return item.uploadStatus == 'error'
        }).length
        return count
    }
  },
  created(){
    this.selectCompanyUseFlow()
  },
  mounted() {
    if(!this.isAskLight()){
      this.getSelectSyncList();
    }
    this.getClientUser();
    this.initAskAppEventSource();
    this.$nextTick(() => {
      if (this.corpId){
        sessionStorage.setItem('_corpId',this.corpId);
      }
    })
    let isComeAccess = JSON.parse(sessionStorage.getItem('isComeAccess'));
    if (isComeAccess && isComeAccess.flag){
      setTimeout(() => {
        this.synchronizationDrawer = true;
        this.channel = isComeAccess.channel;
        this.$nextTick(() => {
          this.filesCom = true;
        })
        sessionStorage.removeItem('isComeAccess');
      },1000)
    }
    window.addEventListener('click', this.handleClickOutside);
    // this.getKnowledgeBaseTree();
  },
  beforeDestroy(){
    this.appEvt && this.appEvt.close();
    window.removeEventListener('click',this.handleClickOutside)
  }
};
</script>
<style lang="less">
.dropdown-upload{
  .el-dropdown-menu__item{
    width:calc(100% - 40px);
    .upload-demo{
      width:100%;
      .el-upload{
        width:100%;
        text-align: left;
      }
    }
  }
}
</style>

<style scoped lang="less">
#knowledgeManagement{
  display: flex;
  align-items: center;
  height: 100%;
  overflow: hidden;
  flex: 1;
  .knowledgeSidebar{
    flex: none;
    width: 246px;
    height: 100%;
    background: #FFFFFF;
    box-shadow: 0px 0px 18px 0px rgba(29,55,129,0.07);
    border-radius: 5px;
    margin-right: 16px;
    .capacity-use-detail{
      padding: 0 12px 12px;
      width: 222px;
      .text{
        color: #A9B3C6;
        font-size: 12px;
        margin-bottom: 10px;
      }
      .alc-item-value-pre {
        width: 222px;
        max-width: 222px;
        height: 6px;
        background: #E8EEFF;
        border-radius: 5px;
        .alc-item-value-pre-innner {
            display: block;
            height: 6px;
            max-width: 222px;
            background: #366aff;
            border-radius: 5px;
        }
      }
    }
  }
  .knowledgeContainer{
    width:calc(100% - 246px);
    height: 100%;
    .containerTop{
      height: 56px;
      background: #FFFFFF;
      border-radius: 9px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 15px;
      .topLeft{
        display: flex;
        align-items: center;
        .enterprise{
          color:#A9B3C6;
          cursor:pointer;
          &.enterprise-disabled{
            cursor: default;
          }
        }
        .breadcrumbItem{
          max-width: 250px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          cursor: pointer;
          margin-right: 2px;
          color: #A9B3C6;
        }
        .lastBreadcrumbItem{
          color: #000000!important;
        }
        .dataTotal{
          padding: 2px 6px;
          background: #ECF1FF;
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #366AFF;
          font-size: 12px;
          margin-left: 10px;
        }
        .symbol{
          color: #A9B3C6;
        }
      }
      .topRight{
        display:flex;
        align-items: center;
        flex: none;
        width: 72px;
        justify-content: space-between;
        .addBtn{
          width: 30px;
          height: 30px;
          background: #366AFF;
          border: 1px solid #366AFF;
          border-radius: 5px;
          display:flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          i{
            color:#ffffff;
          }
        }
        .hideDetails{
          width: 30px;
          height: 30px;

          border-radius: 5px;
          display:flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

        }
        .expand{
          background: #366aff;
          border: 1px solid #366aff;
          i{
            color:#FFFFFF;
          }
        }
        .hide{
          background: #FFFFFF;
          border: 1px solid #A1B9FF;
          i{
            color:#366aff;
          }
        }
      }
      .onlySee{
        justify-content: flex-end!important;
      }
    }
    .container{
      // box-shadow: 0px 0px 18px 0px rgba(29,55,129,0.07);
      border-radius: 5px;
      display:flex;
      margin-top:16px;
      height:calc(100% - 72px);
      .containerTable{
        height: 100%;
      }
      .setTableWidth{
        width:calc(100% - 302px);
        transition:width 1s;
      }
      .notSetTableWidth{
        width:100%;
        transition:width 1s;
      }
      .knowledgeDetail{
        flex: none;
        width: 330px;
        height:calc(100% - 15px);
        background: #FFFFFF;
        border-radius: 9px;
        margin-left:16px;
        //padding: 10px 0;
        padding-bottom: 15px;
      }
    }
  }
  .synchronization-loading{
    position: fixed;
    top: 20px;
    left:calc((100vw - 380px)/2);
    background-color: #fdf6ec;
    border:1px solid #faecd8;
    color: #E6A23C;
    border-radius: 4px;
    padding: 10px 15px 10px 20px;
    width: 380px;
  }
  .ask-upload-notify {
    z-index: 10;
    position: absolute;
    min-height: 50px;
    bottom: 18px;
    right: 16px;
    width: 200px;
    width: 351px;
    background: #FFFFFF;
    box-shadow: 0px 1px 9px 0px rgba(57, 63, 79, 0.21);
    border-radius: 5px;
    .ask-upload-notify-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 48px;
        line-height: 48px;

        .aunh-left {
            flex: auto;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 48px;
            padding-left: 12px;
            font-size: 12px;
            .aunh-left-text{
              margin-left: 6px;
              font-size: 12px;
              color:#2c3e50;
            }

            .aunh-left-fail{
                color: #FE5965;
                font-size: 14px;
                font-weight: 700;
                margin-right: 5px;
                display: flex;
                align-items: center;
            }

            .aunh-left-loading {
                display: flex;
                align-items: center;
                margin-right: 10px;
                
            }

            .aunh-left-suc {
                color: #04B051;
                font-weight: 700;
                margin-right: 5px;
                display: flex;
                align-items: center;
            }

            .aunh-left-count {
                height: 17px;
                line-height: 17px;
                padding: 0 5px;
                background: #ECF1FF;
                border-radius: 9px;
                margin-right: 5px;
            }
            .iconfont{
                vertical-align: middle;
            }
        }

        .aunh-right {
            flex: none;
            width: 80px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-right: 12px;

            .aunh-right-up,
            .aunh-right-down {
                font-size: 12px;
                font-weight: lighter;
                cursor: pointer;
            }

            .aunh-right-close {
                margin-left: 6px;
                color: #A9B3C6;
                cursor: pointer;
            }
        }
    }
    .ask-upload-notify-body {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding-bottom: 12px;
        max-height: 200px;
        overflow-y: auto;
        overflow-x: hidden;
        .aunb-info {
            height: 24px;
            line-height: 24px;
            text-align: left;
            padding: 0 12px;
            font-size: 12px;
            font-weight: bolder;
            background-color: rgba(54, 106, 255, .07);
            margin-bottom: 5px;
            display: flex;
            justify-content: space-between;
            .right{
                color: #366AFF;
                font-size: 12px;
                cursor: pointer;
            }
        }
        .aunb-cell {
            height: 32px;
            width: 100%;
            margin: 4px 0;
            position: relative;

            .aunb-cell-process {
                position: absolute;
                left: 0;
                top: 0;
                height: 32px;
                border-radius: 16px;
                width: 50%;
                background-color: rgba(54, 106, 255, .07);
            }

            .aunb-cell-info {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 12px;
                height: 100%;
                line-height: 100%;
                width: 320px;
                position: relative;
                z-index: 2;

                .aunb-cell-info-left {
                    flex: auto;
                    justify-content: flex-start;
                    align-items: center;
                    display: flex;

                    .acil-suc {
                        color: #95E0B7;
                        margin-right: 6px;
                    }

                    .acil-err {
                        color: #FFA8AE;
                        margin-right: 6px;
                    }

                    .acil-name-box {
                        font-size: 14px;
                        // font-weight: 700;
                        margin-left: 4px;
                        margin-right: 6px;
                        text-align: left;
                       
                        .acil-name{
                           text-overflow: ellipsis;
                            display: -webkit-box; 
                            overflow: hidden;     
                            word-break: break-all; 
                            text-overflow: ellipsis;  
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 1;  
                            white-space:pre-line;  
                            max-width: 170px;
                        }
                        
                        .beyond{
                            margin-top: 4px;
                            color:#FE5965;
                            font-size: 12px;
                            .upgradation{
                                cursor: pointer;
                                color: #366AFF;
                                margin-left: 20px;
                            }
                        }
                    }
                }

                .aunb-cell-info-right {
                    flex: none;
                    width: 80px;
                    justify-content: flex-end;
                    align-items: center;
                    display: flex;
                    font-size: 12px;
                    height: 32px;
                    line-height: 32px;

                    .acir-current {
                        font-weight: bolder;
                        color: #366AFF;
                    }

                    .acir-total {
                        color: #A9B3C6;
                        margin-right: 6px;
                    }

                    .acir-close {
                        color: #A9B3C6;
                    }

                    .acir-reload {
                        color: #A9B3C6;
                    }
                }
            }
        }
    }
    }
}
.askLightKnowledgeManage{
  .knowledgeSidebar{
    margin-right: 0!important;
    border-right: 1px solid #EBEEF5;
    border-top-right-radius: 0!important;
    border-bottom-right-radius: 0!important;
  }
  .knowledgeContainer{
    .containerTop{
      border-top-left-radius: 0!important;
      border-bottom-left-radius: 0!important;
      border-bottom-right-radius: 0!important;
      border-bottom: 1px solid #EBEEF5;
    }
    .container{
      margin-top: 0!important;
      height: calc(100% - 57px) !important;
      #knowledgeTable{
        box-shadow: none!important;
        border-right: 1px solid #EBEEF5;
        .table{
          border-radius: 0!important;
        }
        /deep/#pagination{
          box-shadow: none!important;
        }
      }
      .setTableWidth{
        width: calc(100% - 330px) !important;
      }
      .knowledgeDetail{
        border-top-left-radius: 0!important;
        border-bottom-left-radius: 0!important;
        border-top-right-radius: 0!important;
        margin-left: 0!important;
      }
    }
  }
}
/deep/#popup-assembly{
  width: 600px;
  .el-form{
    width: 100%;
  }
  .el-form-item{
    margin-bottom: 16px;
  }
  .el-form-item__label{
    line-height: 24px;
  }
}
#el-dropdown-dir{
  width: 100%;
  .el-dropdown-link-dir{
    display: flex;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    .el-dropdown-placeholder{
      flex: auto;
      display: block;
      height: 38px;
      padding: 0 18px;
      color: #C0C4CC;
    }
    .el-dropdown-dir-content {
      flex: auto;
      display: flex;
      align-items: center;
      height: 38px;
      padding: 0 18px;

      .el-tag {
        margin: 2px 5px 0 0;
      }
    }
    .el-icon{
      flex: none;
      width: 30px;
      text-align: center;
      display: flex;
      align-items: center;
    }
  }
}
.dir-dropdown-item{
  width: 500px!important;
  padding: 0!important;
  .dir-tree{
    height: auto;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #ffffff;
  }
  .custom-tree-node {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;

    i {
      font-weight: 600;
      color: #616161;
      font-size: 14px;
    }
  }
  /deep/.el-tree{
    max-height: 300px;
  }
}
/deep/.el-dropdown-menu__item{
  display: flex;
  align-items: center;
}
.custom-tree-node-choose-dept{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.closeSynchronize{
  width: calc(100% - 20px);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .closeButton{
    width: 90px;
    height: 32px;
    background: #FFFFFF;
    border: 1px solid #E0E6F7;
    border-radius: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
.demo-ruleForm{
  .del-tips{
    height: 42px;
    background: #FFF8F4;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 400;
    color: #FF9555;
    text-align: left;
    line-height: 42px;
    padding: 0 10px;
  }
}
/deep/.el-drawer__body{
  height: 100%;
}
/deep/.same-name-file-popup{
  .popup-container-c{
    max-height: calc(84vh - 200px);
    overflow-y: scroll;
    padding: 0 64px;
  }
  .same-name-file-popup-content{
    .same-name-file-popup-item{
      width: 100%;
      height: 25px;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      img{
        width: 20px;
        margin-right: 4px;
      }
    }
  }
}
</style>