import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store';
import {
    Dropdown,
    Select,
    Tooltip,
    Link,
    Avatar,
    Badge,
    Input,
    Drawer,
    Tree
} from 'element-ui'
import './assets/style/theme/index.css' // element 自定义样式
import {requestUrl} from "./http/requestUrl";
import instance from "./http/http";
import ignore_auth_instance from "./http/http_ignore_auth";
import { isUseOpenData } from "@/assets/js/filterTree";
import {isAskLightning} from "@/assets/js/common";

import CKEditor from '@ckeditor/ckeditor5-vue2';
// 公用方法
import tool from './utils/common';
import { isLiBang } from './assets/js/accountMainId';




import VueI18n from 'vue-i18n'; //多语言

// 导入多语言的 JSON 文件
import enLocale from './locales/en.json';
import cnLocale from './locales/cn.json';

Vue.use(VueI18n);
// 创建 VueI18n 实例，并设置默认语言和多语言内容

let lang = 'cn';

Vue.prototype.main_isLishi = process.env.VUE_APP_SOURCE == 'lishi';
// Vue.prototype.main_isLishi = true;

if(window.location.origin == 'https://wisdom.ebot.isheely.com') {
  document.title = 'eKMS知识管理系统'
} else {
  if (Vue.prototype.main_isLishi) {
      document.title = 'AskKMS'
  } else {
      document.title = 'AskKMS知识管理系统'
  }
}

// 立时环境特殊处理
if (Vue.prototype.main_isLishi) {
  lang = 'en';
}

const i18n = new VueI18n({
    locale: lang, // 默认语言
    messages: {
      en: enLocale, // 英文
      cn: cnLocale, // 中文
      // 其他语言...
    }
  });


//把tools的工具挂载到vue原型上
import _lodash from "lodash";

Object.keys(tool).forEach(val => {
    Vue.prototype[val] = tool[val];
  })
Vue.config.productionTip = false

Vue.prototype.$http = instance;
Vue.prototype.$http_ignore_auth = ignore_auth_instance;
Vue.prototype.requestUrl = requestUrl;

Vue.prototype.ask_lodash = _lodash;
Vue.prototype._isLiBang = isLiBang;

// 文件类型图标
Vue.prototype.askSetSrc = (type) => {
  if (type === 'mp4' || type === 'MP4' || type === 'mov' || type === 'MOV' || type == 'avi' || type == 'flv') {
    return 'video'
  } else if (type === 'jpg' || type === 'png' || type === 'JPG' || type === 'PNG' ||
    type === 'jpeg' || type === 'JPEG' || type === 'gif' || type === 'bmp' ||
    type === 'BMP' || type === 'WebP' || type === 'webp' || type === 'svg' || type === 'SVG' || type === 'rgb' || type === 'heic') {
    return 'image'
  } else if (type === 'doc' || type === 'docx' || (type && type.includes('文档'))) {
    return 'word'
  } else if (type === 'pdf') {
    return 'pdf'
  } else if (type === 'ppt' || type === 'pptx') {
    return 'ppt'
  } else if (type === 'xlsx' || type === 'xls') {
    return 'excel'
  } else if (type === 'txt') {
    return 'txt'
  } else if (type === 'mp3' || type === 'MP3') {
    return 'audio'
  } else {
    return type
  }
}

Vue.use(Dropdown);
Vue.use(Select);
Vue.use(Tooltip);
Vue.use(Link);
Vue.use(Avatar);
Vue.use(Badge);
Vue.use(Input);
Vue.use(Drawer);
Vue.use(Tree);
Vue.use(CKEditor)
/* 路由发生变化修改页面title */
// router.beforeEach((to, from, next) => {
//   if (to.meta.title) {
//     document.title = to.meta.title
//   }
//   next()
// })
Vue.prototype.isUseOpenData = isUseOpenData
Vue.prototype.isAskLight = isAskLightning
Date.prototype.Format = function (fmt) { //author: meizz
    var o = {
        "M+": this.getMonth() + 1, //月份
        "d+": this.getDate(), //日
        "h+": this.getHours(), //小时
        "H+": this.getHours(), //小时
        "m+": this.getMinutes(), //分
        "s+": this.getSeconds(), //秒
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度
        "S": this.getMilliseconds() //毫秒
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
}
new Vue({
    router,
    store,
    i18n,
  render: h => h(App)
}).$mount('#app')
