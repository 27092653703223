<template>
  <div class="version-manage">
    <template v-if="!openSubsection">
      <div class="preview-content" v-if="isReadStatus" :class="isShowKnowledgeDetail ? 'preview-content-width1' : 'preview-content-width2'">
        <!-- 当前版本 -->
        <div id="now-version" :class="['now-version', isShowHistoryVersion ? 'show-history-version' : '']">
          <div class="document-header" v-if="isShowHistoryVersion">
            <div class="document-header-left">
              <img class="file-icon" :src="nowVersionDetail.fileIcon" alt="" srcset="">
              <span class="document-name one-column-ellipsis">{{ nowVersionDetail.name }}</span>
              <div class="now-version-tips" v-if="pageType === 'lookVersion'">{{ $t('versionManage.currentVersion') }}</div>
            </div>
            <div class="version-num" v-if="pageType === 'lookVersion'">V{{ nowVersionDetail.version }}</div>
          </div>
          <knowledge-preview v-if="drawerDocNow" ref="KnowledgePreview" :knowledgeDetail="nowVersionDetail"
                             :fileType="fileType" :src="nowVersionPreviewUrl" :isShowHistoryVersion="isShowHistoryVersion"
                             @closeSummary="closeSummary" :editType="editType"></knowledge-preview>
          <FilePreview v-if="false" ref="filePreview" :url="nowVersionPreviewUrl" :knowledgeItem="nowVersionDetail"
                       :fileType="fileType" :hiddenToolbar="true">
          </FilePreview>
        </div>
        <!-- 历史版本 -->
        <div class="history-version" v-if="isShowHistoryVersion">
          <div class="document-header">
            <div class="document-header-left">
              <img width="16px" :src="historyVersionDetail.fileIcon" alt="" srcset="">
              <span class="document-name one-column-ellipsis">{{ historyVersionDetail.name }}</span>
            </div>
            <div class="document-header-right">
              <div class="version-num">V{{ historyVersionDetail.version }}</div>
              <div class="use-history" @click="useThisVersion">{{ $t('versionManage.userVersion') }}</div>
            </div>
          </div>
          <knowledge-preview v-if="drawerDocHistory" ref="KnowledgePreview" :knowledgeDetail="nowVersionDetail"
                             :fileType="fileType" :src="historyVersionPreviewUrl" :isShowHistoryVersion="isShowHistoryVersion"
                             @closeSummary="closeSummary"></knowledge-preview>
          <FilePreview v-if="false" ref="filePreviewHistory" :url="historyVersionPreviewUrl"
                       :knowledgeItem="historyVersionDetail" :fileType="fileType" :hiddenToolbar="true">
          </FilePreview>
        </div>
      </div>
      <div class="knowledgeDetail" v-if="isReadStatus">
        <div class="knowledgeDetail-content" v-if="isShowKnowledgeDetail">
          <folder-details :isVersionManage="true" :currentNode="nowVersionDetail" @editFolder="editFolder"
                          @moveTo="editFolder" @deleteDetail="deleteDetail" @changeSafe="changeSafe" ref="folderDetails">
          </folder-details>
        </div>
        <div class="switch-page-type">
          <el-tooltip class="item" effect="dark" :content="$t('versionManage.infomation')" placement="left-start">
            <div :class="['look-base-set', pageType === 'lookBaseSet' ? 'active' : '']"
                 @click.stop="switchPageType('lookBaseSet')">
              <i class="iconfont guoran-xiangqing_shuoming"></i>
            </div>
          </el-tooltip>
          <el-popover placement="left" width="270" :visible-arrow="false" popper-class="version-list"
                      v-model="visible" trigger="manual">
            <div class="version-item" v-for="item in versioHistoryList" :key="item.id">
              <div class="version-item-day">{{ item.timelineTitle }}</div>
              <div :class="['version-item-content', cellIndex + 1 === item.list.length ? 'version-item-content-last' : '']"
                   v-for="(cell, cellIndex) in item.list" :key="cellIndex" @click="chooseHistoryVersion(cell)">
                <div class="version-item-time">{{ cell.time }}</div>
                <span v-if="cell.eventType === 'create'" class="version-item-container">{{$t('versionManage.addKnowledge')}}</span>
                <template v-else>
                  <div class="version-item-container" v-if="cell.userName.length > 10">
                    <div class="version-item-content-wrapper">
                      <div class="version-item-box">
                        <span class="version-item-icon-1">{{$t('versionManage.updateKnowledge')}}</span>
                        <span class="version-item-user-name">{{ cell.userName }}</span>
                        <span class="version-item-icon-2">{{$t('versionManage.updateKnowledge')}}</span>
                      </div>
                    </div>
                  </div>
                  <div v-else class="version-item-container">
                    <span>{{ cell.userName }}{{$t('versionManage.updateKnowledge')}}</span>
                  </div>
                </template>
                <div class="version-item-version-text">V{{ cell.version }}</div>
              </div>
            </div>
            <div slot="reference">
              <el-tooltip effect="dark" :content="$t('versionManage.historyVersion')" placement="left">
                <div :class="['look-version', pageType === 'lookVersion' ? 'active' : '']"
                     @click.stop="switchPageType('lookVersion')">
                  <i class="iconfont guoran-lishibanben"></i>
                  <div class="new-dage" v-if="isHaveNewHistory">NEW</div>
                </div>
              </el-tooltip>
            </div>
          </el-popover>
          <el-tooltip class="item" effect="dark" :content="$t('versionManage.subsection.title')" placement="left-start">
            <div :class="['subsection-btn', pageType === 'subsection' ? 'active' : '']"
                 @click.stop="switchPageType('subsection')"
                 v-show="hasManagement() && (parseInfoCount >=1 || loadingKnowledge) &&  documentCaseType() && nowVersionDetail.progress && nowVersionDetail.progress.finished"
            >
              <i class="iconfont guoran-cunchukongjian"></i>
            </div>
          </el-tooltip>
          <template v-if="hasManagement() && parseInfoCount == 0 && documentCaseType()  && !loadingKnowledge &&  ($route.query.fileSuffix === 'doc' || $route.query.fileSuffix === 'docx' || $route.query.fileSuffix === 'pdf' || $route.query.fileSuffix === 'txt') && nowVersionDetail.progress && nowVersionDetail.progress.finished">
            <el-popover
                placement="right"
                width="300"
                trigger="click"
                v-model="subsectionPopover"
            >
              <div class="subsection-container">
                <div class="tips">当前知识分段可使用新的分段逻辑，是否切换新分段？</div>
                <div class="btn-list">
                  <div class="later-btn" @click="subsectionPopover = false">稍后切换</div>
                  <div class="immediately-btn" @click.stop="switchPageType('subsection')">立即切换</div>
                </div>
              </div>
              <div :class="['subsection-btn', pageType === 'subsection' ? 'active' : '']"
                   slot="reference">
                <i class="iconfont guoran-cunchukongjian"></i>
              </div>
            </el-popover>
          </template>

        </div>
      </div>
      <web-office-page
          v-if="!isReadStatus"
          :officeType="officeType"
          :fileId="nowVersionDetail.id"
          :currentVersion="currentVersion"
          :editType="editType"
          :knowledgeDetail="nowVersionDetail"
          :previousVersion="previousVersion"
          @updatePreviousVersion="getPreviousDocument"
      >

      </web-office-page>

      <div class="folder-management">
        <folder-management :corpId="corpId" :currentNode="nowVersionDetail" :isVersionManage="true" ref="folderManagement"
                           @getKnowledge="getKnowledge">
        </folder-management>
      </div>
    </template>
    <template v-else>
      <div class="subsectionDetail">
        <subsection-com :ossPath="nowVersionPreviewUrl" ref="subsectionCom"></subsection-com>
        <div class="switch-page-type">
          <el-tooltip class="item" effect="dark" :content="$t('versionManage.infomation')" placement="left-start">
            <div :class="['look-base-set', pageType === 'lookBaseSet' ? 'active' : '']"
                 @click.stop="switchPageType('lookBaseSet')">
              <i class="iconfont guoran-xiangqing_shuoming"></i>
            </div>
          </el-tooltip>
          <el-popover placement="left" width="270" :visible-arrow="false" popper-class="version-list"
                      v-model="visible" trigger="manual">
            <div class="version-item" v-for="item in versioHistoryList" :key="item.id">
              <div class="version-item-day">{{ item.timelineTitle }}</div>
              <div :class="['version-item-content', cellIndex + 1 === item.list.length ? 'version-item-content-last' : '']"
                   v-for="(cell, cellIndex) in item.list" :key="cellIndex" @click="chooseHistoryVersion(cell)">
                <div class="version-item-time">{{ cell.time }}</div>
                <span v-if="cell.eventType === 'create'" class="version-item-container">{{$t('versionManage.addKnowledge')}}</span>
                <template v-else>
                  <div class="version-item-container" v-if="cell.userName.length > 10">
                    <div class="version-item-content-wrapper">
                      <div class="version-item-box">
                        <span class="version-item-icon-1">{{$t('versionManage.updateKnowledge')}}</span>
                        <span class="version-item-user-name">{{ cell.userName }}</span>
                        <span class="version-item-icon-2">{{$t('versionManage.updateKnowledge')}}</span>
                      </div>
                    </div>
                  </div>
                  <div v-else class="version-item-container">
                    <span>{{ cell.userName }}{{$t('versionManage.updateKnowledge')}}</span>
                  </div>
                </template>
                <div class="version-item-version-text">V{{ cell.version }}</div>
              </div>
            </div>
            <div slot="reference">
              <el-tooltip effect="dark" :content="$t('versionManage.historyVersion')" placement="left">
                <div :class="['look-version', pageType === 'lookVersion' ? 'active' : '']"
                     @click.stop="switchPageType('lookVersion')">
                  <i class="iconfont guoran-lishibanben"></i>
                  <div class="new-dage" v-if="isHaveNewHistory">NEW</div>
                </div>
              </el-tooltip>
            </div>
          </el-popover>
          <el-tooltip class="item" effect="dark" :content="$t('versionManage.subsection.title')" placement="left-start">
            <div :class="['subsection-btn', pageType === 'subsection' ? 'active' : '']"
                 @click.stop="switchPageType('subsection')"
                 v-show="hasManagement() && parseInfoCount >= 1 && documentCaseType() && nowVersionDetail.progress && nowVersionDetail.progress.finished"
            >
              <i class="iconfont guoran-cunchukongjian"></i>
            </div>
          </el-tooltip>
          <template v-if="hasManagement() && parseInfoCount == 0 && documentCaseType() && nowVersionDetail.progress && nowVersionDetail.progress.finished">
            <el-popover
                placement="right"
                width="300"
                trigger="click"
                v-model="subsectionPopover"
            >
              <div class="subsection-container">
                <div class="tips">当前知识分段可使用新的分段逻辑，是否切换新分段？</div>
                <div class="btn-list">
                  <div class="later-btn" @click="subsectionPopover = false">稍后切换</div>
                  <div class="immediately-btn" @click.stop="switchPageType('subsection')">立即切换</div>
                </div>
              </div>
              <div :class="['subsection-btn', pageType === 'subsection' ? 'active' : '']"
                   slot="reference">
                <i class="iconfont guoran-cunchukongjian"></i>
              </div>
            </el-popover>
          </template>
        </div>
      </div>
    </template>

    <delete-dialog @closeEvent="deleteFileDialog = false" v-if="deleteFileDialog">
      <div slot="popup-container" class="popup-container">
        <div class="delete-title">删除文件</div>
        <div class="prompt-text">
          是否确认删除该文件？
        </div>
      </div>
      <div slot="dialog-footer">
        <el-button @click="deleteFileDialog = false" round size="small" plain class="cancel-btn">取 消</el-button>
        <el-button @click="deleteFile" style="background-color: #FE5965 ;color: white;border:none;" size="small"
          class="confirm-btn">删 除
        </el-button>
      </div>
    </delete-dialog>
    <delete-dialog @closeEvent="revokeApprovalDialog = false" v-if="revokeApprovalDialog">
      <div slot="popup-container" class="popup-container">
        <div class="delete-title">撤销审批</div>
        <div class="prompt-text">
          已提交 {{ revokeApprovalType | typeFilterTitle }} 审批
        </div>
      </div>
      <div slot="dialog-footer">
        <el-button @click="revokeApprovalDialog = false" round size="small" plain class="cancel-btn">取 消</el-button>
        <el-button @click="revokeApproval" style="background-color: #FE5965 ;color: white;border:none;" size="small"
          class="confirm-btn">撤销
        </el-button>
      </div>
    </delete-dialog>
    <popup-com @closeEvent="backPopup = false" v-if="backPopup">
      <div slot="popup-name">{{ $t('versionManage.subsection.notSave') }}</div>
      <div slot="dialog-footer">
        <el-button  plain size="small" @click="backPopup = false" class="cancel-btn">{{ $t('knowledgeManagement.folderManagement.cancel') }}</el-button>
        <el-button  type="primary" size="small" @click="saveBack('no')"  class="border-btn">{{ $t('versionManage.subsection.noSaveBtn') }}</el-button>
        <el-button  type="primary" size="small" @click="saveBack('save')"  class="confirm-btn">{{ $t('versionManage.subsection.saveBtn') }}</el-button>
      </div>
    </popup-com>
    <popup-com @closeEvent="changePopup = false" v-if="changePopup">
      <div slot="popup-name">{{ $t('versionManage.subsection.notSave') }}</div>
      <div slot="dialog-footer">
        <el-button  plain size="small" @click="changePopup = false" class="cancel-btn">{{ $t('knowledgeManagement.folderManagement.cancel') }}</el-button>
        <el-button  type="primary" size="small" @click="saveChange('no')"  class="border-btn">{{ $t('versionManage.subsection.noSaveChangeBtn') }}</el-button>
        <el-button  type="primary" size="small" @click="saveChange('save')"  class="confirm-btn">{{ $t('versionManage.subsection.saveChangeBtn') }}</el-button>
      </div>
    </popup-com>
  </div>
</template>

<script>
import FolderDetails from "@/views/knowledgeManagement/folderDetails";
import FolderManagement from "@/views/knowledgeManagement/folderManagement";
import FilePreview from "@/components/FilePreview";
import formatDoc from "../../utils/formatDocList";
import { requestUrl } from "@/http/requestUrl";
import KnowledgePreview from "@/components/knowledgePreview/knowledgePreview";
import { getFileType } from "@/components/preview/utils/url";
import { mapGetters, mapActions } from 'vuex';
import DeleteDialog from "@/components/deleteDialog";
import WebOfficePage from "@/views/versionManage/webOfficePage";
import SubsectionCom from "@/views/versionManage/components/subsection";
import PopupCom from "@/components/popup";
import { setTimestamp } from '@/assets/js/common';

export default {
  data () {
    return {
      documentId: '',
      isShowKnowledgeDetail: true,
      pageType: "lookBaseSet", // lookVersion
      corpId: "",
      channel: 0,
      versioHistoryList: [],
      isShowHistoryVersion: false, // 是否展示历史版本
      nowVersionDetail: {}, // 当前版本信息
      nowVersionPreviewUrl: "", // 当前版本的预览地址
      historyVersionDetail: {}, // 历史版本信息
      historyVersionPreviewUrl: "", // 历史版本的预览地址
      drawerDocNow: false,
      drawerDocHistory: false,
      visible: false,
      isHaveNewHistory: false, // 是否有新的历史版本
      fileType: '',
      previewUrl: "",
      deleteFileDialog: false,
      textWatermarkStr: "",
      isDownload: false,
      knowledgeId: "",
      revokeApprovalDialog:false,
      revokeApprovalType:'',
      officeType:"",
      editType:"",
      previousVersion:null,
      currentVersion:1,
      maxVersion:1,
      openSubsection:false,
      backPopup:false,
      changePopup:false,
      changeType:"",
      parseInfoCount:0,
      subsectionPopover:false,
      knowledgeProcessTimer:null,
      knowledgeLearning:true,
      learningFail:false,
      loadingKnowledge:false,
      deleteCallback: null
    }
  },
  components: {
    PopupCom,
    SubsectionCom,
    WebOfficePage,
    DeleteDialog,
    KnowledgePreview,
    FolderDetails,
    FolderManagement,
    FilePreview
  },
  computed: {
    ...mapGetters('knowledge', {
      knowledgeInfo: 'getKnowledge'
    }),
    ...mapGetters('knowledge',{
      isReadStatus:"getDocumentStatus"
    }),
  },
  filters:{
    typeFilterTitle(value) {
      switch (value) {
        case 'KNOWLEDGE_PUBLISH':
            return '发布知识'
        case 'KNOWLEDGE_CANCEL_PUBLISH':
            return '取消发布知识'
        case 'KNOWLEDGE_ARCHIVE':
            return '归档知识'
        case 'KNOWLEDGE_CANCEL_ARCHIVE':
            return '取消归档知识'
        default:
            return '';
      }
    },
  },
  mounted () {
    this.documentId = this.$route.query.id;
    this.knowledgeId = this.$route.query.knowledgeId;
    this.getParseInfoCount();
    this.getTextWatermark();
    // this.fileType = this.$route.query.fileType;

    this.corpId = sessionStorage.getItem('_corpId')
    this.isShowKnowledgeDetail = true;
    this.selectDocumentVersionHaveNew();
    this.getMaxVersion();
    this.updateOpenSubsection(false);
    // this.getKnowledgeDetail();

  },
  beforeDestroy () {
    document.onclick = null;
    let office = document.getElementsByClassName('web-office-default-container');
    if (office){
      if (typeof (office) === 'object'){
        Array.from(office).forEach(item => {
          item.style.display = 'none';
        })
      } else {
        office.style.display = 'none';
      }
    }
    this.updateDocumentStatus(true);
    this.updatePreviousEditVersion(null);
    this.knowledgeInfo.currentVersion = this.nowVersionDetail.version;
    this.updateKnowledge(this.knowledgeInfo);
  },
  methods: {
    ...mapActions('knowledge', [
      'updateKnowledge'
    ]),
    ...mapActions('knowledge', [
      'updateDownLoadBtn'
    ]),
    ...mapActions('knowledge', [
      'updateTextWatermarkStr'
    ]),
    ...mapActions('knowledge', [
      'updateDocumentStatus'
    ]),
    ...mapActions('knowledge', [
      'updatePreviousEditVersion'
    ]),
    ...mapActions('knowledge',[
        'updateOpenSubsection'
    ]),
    async openWebOffice(type){
      this.editType = type;
      if (type === 'copyNewVersion'){
        let url = '/tag-api/v3/3rd/files/' + this.documentId + '/upgrade-version'
        url += '?current_version=' + this.currentVersion + '&user_id=' + sessionStorage.getItem('_uid');
        await this.$http.post(url).then(res => {
          if (res.data.code == 0){
            this.knowledgeInfo.currentVersion = res.data.data.newVersion;
            this.currentVersion = res.data.data.newVersion;
            this.updateKnowledge(this.knowledgeInfo)
          }
        })
      } else if (type === 'previous'){
        this.knowledgeInfo.currentVersion = this.previousVersion.version;
        this.currentVersion = this.previousVersion.version;
        this.updateKnowledge(this.knowledgeInfo)
      }

      const userId = sessionStorage.getItem('_uid')
      let officeType;
      const fileExtension = this.nowVersionDetail.fileSuffix
      console.log("userId,fileExtension:",userId,fileExtension,this.nowVersionDetail)
      if (fileExtension === 'doc' || fileExtension === 'docx' || fileExtension === 'txt') {
          officeType = 'Writer';
      } else if (fileExtension === 'xls' || fileExtension === 'xlsx') {
          officeType = 'Spreadsheet';
      } else if (fileExtension === 'ppt' || fileExtension === 'pptx') {
          officeType = 'Presentation';
      } else if (fileExtension === 'pdf' || fileExtension === 'ofd') {
          officeType = 'Pdf';
      } else {
        this.$message({
          message: '该类型文档暂不支持编辑',
          type: 'warning'
        });
        return
      }
      this.officeType = officeType;
      this.updateDocumentStatus(false)
      // 构建 URL 并在新窗口打开
      // const url = this.$router.resolve({
      //     name: 'WebOfficePage',
      //     query: {
      //         officeType: officeType,
      //         fileId: this.documentId,
      //         userId: userId,
      //         currentVersion: this.currentVersion,
      //       previousVersion:this.previousVersion.version,
      //       editType:this.editType
      //     },
      // }).href;
      //
      // window.open(url, '_blank');  // 在新页面中打开
    },
    documentCaseType() {
        let type = this.$route.query.fileSuffix;
        let caseType = type.toLowerCase();
        let arr = ['doc', 'docx', 'pdf', 'txt'];
        return arr.includes(caseType);
    },
    async changeReadStatus(){
      let office = document.getElementsByClassName('web-office-default-container');
      if (office){
        if (typeof (office) === 'object'){
          Array.from(office).forEach(item => {
            item.style.display = 'none';
          })
        } else {
          office.style.display = 'none';
        }
      }
      let url = '/tag-api/v3/3rd/getDocumentMaxVersion?id=' + this.documentId;
      if (this.editType === 'copyNewVersion'){
        await this.$http.get(url).then(res => {
          console.log('getDocumentMaxVersion',res)
          if (res.data.data){
            this.$http.get(requestUrl.docVersion.useDocumentVersionByDocId + '?docId=' + this.documentId + '&version=' + res.data.data).then(res => {
              if (res.data.code == 0) {
                this.getKnowledgeDetail();
              }
            })
          }
        })
      } else {
        if (this.editType === 'previous'){
          await this.$http.get(requestUrl.docVersion.useDocumentVersionByDocId + '?docId=' + this.documentId + '&version=' + this.previousVersion.version).then(res => {
            if (res.data.code == 0) {
              this.getKnowledgeDetail();
            }
          })
        } else {
          setTimeout(() => {
            this.getKnowledgeDetail();
          },500)
        }
      }
    },
    getMaxVersion(){
      let url = '/tag-api/v3/3rd/getDocumentMaxVersion?id=' + this.documentId;
      this.$http.get(url).then(res => {
        console.log('getDocumentMaxVersion',res)
        if (res.data.data){
          this.maxVersion = res.data.data;
        }
      })
    },
    selectDocumentVersionHaveNew () {
      this.$http.get(requestUrl.docVersion.selectDocumentVersionHaveNew + '?docId=' + this.documentId).then(res => {
        if (res.data.code == 0) {
          this.isHaveNewHistory = res.data.data;
        }
      })
    },
    statusFilter(val){
      if (!val || val === 'UNPUBLISHED'){
        return "取消发布"
      } else if (val === 'PUBLISH'){
        return  "发布"
      } else {
        return "归档"
      }
    },
    // 获取当前知识的详情
    getKnowledgeDetail () {
      this.drawerDocNow = false;
      this.$http.get(requestUrl.knowledgeBase.getKnowledgeBaseById + '?id=' + this.documentId).then(async res => {
        if (res.data.code == 0 && res.data.data) {
          res.data.data.fileIcon = formatDoc.formatFileTypeSrc({ name: res.data.data.name + '.' + res.data.data.fileSuffix })
          this.nowVersionDetail = res.data.data;
          this.historyVersionDetail = res.data.data;
          this.fileType = await getFileType(this.nowVersionDetail.ossPath);
          this.getPreviewContent(this.nowVersionDetail.ossPath, 'nowVersion');
          this.updateKnowledgeInfo(res.data.data);
          this.getPreviousDocument();
        }
      })
    },
    getKnowledgeProcess(){
      this.$http.get(requestUrl.knowledgeBase.getKnowledgeBaseById + '?id=' + this.documentId).then(res => {
        if (res.data.code == 0 && res.data.data){
          if (res.data.data.progress){
            this.knowledgeLearning = res.data.data.progress.finished;
            this.learningFail = res.data.data.progress.failed;
            if (this.knowledgeLearning){
              this.getParseInfoCount();
            }
          }
          if (!this.knowledgeLearning && !this.learningFail){
            this.knowledgeProcessTimer = setTimeout(() => {
              if (this.knowledgeProcessTimer) {
                clearTimeout(this.knowledgeProcessTimer)
                this.knowledgeProcessTimer = null;
              }
              this.getKnowledgeProcess()
            }, 10000);
          } else{
            this.loadingKnowledge = false;
            if (this.knowledgeProcessTimer){
              clearTimeout(this.knowledgeProcessTimer)
              this.knowledgeProcessTimer = null;
            }
          }
        }
      })
    },
    updateKnowledgeInfo (data) {
      this.knowledgeInfo.currentVersion = data.version;
      this.currentVersion = data.version;
      if (data.currentVersion) {
        this.knowledgeInfo.status = data.currentVersion.state
        this.knowledgeInfo.pendingApproval = data.currentVersion.pendingApproval
      } else {
            this.knowledgeInfo.status = "UNPUBLISHED"
          }
      if (data.managePermission) {
        let managePermission = JSON.parse(data.managePermission)
        this.knowledgeInfo.managePermission = managePermission.map(res => {
          return Number(res.id)
        });
      } else {
        this.knowledgeInfo.managePermission = null
      }
      if (data.editPermission){
        let editPermission = JSON.parse(data.editPermission)
        this.knowledgeInfo.editPermission = editPermission.map(res => {
          return Number(res.id)
        });
      } else {
        this.knowledgeInfo.editPermission = null
      }
      this.knowledgeInfo.previousPubVersion = data.previousPubVersion
      console.log('knowledgeInfo', this.knowledgeInfo)
      this.updateKnowledge(this.knowledgeInfo);
    },
    // 查询文档的历史版本
    getVersionHistory () {
      this.$http.get(requestUrl.docVersion.selectDocumentVersionByDocId + '?docId=' + this.documentId).then(res => {
        if (res.data.code == 0) {
          let currentList = formatDoc.formatDocList(res.data.data);
          this.versioHistoryList = currentList;
          this.hiddeenProver()
          // document.addEventListener('click', this.hiddeenProver);
          this.selectDocumentVersionHaveNew();
        }
      })
    },
    /**
    * 右侧详情块点击的编辑/移动
    * */
    async editFolder (command, node) {
      let url = requestUrl.knowledgeBase.getKnowledgeBaseById + '?id=' + node.parentId;
      await this.$http.get(url).then(async res => {
        if (res.data.code == 0 && res.data.data) {
          this.$refs.folderManagement.nodeParentData = await res.data.data;
        }
      })
      this.$refs.folderManagement.nodeData = node;
      this.$refs.folderManagement.handleCommand(command, '', '', 'detail-edit');
    },
    changeSafe () {
      this.getTextWatermark();
      this.openSubsection = false;
      this.$nextTick(() => {
        this.drawerDocNow = false;
        setTimeout(() => {
          this.drawerDocNow = true;
        }, 100)
      })
    },
    // 切换基础信息和版本信息
    switchPageType (type) {
      this.changeType = type;
      this.visible = false ;
      let bool = false;
      this.subsectionPopover = false;
      if (type !== 'subsection'){
        if (this.openSubsection){
          if (JSON.stringify(this.$refs.subsectionCom.split_paragraphs) === this.$refs.subsectionCom.split_paragraphs_copy){
            bool = false
          } else {
            bool = true;
          }
        } else {
          bool = false
        }
      }
      if (bool){
        this.changePopup = true;
        return
      }
      this.openSubsection = false;
      this.updateOpenSubsection(false)
      if (type === 'lookVersion') {
        this.pageType = type;
        this.$nextTick(() => {
          this.visible = true;
        })
        this.getVersionHistory();
      } else if (type === 'lookBaseSet'){
        this.pageType = type;
        this.drawerDocNow = false;
        this.isShowKnowledgeDetail = true;
        this.isShowHistoryVersion = false;
        setTimeout(() => {
          this.drawerDocNow = true;
        })
      } else if (type === 'subsection'){
        if (this.loadingKnowledge && !this.knowledgeLearning){
          this.$message({
            message:"知识学习中，该过程可能需要一些时间，请稍后再试",
            type:"warning"
          })
          return;
        }
        if (this.parseInfoCount == 0){
          let url = requestUrl.knowledgeBase.relearnKnowledge + this.$route.query.knowledgeId;
          this.$http.post(url).then(res => {
            if (res.status == 200) {
              this.$message({
                message:"切换成功，知识学习中，该过程可能需要一些时间，请稍后再试",
                type:"success"
              })
              this.changeType = "lookBaseSet";
              this.pageType = "lookBaseSet";
              this.loadingKnowledge = true;
              this.getKnowledgeProcess();
              // this.openSubsection = true;
              // this.updateOpenSubsection(true)
            }
          })
        } else {
          this.pageType = type;
          this.openSubsection = true;
          this.updateOpenSubsection(true)
        }
      }
    },
    async saveChange(type){
      if (type !== 'no'){
        await this.$refs.subsectionCom.saveParse();
      }
      this.changePopup = false;
      this.pageType = this.changeType;
      this.openSubsection = false;
      this.updateOpenSubsection(false)
      if (this.changeType === 'lookVersion') {
        this.$nextTick(() => {
          this.visible = true;
        })
        this.getVersionHistory();
      } else if (this.changeType === 'lookBaseSet'){
        this.drawerDocNow = false;
        this.isShowKnowledgeDetail = true;
        this.isShowHistoryVersion = false;
        setTimeout(() => {
          this.drawerDocNow = true;
        })
      }
    },
    // 编辑知识后,刷新知识详情
    getKnowledge () {
      this.getKnowledgeDetail();
    },
    /**
     * 展示详情更多按钮中删除操作
     * */
    folderDetailHandle (command) {
      if (command === 'del') {
        this.$refs.folderManagement.handleCommand('del')
      }
    },
    // 删除知识成功后
    deleteDetail () {
      if (this.deleteCallback) {
        this.deleteCallback();
      } else {
        this.$router.go(-1);
      }
    },
    chooseHistoryVersion (cell) {
      this.visible = false;
      if (this.nowVersionDetail.version === cell.version) {
        this.messageFn('warning', '已处于当前版本');
        return false
      }
      this.drawerDocHistory = false;
      this.drawerDocNow = false;
      let obj = JSON.parse(JSON.stringify(this.historyVersionDetail))
      this.historyVersionDetail = Object.assign(obj, cell)
      this.isShowHistoryVersion = true;
      this.getPreviewContent(cell.ossPath, 'historyVersion');
      this.isShowKnowledgeDetail = false;
      this.openSubsection = false;
      this.updateOpenSubsection(false)
    },
    loadIframe () {
      let iframe = document.getElementsByClassName('preview_iframe')[0];
      console.log(iframe, 'iframe');
      iframe.onload(() => {
        iframe.contentWindow.postMessage({
          type: "hiddenToolbar",
        }, '*')
      })
    },
    // 获取文件的预览地址
    getPreviewContent (ossPath, type) {
      let url = requestUrl.preview.previewUrl + '?isVersionManage=1'
      console.log('fileType', this.fileType)
      if (this.fileType === 'VIDEO' || this.fileType === 'PDF' || this.fileType === 'EXCEL' || this.fileType === 'TXT' || this.fileType === 'DOC') {
        url += '&needEncrypt=false'
      } else {
        url += '&needEncrypt=true'
      }
      // if (ossPath.indexOf('uploadId') !== -1){
      //   ossPath += '&timestamp=' + new Date().getTime();
      // } else {
      //   ossPath += '?timestamp=' + new Date().getTime();
      // }
      ossPath = setTimestamp(ossPath)
      // ossPath = 'http://guoranwisdom.oss-cn-zhangjiakou.aliyuncs.com/ab0f90737c8b4f2d85ba2157e4473110/2024/08/30/12/46/e9d86f11dafad02e0a0ccdd599b7dc9a/v10/地铁运营线路.docx'
      this.$http.post(url, { fileInOssPath: ossPath }).then(res => {
        if (res.data.code == 0) {
          if (type === 'nowVersion') {
            this.nowVersionPreviewUrl = res.data.data;
            this.previewUrl = res.data.data;
            this.drawerDocNow = true;
            this.drawerDocHistory = false;
            this.$nextTick(() => {
              if (this.fileType === 'HTML') {
                this.$refs.KnowledgePreview.$refs.filePreview.getPreviewContent(this.nowVersionDetail.ossPath);
              }
            })
          } else {
            this.historyVersionPreviewUrl = res.data.data;
            this.previewUrl = res.data.data;
            this.drawerDocNow = true;
            this.drawerDocHistory = true;
            this.$nextTick(() => {
              if (this.fileType === 'HTML') {
                this.$refs.KnowledgePreview.$refs.filePreview.getPreviewContent(this.historyVersionDetail.ossPath);
                // this.$refs.filePreviewHistory.getPreviewContent(this.historyVersionDetail.ossPath);
              }
            })
          }
        }
      })
    },
    // 使用此版本
    useThisVersion () {
      this.editType = '';
      this.$http.get(requestUrl.docVersion.useDocumentVersionByDocId + '?docId=' + this.documentId + '&version=' + this.historyVersionDetail.version).then(res => {
        if (res.data.code == 0) {
          this.messageFn('success', this.$t('versionManage.updateDoc'));
          this.getKnowledgeDetail();
          this.isShowHistoryVersion = false;
        }
      })
    },
    // 点击prover元素时绑定click事件,以此来实现点击除prover元素之外的区域隐藏prover
    hiddeenProver () {
      document.querySelector(".version-list").onclick = (e) => {
        console.log(e);
        e.stopPropagation ? e.stopPropagation() : e.cancelBubble = true;
        if (e.target.className.indexOf('version-item') == -1) {
          this.visible = true;
        }
      }
      document.onclick = () => {
        this.visible = false;
      }
    },
    // 获取是否存在发布，归档，取消发布时需要审批的自动化节点
    getAutomatedNode (eventType) {
      return new Promise((reslove, resject) => {
        this.$http(requestUrl.automation.getNodeCheck + '?type=APPROVAL' + '&eventType=' + eventType + '&corpKnowledgeId=' + this.documentId).then(res => {
          reslove(res.data)
        }).catch(() => {
          resject(false)
        })
      })
    },
    // 审批校验
    verifyIfAutomationIsTriggered (type) {
      this.$emit('getApprovalLoading', true)
      // 该操作需要审批，是否发布/归档/取消发布/取消归档？
      let typeObject = {
        'PUBLISHED': {
          label: '发布',
          fn: 'publishKnowledge',
          eventType: 'KNOWLEDGE_PUBLISH'
        },
        'UNPUBLISHED': {
          label: '取消发布',
          fn: 'unPublishKnowledge',
          eventType: 'KNOWLEDGE_CANCEL_PUBLISH'
        },
        'ARCHIVE': {
          label: '归档',
          fn: 'archiveKnowledge',
          eventType: 'KNOWLEDGE_ARCHIVE'
        },
        'UNARCHIVE': {
          label: '取消归档',
          fn: 'archiveKnowledge',
          eventType: 'KNOWLEDGE_CANCEL_ARCHIVE'
        },
      }
      // 立时主体不需要校验审批流程
      if(this._isLiBang()) {
        this[typeObject[type].fn](false, type)
        this.$emit('getApprovalLoading', false)
      } else {
        this.getAutomatedNode(typeObject[type].eventType).then(flag => {
          this.$emit('getApprovalLoading', false)
          if (flag) {
            let str = '该操作需要审批，是否' + typeObject[type].label
            this.$emit('sureApprovalStatus', true)
            this.$confirm(str + '?', '审批', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.$emit('sureApprovalStatus', false)
              this[typeObject[type].fn](true, type)
            }).catch(() => {
              this.$emit('sureApprovalStatus', false)
            });
          } else {
            this[typeObject[type].fn](false, type)
          }
        }).catch(() => {
          this.$message.error('操作异常，请稍后再试！')
          this.$emit('getApprovalLoading', false)
        })
      }
    },
    revokeApproval() {
      let url = requestUrl.automation.cancelApproval + `?corpKnowledgeId=${this.nowVersionDetail.id}&version=${this.nowVersionDetail.version}&check=false`
      this.$http.put(url).then(res => {
        if (res.data.code == 0) {
          this.$message.success("取消审批成功")
          this.revokeApprovalDialog = false
          this.getKnowledgeDetail()
          this.$emit('sureApprovalStatus', false)
        }
      })
    },
    // 取消审批，获取审批详情
    cancelApproval () {
      let url = requestUrl.automation.cancelApproval + `?corpKnowledgeId=${this.nowVersionDetail.id}&version=${this.nowVersionDetail.version}&check=true`
      this.$http.put(url).then(res => {
        if (res.data.code == 0) {
          this.revokeApprovalType = res.data.data.type
          this.revokeApprovalDialog = true
        }
      })
    },
    //发布知识
    saveOrPublish (type) {
      this.verifyIfAutomationIsTriggered(type)
    },
    publishKnowledge (needApproval) {
      let url = requestUrl.knowledgeBase.publishKnowledge + '?publish=true';
      let id = this.$route.query.id
      // 需要校验审批流程，点击审批确定后直接触发接口
      if (needApproval) {
        url = url + '&triggerAutomationFlow=true'
        this.publishKnowledgeAxios(url, id, needApproval)
        return
      } else {
        this.$confirm(this.$t('versionManage.publishTip') + '?', this.$t('status.release'), {
          confirmButtonText: this.$t('common.confirm'),
          cancelButtonText: this.$t('common.cancel'),
          type: 'warning'
        }).then(() => {
          this.publishKnowledgeAxios(url, id, needApproval)
          this.$emit('sureApprovalStatus', false)
        }).catch(() => {
          this.$emit('sureApprovalStatus', false)
        });
      }
    },
    publishKnowledgeAxios (url, id, needApproval) {
      this.$http.post(url, id, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(res => {
        console.log('res', res)
        if (res.data.code == 0) {
          this.$message({
            message: needApproval ? '提交审批成功，请等待审批通过' : "知识发布成功",
            type: "success"
          })
          // this.updateKnowledgeInfo(res.data.data);
          this.getKnowledgeDetail()
        }
        this.$emit('sureApprovalStatus', false)
      })
    },
    //取消发布知识
    unPublishKnowledge (needApproval) {
      let url = requestUrl.knowledgeBase.publishKnowledge + '?publish=false';
      let id = this.$route.query.id
      // 需要校验审批流程，点击审批确定后直接触发接口
      if (needApproval) {
        url = url + '&triggerAutomationFlow=true'
        this.unPublishKnowledgeAxios(url, id, needApproval)
      } else {
        this.$confirm('是否取消发布知识' + '?', '取消发布', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.unPublishKnowledgeAxios(url, id, needApproval)
          this.$emit('sureApprovalStatus', false)
        }).catch(() => {
          this.$emit('sureApprovalStatus', false)
        });
      }
    },
    unPublishKnowledgeAxios (url, id, needApproval) {
      this.$http.post(url, id, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(res => {
        console.log('res', res)
        if (res.data.code == 0) {
          this.$message({
            message: needApproval ? '提交审批成功，请等待审批通过' : "知识取消发布成功",
            type: "success"
          })
          this.getKnowledgeDetail()
          // this.updateKnowledgeInfo(res.data.data);
        }
        this.$emit('sureApprovalStatus', false)
      })
    },
    // 删除知识
    deleteNews (deleteCallback) {
      console.log('953 删除', deleteCallback)
      this.deleteFileDialog = true;
      this.deleteCallback = deleteCallback;
    },
    //知识归档或者取消归档
    archiveKnowledge (needApproval, type) {
      let url = requestUrl.knowledgeBase.archiveKnowledge;
      let message = "";
      let popupTips = ""
      if (type === 'ARCHIVE') {
        url += '?archive=true';
        message = "知识归档成功";
        popupTips = "是否归档知识"
      } else {
        url += '?archive=false'
        message = "知识取消归档成功";
        popupTips = "是否取消归档知识"
      }
      let id = this.$route.query.id
      if (needApproval) {
        url = url + '&triggerAutomationFlow=true'
        message = '提交审批成功，请等待审批通过'
        this.archiveKnowledgeAxios(url, id, message)
      } else {
        this.$confirm(popupTips + '?', type === 'ARCHIVE' ? '归档' : '取消归档', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.archiveKnowledgeAxios(url, id, message)
          this.$emit('sureApprovalStatus', false)
        }).catch(() => {
          this.$emit('sureApprovalStatus', false)
        });
      }
    },
    archiveKnowledgeAxios (url, id, message) {
      this.$http.post(url, id, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(res => {
        this.$message({
          message,
          type: "success"
        })
        if (res.data.code == 0) {
          this.getKnowledgeDetail()
          // this.updateKnowledgeInfo(res.data.data);
        }
        this.$emit('sureApprovalStatus', false)
      })
    },
    deleteFile () {
      let url = requestUrl.knowledgeBase.moveDeleteFolder
      let obj = {
        deleteNodeIds: [this.$route.query.id],
        newParentId: null
      }
      this.$http.delete(url, {
        data: obj
      }).then(res => {
        if (res.data.code == '0') {
          this.$message({
            message: this.$t('versionManage.deleteMsg'),
            duration: 2000,
            type: "success"
          })
          this.deleteDetail();
        }
      })
    },
    editSummary (bool) {
      this.$refs.KnowledgePreview.showSummary = bool;
      if (bool) {
        this.$refs.KnowledgePreview.goTop = true;
        this.$refs.KnowledgePreview.scrollView();
      }
    },
    closeSummary () {
      this.$emit('closeSummary')
    },
    downLoadUrl () {
      console.log(
        'this.nowVersionDetail: ',
        this.nowVersionDetail
      );
      
      let url = this.nowVersionDetail.ossPath
      if (decodeURIComponent(url) != url) {
        url = decodeURIComponent(url)
      }
      let index = url.lastIndexOf("?")
      if (index !== -1) {
        url = url.substr(0, url.lastIndexOf("?"));
      }
      const fileType = url.substring(url.lastIndexOf('.'));
      this.$http.post('/knowledge-api/temporary-certificate/or-origin?expired=10', url, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then(res => {
        if (res.data) {
          let name = (this.nowVersionDetail.name) + fileType
          if (fileType === '.txt') {
            this.$http.get(res.data).then(txtRes => {
              this.downloadTxtFile(txtRes.data, name)
            })
          } else {
            // let a = document.createElement('a')
            // a.href = res.data
            // console.log('1058 name: ', res.data, (this.nowVersionDetail.name) + fileType);
            
            // a.download = (this.nowVersionDetail.name) + fileType
            // a.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }))
            // this.$message.success({
            //   message: this.$t('versionManage.downloadSuccess'),
            // })

            // 下载地址和当前网站不同源，无法修改下载文件名，因此弃用以上下载方法，使用下载地址请求文件
            this.downloadPriFile(res.data, name);
          }
        }
      })
    },
    downloadPriFile(url, fileName) {
        const xhr = new XMLHttpRequest();
        xhr.open("GET", url, true);
        xhr.responseType = 'blob';
        xhr.onload = function() {
            const url = window.URL.createObjectURL(xhr.response);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a); // 添加到页面中以便触发下载
            a.click(); // 模拟点击下载
            document.body.removeChild(a); // 移除元素
            // that.$message.success({
            //   message: that.$t('versionManage.downloadSuccess'),
            // })
        };
        xhr.send();
    },
    downloadTxtFile (content, filename) {
      // 创建一个包含 txt 文件内容的 Blob 对象
      const blob = new Blob([content], { type: 'text/plain' });

      // 生成一个 URL
      const url = URL.createObjectURL(blob);

      // 创建一个下载链接
      const link = document.createElement('a');
      link.href = url;
      link.download = filename; // 设置下载文件名
      document.body.appendChild(link);

      // 触发点击下载链接
      link.click();

      // 移除下载链接
      document.body.removeChild(link);

      // 释放 Blob 对象所占用的内存
      URL.revokeObjectURL(url);
    },
    getTextWatermark () {
      let url = requestUrl.textWatermark.getTextWatermark;
      let obj = {
        "knowledgeId": this.knowledgeId,
        "systemType": 2
      }
      this.$http.post(url, obj).then(res => {
        console.log('getTextWatermark', res.data.data)
        if (res.data.data) {
          this.textWatermarkStr = res.data.data.textWatermarkStr;
          this.isDownload = res.data.data.showDownloadBtn;
          this.updateDownLoadBtn(this.isDownload);
          this.updateTextWatermarkStr(this.textWatermarkStr)
        }
      })
    },
    getPreviousDocument(type){
      console.log('type',type)
      let url = '/tag-api/v3/3rd/getPreviousRecord';
      let corpId = sessionStorage.getItem('_corpId');
      let mainId = sessionStorage.getItem('_mainId')
      url += '?mainId=' + mainId + '&corpId=' + corpId + '&id=' + this.nowVersionDetail.id;
      this.$http.get(url).then(res => {
        if (res.data.data){
          this.previousVersion = res.data.data;
          this.updatePreviousEditVersion(this.previousVersion)
        }
      })
    },
    async uploadSuccess(file){
      console.debug(file,'uploadSuccessFolder');
      let url = requestUrl.knowledgeBase.saveLocalUploadFile + '?saveType=' + 1;
      let corpId = sessionStorage.getItem('_corpId');

      // const dotComIndex = this.nowVersionDetail.ossPath.indexOf('.com');
      // let path = ''
      // if (dotComIndex !== -1) {
      //   path = this.nowVersionDetail.ossPath.substring(0, dotComIndex + '.com'.length);
      // }
      // let mainId = sessionStorage.getItem('_mainId');
      // let filename = this.nowVersionDetail.name + '.' + this.nowVersionDetail.fileSuffix;
      // let ossPath = path + '/'  + mainId +'/' + dataFormat("yyyy/MM/dd/hh/mm/") + uid(32) + "/" + filename;
      // console.log('ossPath',ossPath)
      let ossPath = file.response.res.requestUrls[0]
      let obj = {
        "corpId":corpId,
        "parentId":this.nowVersionDetail.parentId,
        "name":this.nowVersionDetail.name,
        "volume":this.nowVersionDetail.volume,  //文件大小
        "sourceLocation":this.nowVersionDetail.sourceLocation, // 用户本地路径
        "fileSuffix":this.nowVersionDetail.fileSuffix,
        "ossPath":ossPath,
        visitPermissionList:this.nowVersionDetail.visitPermission ? JSON.parse(this.nowVersionDetail.visitPermission):null,
        managePermissionList:this.nowVersionDetail.managePermission ? JSON.parse(this.nowVersionDetail.managePermission) : null,
        textWatermark:this.nowVersionDetail.textWatermark,
        textWatermarkValue:this.nowVersionDetail.textWatermarkValue,
        isDownload:this.nowVersionDetail.isDownload,
        downloadType:this.nowVersionDetail.downloadType
      }
      this.$http.post(url,obj).then(res => {
        if (res.data.code == '0'){
          // this.selectCompanyUseFlow();
          this.getKnowledgeDetail();
        }
      })
      // this.$refs.uploadFile.clearFiles();
    },

    hasManagement() {
      // let bool = false;
      let roleId = sessionStorage.getItem('roleId')
      if (roleId == 1) {
        return true
      }
      return this.checkPermission(this.knowledgeInfo);
    },
    // 校验权限，批量发布时，有编辑 || 管理权限 才能操作
    checkPermission(item) {
      let flag = false
      // 整体的权限列表 ，包含部门 和 个人 ， tyoe 1 代表 部门 ， 0 代表 个人
      let managePermission = item.managePermission ? item.managePermission : []
      let editPermission = item.editPermission ? item.editPermission : []
      let accountGroupIds = sessionStorage.getItem("_groupIds") ? JSON.parse(sessionStorage.getItem("_groupIds")) : []
      let accountUserId = sessionStorage.getItem("_uid") * 1
      if (editPermission.includes(accountUserId) || managePermission.includes(accountUserId)) {
        flag = true
        return flag
      }
      for (let index = 0; index < accountGroupIds.length; index++) {
        const gIds = accountGroupIds[index] * 1;
        if (editPermission.includes(gIds) || managePermission.includes(gIds)) {
          flag = true
          return flag
        }
      }
      return flag
    },
    checkSubsection(){
      let editPa = [];
      if (this.openSubsection){
        /* 判断是否有编辑文本未被保存 */
        for (let k in this.$refs.subsectionCom.edit_paragraph){
          if (this.$refs.subsectionCom.edit_paragraph[k]){
            editPa.push(this.$refs.subsectionCom.edit_paragraph[k])
          }
        }
        if (JSON.stringify(this.$refs.subsectionCom.split_paragraphs) === this.$refs.subsectionCom.split_paragraphs_copy && (editPa.length == 0)){
          this.goBack();
        } else {
          this.backPopup = true;
        }
      } else {
        this.goBack();
      }
    },
    goBack(){
      this.backPopup = false;
      // 记录的 从其他系统跳转过来的信息
      if (sessionStorage.getItem('toVersionManage')) {
        this.$router.push({ path: '/knowledge_Management' })
        sessionStorage.removeItem('toVersionManage')
      } else {
        console.log(1204, "else");
        
        this.$router.push({ path: '/knowledge_Management', query: { isback: 1 } })
      }
    },
    saveBack(type){
      if (type === 'no'){
        this.goBack();
      } else {
        this.$refs.subsectionCom.saveParse('back');
      }
    },
    getParseInfoCount(){
      let url = "/knowledge-api/correction/knowledge-parse-info-count/" + this.$route.query.knowledgeId;
      this.$http.get(url).then(res => {
        if (res.data.code == 0){
          this.parseInfoCount = res.data.data;
        }
      })
    }
  }
}
</script>
<style lang="less">
.el-popover.version-list {
  text-align: inherit;
  max-height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
  background: #FFFFFF;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
  border-radius: 14px;
  padding: 0;

  .version-item {
    border-bottom: 1px solid #F4F6FB;
    padding: 15px;

    .version-item-day {
      font-size: 14px;
      color: #A9B3C6;
      margin-bottom: 10px;
    }

    .version-item-content {
      font-size: 14px;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      padding: 4px;
      cursor: pointer;
      position: relative;

      &.version-item-content-last {
        margin-bottom: 0;
      }

      .version-item-version-text {
        margin-left: 10px;
        padding: 0 5px;
        height: 19px;
        background: #E7EAF1;
        border-radius: 2px;
        color: #000000;
        white-space: nowrap;
      }

      .version-item-time {
        margin-right: 6px;
        white-space: nowrap
      }

      &:hover {
        background-color: #CDDCFF;

        .version-item-version-text {
          background-color: #EDF2FF;
        }

        .version-item-box::after {
          background-color: #CDDCFF;
        }
      }

      .version-item-container {
        width: 160px;
      }

      .version-item-content-wrapper {
        display: flex;
        position: relative;
        overflow: hidden;
      }

      .version-item-box {
        font-size: 14px;
        line-height: 20px;
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;

        // height: 20px;
        .version-item-user-name {
          text-align: inherit;
        }
      }

      .version-item-box::before {
        content: '';
        display: block;
        float: right;
        width: 0px;
        height: calc(100% - 18px);
      }

      .version-item-box::after {
        position: absolute;
        content: '';
        display: inline-block;
        width: 100%;
        height: 100%;
        background: #ffffff;
      }

      .version-item-icon-1 {
        height: 16px;
        width: 70px;
        background-size: cover;
        margin-left: 2px;
        float: right;
        clear: both;
        display: block;
        position: relative;
        bottom: 2px;
      }

      .version-item-icon-2 {
        position: relative;
        // display: inline-block;
        height: 16px;
        width: 70px;
        background-size: cover;
        margin-left: 2px;
        transform: translateY(2px);
      }

      .version-item-icon-2::after {
        content: '';
        position: absolute;
        background: #ffffff;
        left: 0px;
        bottom: -40px;
        height: 40px;
        width: 70px;
      }

    }
  }

}
.subsection-btn{
  margin-top: 10px;
}
.subsection-container{
  .tips{
    text-align: left;
  }
  .btn-list{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 12px;
    .later-btn{
      height: 28px;
      padding: 0 12px;
      cursor:pointer;
      background-color: #ffffff;
      color: #616161;
      border-radius: 15px;
      margin-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #E0E6F7;
    }
    .immediately-btn{
      height: 30px;
      padding: 0 12px;
      cursor:pointer;
      background-color: #366aff;
      color: #ffffff;
      border-radius: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

}
</style>
<style lang="less" scoped>
@import "./versionManage.less";
</style>
