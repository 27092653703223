<template>
  <div>
    <div v-show="!!summary && showSummary" class="intelligentSummary" id="intelligentSummary">
      <div class="header">
        <div class="left">
          <i class="iconfont guoran-tongyichicun-write-29-jiqiren"></i>
          <span>{{ $t('versionManage.intelligentSummary') }}</span>
        </div>
        <div class="right">
          <div class="regenerationBtn" v-if="hasManagement()" @click="regeneration">
            <template v-if="!isLoading">{{ $t('versionManage.regenerate') }}</template>
            <template v-else>
              <img
                  height="20px"
                  width="20px"
                  src="https://static.guoranbot.com/loading.gif"
                  alt
                  srcset
                  style="margin-right: 6px"
              />
              <span>{{ $t('versionManage.generating') }}</span>
            </template>
          </div>
          <i class="iconfont guoran-tongyichicun-shouqi" @click="closeSummary"></i>
        </div>
      </div>
      <div class="summaryContent">
        <span>{{ typedContent }}</span>
      </div>
      <div class="intelligentBottom" v-if="recommendQuestions.length > 0">
        <div class="intelligentQues"
             v-for="(item,index) in recommendQuestions"
             :key="index"
             @click="recommendQues(item)"
        >
          {{ item }}
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { mapGetters } from 'vuex'
import common from '@/utils/common'
export default {
  name: "intelligentSummary",
  data(){
    return{
      summary:"",
      recommendQuestions:[],
      isLoading:false,
      typedContent:"",
      typingSpeed:20,
      showSummary: true
    }
  },
  props:{
    knowledgeId:{
      type:String
    }
  },
  computed: {
    ...mapGetters('knowledge', {
      knowledgeInfo: 'getKnowledge'
    }),
  },
  methods:{
    getKnowledgeSummary() {
      let url = "/knowledge-api/knowledge/detail/" + this.knowledgeId;
      this.$http.get(url).then(res => {
        console.log('res',res)
        if (res.data.data){
          this.summary = res.data.data.summary;
          this.showSummary = this.summary.indexOf('暂不支持摘要') == -1;
          this.recommendQuestions = res.data.data.recommendQuestions ? res.data.data.recommendQuestions : [];
          if (!this.summary){

            // 如果没有总结，就关闭智能总结
            this.closeSummary();

            this.regeneration();
          } else {
            this.typedContent = this.summary;
            this.$emit('getSummarySuccess')
          }
        }
      })
    },
    regeneration(){
      let url = "/knowledge-api/knowledge/analyze/summary";
      let obj = {
        "mainId": sessionStorage.getItem('_mainId') || localStorage.getItem('_mainId'),
        "userId": sessionStorage.getItem('_uid') || localStorage.getItem('_uid'),
        "knowledgeId": this.knowledgeId,
        "async": false
      }
      this.isLoading = true;
      this.typedContent = "";
      this.$http.post(url,obj).then(res => {
        console.log('regeneration',res)
        if (res.data.data){
          this.summary = res.data.data.summary;
          this.recommendQuestions = res.data.data.recommendQuestions ? res.data.data.recommendQuestions : [];
          this.$emit('getSummarySuccess')
          this.startTypingEffect();
        }
        this.isLoading = false;
      })
    },
    closeSummary(){
      this.$emit('closeSummary')
    },
    recommendQues(item){
      this.$emit('recommendQues',item,this.knowledgeId)
    },
    startTypingEffect() {
      let i = 0;
      const interval = setInterval(() => {
        if (i < this.summary.length) {
          this.typedContent += this.summary.charAt(i);
          i++;
        } else {
          clearInterval(interval);
        }
      }, this.typingSpeed);
    },
    hasManagement(){
      let bool = false;
      bool = common.hasManagement(this.knowledgeInfo.managePermission)
      return bool;
    },
  },
  mounted() {
    this.getKnowledgeSummary();
  }
};
</script>

<style scoped lang="less">
.intelligentSummary{
  background-color: #ffffff;
  border-radius: 20px;
  border: 1px solid #366AFF;
  margin-bottom: 20px;
  .header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 16px;
    .left{
      //display: flex;
      //align-items: center;
      color: #366aff;
      i{
        padding-right: 6px;
      }
    }
    .right{
      display: flex;
      align-items: center;
      color: #366aff;
      .regenerationBtn{
        height: 26px;
        background: #E9EFFF;
        border-radius: 12px;
        color: #366AFF;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding: 0 10px;
        margin-right: 10px;
        font-size: 12px;
      }
      i{
        cursor: pointer;
      }
    }
  }
  .summaryContent{
    line-height: 26px;
    padding: 0 16px 16px 16px;
  }
  .intelligentBottom{
    background: #F9FAFD;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 16px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    .intelligentQues{
      // height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 8px;
      background: #E5EBFF;
      color: #366AFF;
      border-radius: 10px;
      margin-right: 5px;
      margin-top: 5px;
      margin-bottom: 5px;
      font-size: 12px;
      cursor: pointer;
      // white-space: nowrap;
      padding: 5px 8px;
    }
  }
  ::-webkit-scrollbar{
    background-color: transparent;
  }
}
</style>